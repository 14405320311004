import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import {getProfile} from "../../helpers/backend_helper";
import PropTypes from "prop-types";
import {Container} from "reactstrap";
import AdminUserList from "./Sections/AdminUserList";
import QuizList from "./Sections/QuizList";

class AdminPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {currentSection: null};

    }

    componentDidMount() {
        const searchParam = this.props.location.search || ''
        const params = new URLSearchParams(searchParam);
        const currentSection = params.get('type')
        console.log(currentSection)
        this.setState({
            currentSection
        })
    }

    render() {
        //meta title
        document.title = "Admin Panel";

        window.localStorage.setItem('theme', 'dark');

        const renderAdminSection = () => {
            switch (this.state.currentSection) {
                case "userList":
                    return <AdminUserList/>
                case "quizList":
                    return <QuizList/>
                default:
                    return null;
            }
        };


        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <div className="container-fluid">

                            <div class="row">
                                <h4 class="mb-sm-0 font-size-18">Admin Dashboard</h4>
                                <div class="col-12">
                                    <div class="row mt--30">
                                        <div class="col-md-6 col-xl-3">
                                            <div class="card" style={{height: 250}}>
                                                <div class='card-header'>
                                                    <h6 class="card-title">Users</h6>
                                                </div>
                                                <div class="card-body">
                                                    <p class="card-text">Manage user list and deactivate accounts</p>
                                                    <button onClick={() => {
                                                        this.props.history.push('/AdminPanel?type=userList')
                                                        this.setState({
                                                            currentSection: 'userList'
                                                        })
                                                    }}
                                                       className="btn btn-primary waves-effect waves-light">Manage</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-xl-3">
                                            <div class="card" style={{height: 250}}>
                                                <div class='card-header'>
                                                    <h6 class="card-title">Quiz Content</h6>
                                                </div>
                                                <div class="card-body">
                                                    <p class="card-text">Manage Quiz contents and make updates to questions</p>
                                                    <button onClick={() => {
                                                        this.props.history.push('/AdminPanel?type=quizList')
                                                        this.setState({
                                                            currentSection: 'quizList'
                                                        })
                                                    }}
                                                            className="btn btn-primary waves-effect waves-light">Manage</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-xl-3">
                                            <div class="card" style={{height: 250}}>
                                                <div class='card-header'>
                                                    <h6 class="card-title">Job Postings</h6>
                                                </div>
                                                <div class="card-body">
                                                    <p class="card-text">Add and Remove job postings</p>
                                                    <a href="javascript: void(0);"
                                                       className="btn btn-primary waves-effect waves-light">Button</a>
                                                </div>
                                            </div>
                                        </div >

                                        <div class="col-md-6 col-xl-3">
                                            <div class="card" style={{height: 250}}>
                                                <div class='card-header'>
                                                    <h6 class="card-title">Site Analytics</h6>
                                                </div>
                                                <div class="card-body">
                                                    <p class="card-text">Review User Analytics and Dashboards</p>
                                                    <a href="javascript: void(0);"
                                                       className="btn btn-primary waves-effect waves-light">Button</a>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    {
                                        renderAdminSection()
                                    }
                                </div>
                            </div>

                        </div>


                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

AdminPanel.propTypes = {
    t: PropTypes.any
};

export default withTranslation()(AdminPanel);