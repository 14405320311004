import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE,
  GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE, UPLOAD_PROFILE_PICTURE, UPLOAD_RESUME
} from "./actionTypes"

export const editProfile = user => {
  return {
    type: EDIT_PROFILE,
    payload: { user },
  }
}

export const uploadProfilePicture = file => {
  return {
    type: UPLOAD_PROFILE_PICTURE,
    payload: { file },
  }
}

export const uploadResume = file => {
  return {
    type: UPLOAD_RESUME,
    payload: { file },
  }
}

export const profileSuccess = msg => {
  return {
    type: PROFILE_SUCCESS,
    payload: msg,
  }
}

export const profileError = error => {
  return {
    type: PROFILE_ERROR,
    payload: error,
  }
}

export const resetProfileFlag = error => {
  return {
    type: RESET_PROFILE_FLAG,
  }
}

export const getProfileData = () => {
  return {
    type: GET_PROFILE
  }
}

export const getProfileSuccess = profileData => {
  return {
    type: GET_PROFILE_SUCCESS,
    payload: profileData,
  }
}

export const getProfileFailure = error => {
  return {
    type: GET_PROFILE_FAILURE,
    payload: error,
  }
}
