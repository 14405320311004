import React, {Component, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import Avatar from "react-avatar";
import ReactPlayer from "react-player";

class CourseDashboard extends Component {
    constructor(props) {
        super(props);

        this.state = { isMobile: false, screenHeight: 1000 };
    }

    componentDidMount() {
        this.setState({

        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div class='row'>
                        <div class='col-xl-4'>
                            <div class='card overflow-hidden'>
                                <div class='card-body'>
                                    <div class='row'>
                                        <div class='col-xl-12 mt--25' style={{textAlign: 'center'}}>
                                           <Avatar name='Romario Newman' size={100} round={true}/>
                                            <div class='mt--15'>
                                                <p style={{fontSize: '25px', fontWeight: 'bold'}}>Romario Newman-Latty</p>
                                            </div>
                                            <div class='mt--15'>
                                                <p style={{fontSize: '20px'}}>Mobile Developer</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class='card'>
                                <div class='card-body'>
                                    <div class='row'>
                                        <div class='col-xl-12 mt--25' style={{textAlign: 'left'}}>
                                            <div class='row'>
                                               <div class='col-xl-6'>
                                                   <h4 style={{fontSize: 20}} class="card-title mb-4">SkillSense</h4>
                                               </div>
                                                <div class='col-xl-6' style={{textAlign: 'right'}}>
                                                    <a class="card-title mb-4">View all ></a>
                                                </div>
                                                <hr/>
                                            </div>

                                            <div class='row'>
                                                <div class='col-xl-8'>
                                                    <div class='row'>
                                                        <div class='col-xl-4'>
                                                            <img style={{height: 70}} src='https://pluralsight2.imgix.net/assessments/images/icons/mac-os-17fcd0c81c.png'/>
                                                        </div>
                                                        <div class='col-xl-8'>
                                                            <div class='row'>
                                                                <div class='col-xl-12'>
                                                                    <p style={{fontSize: 20}}>Swift</p>
                                                                </div>
                                                            </div>
                                                            <div class='row'>
                                                                <div class='col-xl-12'>
                                                                    <p style={{fontSize: 20, fontWeight: 'bold', color: 'green'}}>Proficient</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class='col-xl-4' style={{textAlign: 'right'}}>
                                                    <button type="button"
                                                            className="btn btn-primary waves-effect waves-light">Retake
                                                    </button>
                                                </div>
                                                <hr/>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class='card'>
                                <div class='card-body'>
                                    <div class='row'>
                                        <div class='col-xl-12 mt--25' style={{textAlign: 'left'}}>
                                            <div class='row'>
                                                <div class='col-xl-6'>
                                                    <h4 style={{fontSize: 20}} class="card-title mb-4">Your badges</h4>
                                                </div>
                                                <div class='col-xl-6' style={{textAlign: 'right'}}>
                                                    <a className="card-title mb-4">View all ></a>
                                                </div>
                                                <hr/>
                                            </div>

                                            <div class='row'>
                                                <div class='col-xl-12'>
                                                    <div>
                                                        <h4 style={{fontSize: 15}} class="card-title mb-4">Newly Earned Badges</h4>
                                                    </div>
                                                    <div class='mt--25'>
                                                        <div class='row'>
                                                            <div style={{textAlign: 'center'}} class='col-xl-5'>
                                                                <img style={{height: 150}} src='https://cdn-icons-png.flaticon.com/512/3750/3750043.png' />
                                                            </div>
                                                            <div class='col-xl'>
                                                                <div class='row'>
                                                                    <p style={{fontSize: 25, fontWeight: 'bold'}}>Month to Month 1x</p>
                                                                </div>
                                                                <div class='row mt--15'>
                                                                    <p style={{fontSize: 20}}>Make time every month to skill up</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class='row'>
                                                <div class='col-xl-12'>
                                                    <div>
                                                        <h4 style={{fontSize: 15}} class="card-title mb-4 mt--15">Upcoming</h4>
                                                    </div>
                                                    <div class='mt--25'>
                                                        <div class='row'>
                                                            <div style={{textAlign: 'center'}} class='col-xl-4'>
                                                                <img style={{height: 100}} src='https://cdn-icons-png.flaticon.com/512/2618/2618179.png' />
                                                            </div>
                                                            <div class='col-xl'>
                                                                <div class='row mt--15'>
                                                                    <p style={{fontSize: 20, fontWeight: 'bold'}}>SkillSense 2x</p>
                                                                </div>
                                                                <div class='row mt--15'>
                                                                    <p style={{fontSize: 20}}>Make time every month to skill up</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class='col-xl-8'>
                            <div class="card" style={{backgroundColor: '#212529'}}>
                                <div class="card-body">
                                    <div class='row'>
                                        <div class='col-xl-6'>
                                            <h4 style={{fontSize: 15, color: 'white'}} class="card-title mb-4">Get Started</h4>
                                        </div>
                                        <div class='col-xl-6' style={{textAlign: 'right'}}>
                                            <a style={{color: 'white'}} className="card-title mb-4">View all ></a>
                                        </div>
                                    </div>

                                    <div id="carouselExampleCaption" class="carousel slide" data-bs-ride="carousel">
                                        <div class="carousel-inner" role="listbox">
                                            <div style={{textAlign: 'center', height: 300}} class="carousel-item">
                                                <img src="https://themesbrand.com/skote/layouts/assets/images/small/img-7.jpg" alt="..."/>
                                                    <div class="carousel-caption d-none d-md-block text-white-50">
                                                        <h5 class="text-white">First slide label</h5>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                    </div>
                                            </div>
                                            <div style={{textAlign: 'center', height: 300}} class="carousel-item active carousel-item-start">
                                                <img src="https://themesbrand.com/skote/layouts/assets/images/small/img-5.jpg" alt="..."/>
                                                    <div class="carousel-caption d-none d-md-block text-white-50">
                                                        <h5 class="text-white">Second slide label</h5>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                    </div>
                                            </div>
                                            <div style={{textAlign: 'center', height: 300}} class="carousel-item carousel-item-next carousel-item-start">
                                                <img src="https://themesbrand.com/skote/layouts/assets/images/small/img-4.jpg" alt="..."/>
                                                    <div class="carousel-caption d-none d-md-block text-white-50">
                                                        <h5 class="text-white">Third slide label</h5>
                                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                    </div>
                                            </div>
                                        </div>
                                        <a className="carousel-control-prev" href="#carouselExampleCaption"
                                           role="button" data-bs-slide="prev">
                                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Previous</span>
                                        </a>
                                        <a className="carousel-control-next" href="#carouselExampleCaption"
                                           role="button" data-bs-slide="next">
                                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                            <span class="sr-only">Next</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <h4 style={{fontSize: 20}} class="card-title">
                                        Trending for new learners
                                    </h4>
                                    <div class="row mt--25">
                                        <div class='col-2'>
                                            <ReactPlayer
                                                style={{borderRadius: '10px', borderColor: 'black', borderWidth: '5px', borderStyle: 'solid'}}
                                                url="https://vimeo.com/243556536"
                                                width='100%'
                                                height='200px'
                                                playing
                                                playIcon={<button>Play</button>}
                                                light="https://i.stack.imgur.com/zw9Iz.png"
                                            />
                                        </div>

                                        <div class='col-2'>
                                            <ReactPlayer
                                                style={{borderRadius: '10px', borderColor: 'black', borderWidth: '5px', borderStyle: 'solid'}}
                                                url="https://vimeo.com/243556536"
                                                width='100%'
                                                height='200px'
                                                playing
                                                playIcon={<button>Play</button>}
                                                light="https://i.stack.imgur.com/zw9Iz.png"
                                            />
                                        </div>

                                        <div class='col-2'>
                                            <ReactPlayer
                                                style={{borderRadius: '10px', borderColor: 'black', borderWidth: '5px', borderStyle: 'solid'}}
                                                url="https://vimeo.com/243556536"
                                                width='100%'
                                                height='200px'
                                                playing
                                                playIcon={<button>Play</button>}
                                                light="https://i.stack.imgur.com/zw9Iz.png"
                                            />
                                        </div>

                                        <div class='col-2'>
                                            <ReactPlayer
                                                style={{borderRadius: '10px', borderColor: 'black', borderWidth: '5px', borderStyle: 'solid'}}
                                                url="https://vimeo.com/243556536"
                                                width='100%'
                                                height='200px'
                                                playing
                                                playIcon={<button>Play</button>}
                                                light="https://i.stack.imgur.com/zw9Iz.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class='mt--25'>
                                <div>
                                    <h4 style={{fontSize: 20}} class="card-title">
                                        Newest courses
                                    </h4>
                                    <div class="row mt--25">
                                        <div class='col-2'>
                                            <ReactPlayer
                                                style={{borderRadius: '10px', borderColor: 'black', borderWidth: '5px', borderStyle: 'solid'}}
                                                url="https://vimeo.com/243556536"
                                                width='100%'
                                                height='200px'
                                                playing
                                                playIcon={<button>Play</button>}
                                                light="https://i.stack.imgur.com/zw9Iz.png"
                                            />
                                        </div>

                                        <div class='col-2'>
                                            <ReactPlayer
                                                style={{borderRadius: '10px', borderColor: 'black', borderWidth: '5px', borderStyle: 'solid'}}
                                                url="https://vimeo.com/243556536"
                                                width='100%'
                                                height='200px'
                                                playing
                                                playIcon={<button>Play</button>}
                                                light="https://i.stack.imgur.com/zw9Iz.png"
                                            />
                                        </div>

                                        <div class='col-2'>
                                            <ReactPlayer
                                                style={{borderRadius: '10px', borderColor: 'black', borderWidth: '5px', borderStyle: 'solid'}}
                                                url="https://vimeo.com/243556536"
                                                width='100%'
                                                height='200px'
                                                playing
                                                playIcon={<button>Play</button>}
                                                light="https://i.stack.imgur.com/zw9Iz.png"
                                            />
                                        </div>

                                        <div class='col-2'>
                                            <ReactPlayer
                                                style={{borderRadius: '10px', borderColor: 'black', borderWidth: '5px', borderStyle: 'solid'}}
                                                url="https://vimeo.com/243556536"
                                                width='100%'
                                                height='200px'
                                                playing
                                                playIcon={<button>Play</button>}
                                                light="https://i.stack.imgur.com/zw9Iz.png"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )

    }



}

CourseDashboard.propTypes = {
    t: PropTypes.any
};

export default withTranslation()(CourseDashboard);