import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDrawer from "react-drawer";
import "react-drawer/lib/react-drawer.css";

import { connect } from "react-redux";

import { Link } from "react-router-dom";

// Redux Store
import { toggleRightSidebar } from "../../store/actions";

import DarkIcon from '../../assets/icons/Black.png'
import LightIcon from '../../assets/icons/Logo-02.png'

import Avatar from 'react-avatar';

//i18n
import { withTranslation } from "react-i18next";
import Navbar from "./Navbar";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearch: false,
      open: false,
      position: "right",
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleSearch = this.toggleSearch.bind(this);
  }

  toggleSearch = () => {
    this.setState({ isSearch: !this.state.isSearch });
  };
  /**
   * Toggle sidebar
   */
  toggleMenu() {
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <header id="page-topbar">
          <div class="navbar-header">
            <div class="d-flex">
              <div class="navbar-brand-box">
                <a href="index.html" className="logo logo-dark">
                                <span class="logo-sm">
                                    <img src={DarkIcon} alt="" height="44"/>
                                </span>
                  <span class="logo-lg">
                                    <img src={DarkIcon} alt="" height="34"/>
                                </span>
                </a>

                <a href="index.html" className="logo logo-light">
                                <span class="logo-sm">
                                    <img src={LightIcon} alt="" height="50"/>
                                </span>
                  <span class="logo-lg">
                                    <img src={LightIcon} alt="" height="50"/>
                                </span>
                </a>
              </div>

              <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                      id="vertical-menu-btn">
                <i class="fa fa-fw fa-bars"></i>
              </button>

              <form className="app-search d-none d-lg-block">
                <div class="position-relative">
                  <input type="text" className="form-control" placeholder="Search..."/>
                  <span class="bx bx-search-alt"></span>
                </div>
              </form>
            </div>

            <div class="d-flex">

              <div class="dropdown d-inline-block d-lg-none ms-2">
                <button type="button" className="btn header-item noti-icon waves-effect"
                        id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                  <i class="mdi mdi-magnify"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                     aria-labelledby="page-header-search-dropdown">

                  <form className="p-3">
                    <div class="form-group m-0">
                      <div class="input-group">
                        <input type="text" className="form-control" placeholder="Search ..."
                               aria-label="Recipient's username"/>
                        <div class="input-group-append">
                          <button className="btn btn-primary" type="submit"><i class="mdi mdi-magnify"></i></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="dropdown d-inline-block">
                <div class="dropdown-menu dropdown-menu-end">

                  <a className="dropdown-item notify-item language" data-lang="en">
                    <img src="assets/images/flags/us.jpg" alt="user-image" className="me-1" height="12"/> <span
                      class="align-middle">English</span>
                  </a>
                  <a className="dropdown-item notify-item language" data-lang="sp">
                    <img src="assets/images/flags/spain.jpg" alt="user-image" className="me-1" height="12"/> <span
                      class="align-middle">Spanish</span>
                  </a>

                  <a className="dropdown-item notify-item language" data-lang="gr">
                    <img src="assets/images/flags/germany.jpg" alt="user-image" className="me-1" height="12"/> <span
                      class="align-middle">German</span>
                  </a>

                  <a className="dropdown-item notify-item language" data-lang="it">
                    <img src="assets/images/flags/italy.jpg" alt="user-image" className="me-1" height="12"/> <span
                      class="align-middle">Italian</span>
                  </a>

                  <a className="dropdown-item notify-item language" data-lang="ru">
                    <img src="assets/images/flags/russia.jpg" alt="user-image" className="me-1" height="12"/> <span
                      class="align-middle">Russian</span>
                  </a>
                </div>
              </div>

              <div class="dropdown d-none d-lg-inline-block ms-1">
                <button type="button" className="btn header-item noti-icon waves-effect" data-bs-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                  <i class="bx bx-customize"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end">
                  <div class="px-lg-2">
                    <div class="row g-0">
                      <div class="col">
                        <a className="dropdown-icon-item" href="#">
                          <img src="assets/images/brands/github.png" alt="Github"/>
                          <span>GitHub</span>
                        </a>
                      </div>
                      <div class="col">
                        <a className="dropdown-icon-item" href="#">
                          <img src="assets/images/brands/bitbucket.png" alt="bitbucket"/>
                          <span>Bitbucket</span>
                        </a>
                      </div>
                      <div class="col">
                        <a className="dropdown-icon-item" href="#">
                          <img src="assets/images/brands/dribbble.png" alt="dribbble"/>
                          <span>Dribbble</span>
                        </a>
                      </div>
                    </div>

                    <div class="row g-0">
                      <div class="col">
                        <a className="dropdown-icon-item" href="#">
                          <img src="assets/images/brands/dropbox.png" alt="dropbox"/>
                          <span>Dropbox</span>
                        </a>
                      </div>
                      <div class="col">
                        <a className="dropdown-icon-item" href="#">
                          <img src="assets/images/brands/mail_chimp.png" alt="mail_chimp"/>
                          <span>Mail Chimp</span>
                        </a>
                      </div>
                      <div class="col">
                        <a className="dropdown-icon-item" href="#">
                          <img src="assets/images/brands/slack.png" alt="slack"/>
                          <span>Slack</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="dropdown d-none d-lg-inline-block ms-1">
                <button type="button" className="btn header-item noti-icon waves-effect" data-bs-toggle="fullscreen">
                  <i class="bx bx-fullscreen"></i>
                </button>
              </div>

              <div class="dropdown d-inline-block">
                <button type="button" className="btn header-item noti-icon waves-effect"
                        id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                  <i class="bx bx-bell bx-tada"></i>
                  <span class="badge bg-danger rounded-pill">3</span>
                </button>
                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                     aria-labelledby="page-header-notifications-dropdown">
                  <div class="p-3">
                    <div class="row align-items-center">
                      <div class="col">
                        <h6 class="m-0" key="t-notifications"> Notifications </h6>
                      </div>
                      <div class="col-auto">
                        <a href="#!" className="small" key="t-view-all"> View All</a>
                      </div>
                    </div>
                  </div>
                  <div data-simplebar="init" style={{'max-height': '230px'}}>
                    <div class="simplebar-wrapper" style={{margin: '0px'}}>
                      <div class="simplebar-height-auto-observer-wrapper">
                        <div class="simplebar-height-auto-observer"></div>
                      </div>
                      <div class="simplebar-mask">
                        <div class="simplebar-offset" style={{right: '0px', bottom: '0px'}}>
                          <div class="simplebar-content-wrapper"
                               style={{
                                 height: 'auto',
                                 'padding-right': '0px',
                                 'padding-bottom': '0px',
                                 overflow: 'hidden'
                               }}>
                            <div class="simplebar-content" style={{padding: '0px'}}>
                              <a className="text-reset notification-item">
                                <div class="d-flex">
                                  <div class="avatar-xs me-3">
                                                <span class="avatar-title bg-primary rounded-circle font-size-16">
                                                    <i class="bx bx-cart"></i>
                                                </span>
                                  </div>
                                  <div class="flex-grow-1">
                                    <h6 class="mb-1" key="t-your-order">Your order is placed</h6>
                                    <div class="font-size-12 text-muted">
                                      <p class="mb-1" key="t-grammer">If several languages coalesce the grammar</p>
                                      <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                              <a className="text-reset notification-item">
                                <div class="d-flex">
                                  <img src="assets/images/users/avatar-3.jpg"
                                       className="me-3 rounded-circle avatar-xs" alt="user-pic"/>
                                  <div class="flex-grow-1">
                                    <h6 class="mb-1">James Lemire</h6>
                                    <div class="font-size-12 text-muted">
                                      <p class="mb-1" key="t-simplified">It will seem like simplified English.</p>
                                      <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                              <a className="text-reset notification-item">
                                <div class="d-flex">
                                  <div class="avatar-xs me-3">
                                                <span class="avatar-title bg-success rounded-circle font-size-16">
                                                    <i class="bx bx-badge-check"></i>
                                                </span>
                                  </div>
                                  <div class="flex-grow-1">
                                    <h6 class="mb-1" key="t-shipped">Your item is shipped</h6>
                                    <div class="font-size-12 text-muted">
                                      <p class="mb-1" key="t-grammer">If several languages coalesce the grammar</p>
                                      <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-min-ago">3 min ago</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </a>

                              <a className="text-reset notification-item">
                                <div class="d-flex">
                                  <img src="assets/images/users/avatar-4.jpg"
                                       className="me-3 rounded-circle avatar-xs" alt="user-pic"/>
                                  <div class="flex-grow-1">
                                    <h6 class="mb-1">Salena Layfield</h6>
                                    <div class="font-size-12 text-muted">
                                      <p class="mb-1" key="t-occidental">As a skeptical Cambridge friend of mine
                                        occidental.</p>
                                      <p class="mb-0"><i class="mdi mdi-clock-outline"></i> <span key="t-hours-ago">1 hours ago</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="simplebar-placeholder" style={{width: '0px', height: '0px'}}></div>
                    </div>
                    <div class="simplebar-track simplebar-horizontal" style={{visibility: 'hidden'}}>
                      <div class="simplebar-scrollbar"
                           style={{transform: 'translate3d(0px, 0px, 0px)', display: 'none'}}></div>
                    </div>
                    <div class="simplebar-track simplebar-vertical" style={{visibility: 'hidden'}}>
                      <div class="simplebar-scrollbar"
                           style={{transform: 'translate3d(0px, 0px, 0px)', display: 'none'}}></div>
                    </div>
                  </div>
                  <div class="p-2 border-top d-grid">
                    <a className="btn btn-sm btn-link font-size-14 text-center">
                      <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View More..</span>
                    </a>
                  </div>
                </div>
              </div>

              <div class="dropdown d-inline-block">
                <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <Avatar name={'Romario Newman'} round={true} size={50}/>
                  <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-end">
                  <a className="dropdown-item" href="#"><i class="bx bx-user font-size-16 align-middle me-1"></i>
                    <span key="t-profile">Profile</span></a>
                  <a className="dropdown-item" href="#"><i class="bx bx-wallet font-size-16 align-middle me-1"></i>
                    <span key="t-my-wallet">My Wallet</span></a>
                  <a className="dropdown-item d-block" href="#"><span class="badge bg-success float-end">11</span><i
                      class="bx bx-wrench font-size-16 align-middle me-1"></i> <span
                      key="t-settings">Settings</span></a>
                  <a className="dropdown-item" href="#"><i class="bx bx-lock-open font-size-16 align-middle me-1"></i>
                    <span key="t-lock-screen">Lock screen</span></a>
                  <div class="dropdown-divider"></div>
                  <a className="dropdown-item text-danger" href="#"><i
                      class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span
                      key="t-logout">Logout</span></a>
                </div>
              </div>

              <div class="dropdown d-inline-block">
                <button type="button" className="btn header-item noti-icon right-bar-toggle waves-effect">
                  <i class="bx bx-cog bx-spin"></i>
                </button>
              </div>

            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  openLeftMenuCallBack: PropTypes.func,
  t: PropTypes.any,
  toggleRightSidebar: PropTypes.func,
};

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(Header)
);
