import {useEffect, useState} from 'react';
import ServiceData from '../../../data/Services.json';
import SectionTitle from '../common/SectionTitle';
import ServiceCardOne from './ServiceCardOne';
import icon01 from '../../../assets/frontPage/images/icons/icon-01.svg'
import icon02 from '../../../assets/frontPage/images/icons/icon-02.svg'
import icon03 from '../../../assets/frontPage/images/icons/icon-03.svg'
import icon04 from '../../../assets/frontPage/images/icons/icon-04.svg'

const ServiceOne = () => {
    const [defaultServices, setDefaultServices] = useState([]);
    const [activeService, setActiveService] = useState(1);
    const [serviceIconList, setServiceIconList] = useState([icon01, icon02, icon03, icon04]);

    const getDefaultServices = () => {
        const filteredServices = ServiceData.filter(
            (service) => service.category === 'Default'
        );

        setDefaultServices(filteredServices)
    }

    const changeActive = (index) => {
        setActiveService(index);
    };

    useEffect(() => {
        getDefaultServices();
    }, []);

    return (
        <div className="axil-service-area ax-section-gap bg-color-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            title="Services we can help you with"
                            subtitle="what we can do for you"
                            description="Nulla facilisi. Nullam in magna id dolor blandit rutrum eget vulputate augue sed eu leo eget risus imperdiet."
                            color="extra08-color"
                            alignment="center"
                        />
                    </div>
                </div>
                <div className="row">
                    {defaultServices?.map((service, index) => (
                        <ServiceCardOne
                            key={service.id}
                            column="col-lg-4 col-md-6 col-sm-6 col-12"
                            index={index}
                            activeIndex={activeService}
                            data={service}
                            icon={serviceIconList[index]}
                            layer={''}
                            changeActive={changeActive}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ServiceOne;
