import React, {Component} from "react";
import PropTypes from "prop-types";
import {Container} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import {useTimer} from "react-timer-hook";
import parse from 'html-react-parser';
import {createNewAssessment, getAssessmentById, getUserById, updateAssessment} from "../../../helpers/backend_helper";

class QuizBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formAlertMessage: null,
            selectedAssessmentId: null,
            currentQuestionIndex: 0,
            currentAssessment: {
                name: null,
                type: null,
                availability: null,
                currentChoiceIndex: 0,
                questions: [
                    {
                        title: null,
                        subtitle: null,
                        category: null,
                        duration: 30,
                        choices: [{
                            choice: null,
                            isCorrect: false,
                            isEditing: true
                        }]
                    }
                ],
                assessmentCategories: []
            }
        };
    }

    componentDidMount() {
        if (!localStorage.getItem('superUser')) {
            console.error('Not Authorized')
            return
        }

        const searchParam = this.props.location.search || ''
        const params = new URLSearchParams(searchParam);
        const assessmentId = params.get('assessmentId')

        if(assessmentId) {
            this.mapAssessmentDataToState(assessmentId)
        } else {
            const stickySessionState = window.localStorage.getItem('currentAssessment');
            this.setState(JSON.parse(stickySessionState))
        }
    }

    mapAssessmentDataToState(assessmentId: string) {
        getAssessmentById({
            params: {
                assessmentId
            },
            headers: {
                Authorization: JSON.parse(localStorage.getItem('superUser'))
            }
        }).then(
            (result) => {

                const assessment = result

                let categories = []
                let questions = assessment.questions.map((question) => {
                    let correctAnswer = question.correctAnswer
                    let choices = question.metadata.choices.map((choice) => {
                        return {
                            choice: choice,
                            isCorrect: correctAnswer === choice,
                            isEditing: false
                        }
                    })

                    if(!categories.includes(question.category)) {
                        categories.push(question.category)
                    }

                    return {
                        title: question.title,
                        subtitle: question.subtitle,
                        category: question.category,
                        duration: question.durationInSeconds,
                        choices: choices
                    }
                })

                questions.push({
                    title: null,
                    subtitle: null,
                    category: null,
                    duration: 30,
                    choices: [{
                        choice: null,
                        isCorrect: false
                    }]
                })

                let stateData = {
                    formAlertMessage: null,
                    selectedAssessmentId: assessmentId,
                    currentQuestionIndex: 0,
                    currentAssessment: {
                        name: assessment.category,
                        type: assessment.type,
                        availability: assessment.status,
                        currentChoiceIndex: 0,
                        questions: questions,
                        assessmentCategories: categories || []
                }
                }
                this.setState(stateData)

            },
            (error) => {
                console.log(error.text);
            }
        );
    }

    createAssessmentPayload() {
        let currentAssessment = this.state.currentAssessment

        let category = currentAssessment.name
        let type = currentAssessment.type
        let status = currentAssessment.availability
        let isTimed = true

        let filteredQuestions = currentAssessment.questions.filter((currentQuestion) => {
            let shouldNotKeep = currentQuestion.title == null || currentQuestion.title === ''
            return !shouldNotKeep
        })

        let questions = filteredQuestions.map((currentQuestion) => {
            let questionTitle = currentQuestion.title
            let questionSubtitle = currentQuestion.subtitle
            let questionCategory = currentQuestion.category
            let questionDuration = currentQuestion.duration
            let correctChoices = []
            let choices = currentQuestion.choices.map((currentChoice) => {
                if(currentChoice.isCorrect) {
                    correctChoices.push(currentChoice.choice)
                }
                return currentChoice.choice
            })

            if(correctChoices.length == 0) {
                alert('Missing Correct Answer for a question')
                return null
            }

        return {
            "category": questionCategory,
            "title": questionTitle,
            "durationInSeconds": questionDuration,
            "metadata": {
                "choices": choices
            },
            "correctAnswer": correctChoices[0]
        }
    })

        if(category == null || category === '') {
            this.setState({
                ...this.state,
                formAlertMessage: 'Missing Assessment Title'
            })
            return null
        }

        if(type == null || type === '') {
            this.setState({
                ...this.state,
                formAlertMessage: 'Missing Assessment Type'
            })
            return null
        }

        if(status == null || status === '') {
            this.setState({
                ...this.state,
                formAlertMessage: 'Missing Assessment Status'
            })
            return null
        }

        if(questions == null || questions.length == 0) {
            this.setState({
                ...this.state,
                formAlertMessage: 'Need to add at least one question to this assessment'
            })
            return null
        }

        return {
            "assessmentData": {
                "category": category,
                "type": type,
                "status": status,
                "isTimed": isTimed,
                "questions": questions
            }
        }
    }

    updateAssessment() {
        let assessmentData = this.createAssessmentPayload()
        assessmentData.assessmentData.id = this.state.selectedAssessmentId
        if(assessmentData == null) {
            console.log('Error Updating Assessment')
            return
        }

        updateAssessment(assessmentData,{
            headers: {
                Authorization: JSON.parse(localStorage.getItem('superUser'))
            }
        }).then(
            (result) => {
                this.props.history.push('/AdminPanel?type=quizList')
            },
            (error) => {
                console.log(error.text);
            }
        );
    }

    createAssessment() {
        let assessmentData = this.createAssessmentPayload()
        if(assessmentData == null) {
            console.log('Error Creating Assessment')
            return
        }

        createNewAssessment(assessmentData,{
            headers: {
                Authorization: JSON.parse(localStorage.getItem('superUser'))
            }
        }).then(
            (result) => {
                this.props.history.push('/AdminPanel?type=quizList')
            },
            (error) => {
                console.log(error.text);
            }
        );
    }

    /// Data Binding
    dataBindingOnBaseForm(elementId: string, formProp: string) {
        let currentAssessment = this.state.currentAssessment
        const inputElement = document.getElementById(elementId)
        let val = inputElement.value;

        const isSelectElement = inputElement.options != null
        if(isSelectElement) {
            let selectedOption = inputElement.options[inputElement.options.selectedIndex]
            val = selectedOption.value
        }

        this.setState({
            currentAssessment: {
                ...currentAssessment,
                [formProp]: val,
            }
        })
    }

    dataBindingOnForm(elementId: string, formProp: string) {
        const inputElement = document.getElementById(elementId)
        const val = inputElement.value;

        let currentAssessment = this.state.currentAssessment
        currentAssessment.questions[this.state.currentQuestionIndex] = {
            ...currentAssessment.questions[this.state.currentQuestionIndex],
            [formProp]: val
        }

        this.setState({
            currentAssessment: {
                ...currentAssessment
            }
        })
    }

    dataBindingQuestionChoice(elementId: string, isCorrect: boolean) {
        const inputElement = document.getElementById(elementId)
        const choiceResponse = inputElement.value;

        let currentAssessment = this.state.currentAssessment
        let choices = currentAssessment.questions[this.state.currentQuestionIndex].choices
        let currentChoiceIndex = choices.length - 1
        choices[currentChoiceIndex] = {
            choice: choiceResponse,
            isCorrect: isCorrect,
            isEditing: true
        }
        currentAssessment.questions[this.state.currentQuestionIndex] = {
            ...currentAssessment.questions[this.state.currentQuestionIndex],
            choices
        }

        this.setState({
            ...currentAssessment
        })

    }

    /// Question Navigation
    addQuestionChoice() {
        const inputElement = document.getElementById('choices')
        const choiceResponse = inputElement.value;

        if(choiceResponse == null || choiceResponse === '') {
            return
        }

        let currentAssessment = this.state.currentAssessment
        let currentChoiceIndex = currentAssessment.currentChoiceIndex
        let choices = currentAssessment.questions[this.state.currentQuestionIndex].choices
        let currentChoice = choices[currentChoiceIndex]
        currentChoice.isEditing = false
        currentChoiceIndex = currentChoiceIndex + 1
        choices.push({
            choice: null,
            isCorrect: false,
            isEditing: true
        })

        currentAssessment.currentChoiceIndex = currentChoiceIndex

        currentAssessment.questions[this.state.currentQuestionIndex] = {
            ...currentAssessment.questions[this.state.currentQuestionIndex],
            choices
        }

        this.setState({
            currentAssessment: {
                ...currentAssessment,
                currentChoiceIndex
            }
        })

        inputElement.value = ''

    }

    previousQuestion() {
        let currentAssessment = this.state.currentAssessment
        let currentQuestionIndex = this.state.currentQuestionIndex
        this.setState({
            ...currentAssessment,
            currentQuestionIndex: currentQuestionIndex - 1
        })
    }

    removeQuestion(questionToDelete) {
        let currentAssessment = this.state.currentAssessment
        let removeIndex = -1
        //ROMO TODO: Delete Based unique ID as opposed to question title
        let questions = currentAssessment.questions.filter((question, index) => {
            let shouldKeep: boolean = questionToDelete.title !== question.title
            if(!shouldKeep) {
                removeIndex = index
            }
            return shouldKeep
        })

        //|| removeIndex == currentAssessment.questions.length - 1
        if(questions.length == 0) {
            questions.push({
                title: null,
                subtitle: null,
                category: null,
                duration: 30,
                choices: [{
                    choice: null,
                    isCorrect: false
                }]
            })
        }

        this.setState({
            ...this.state,
            currentAssessment: {
                name: currentAssessment.name,
                type: currentAssessment.type,
                availability: currentAssessment.availability,
                currentChoiceIndex: 0,
                questions: questions,
                assessmentCategories: currentAssessment.assessmentCategories
            },
            currentQuestionIndex: 0
        })

        this.resetForm()

        this.saveProgress()


    }

    gotoQuestion(index: number) {
        let currentAssessment = this.state.currentAssessment
        let currentQuestionIndex = this.state.currentQuestionIndex
        this.setState({
            ...currentAssessment,
            currentQuestionIndex: index
        })
    }

    processCurrentQuestion() {
        if(!this.validateQuizBuilderForm()) {
            return
        }
        let currentAssessment = this.state.currentAssessment
        let currentQuestionIndex = this.state.currentQuestionIndex
        let assessmentCategories = currentAssessment.assessmentCategories
        let questions = currentAssessment.questions
        let currentQuestion = currentAssessment.questions[currentQuestionIndex]
        if(!assessmentCategories.includes(currentQuestion.category)) {
            assessmentCategories.push(currentQuestion.category)
        }

        questions.push({
            title: null,
            subtitle: null,
            category: null,
            duration: 30,
            choices: [{
                choice: null,
                isCorrect: false
            }]
        })

        this.setState({
            currentQuestionIndex: currentQuestionIndex + 1,
            currentAssessment: {
                ...currentAssessment,
                currentChoiceIndex: 0
            }
        })

        this.resetForm()

        this.saveProgress()

    }

    processQuestionChoice(choice, action) {
        let currentAssessment = this.state.currentAssessment
        let questions = currentAssessment.questions
        let currentChoiceIndex = currentAssessment.currentChoiceIndex
        let currentQuestionIndex = this.state.currentQuestionIndex
        let currentQuestion = questions[currentQuestionIndex]
        let choices = currentQuestion.choices
        let editIndex = currentQuestion.choices.indexOf(choice)

        switch (action) {
            case 'remove':
                currentAssessment.currentChoiceIndex = editIndex

                choices = choices.filter((currentChoice) => {
                    if(currentChoice.choice == null || currentChoice.choice === '') {
                        return true
                    } else if (currentChoice.choice === choice.choice) {
                        return false
                    } else {
                        return true
                    }
                })

                currentAssessment.questions[this.state.currentQuestionIndex] = {
                    ...currentAssessment.questions[this.state.currentQuestionIndex],
                    choices
                }
                break
            case 'toggleCorrect':
                choice.isCorrect = !choice.isCorrect
                currentQuestion.choices[editIndex] = choice
                break
            default:
                break
        }

        this.setState({
            currentAssessment: {
                ...currentAssessment,
                questions,
                currentChoiceIndex: editIndex
            }
        })
    }

    /// Validate Question Form
    validateQuizBuilderForm(): boolean {
        let currentAssessment = this.state.currentAssessment
        let currentQuestionIndex = this.state.currentQuestionIndex
        let currentQuestion = currentAssessment.questions[currentQuestionIndex]

        if(currentQuestion.title == null || currentQuestion.title === '') {
            this.setState({
                ...this.state,
                formAlertMessage: 'Missing Question Title'
            })
            return false
        }

        if(currentQuestion.category == null || currentQuestion.category === '') {
            this.setState({
                ...this.state,
                formAlertMessage: 'Missing Question Category'
            })
            return false
        }

        if(currentQuestion.choices[0].choice == null || currentQuestion.choices[0].choice === '') {
            this.setState({
                ...this.state,
                formAlertMessage: 'Please add at least one choice to this assessment '
            })
            return false
        }

        return true

    }

    /// State Management Actions
    resetForm() {
        const questionTitle = document.getElementById('questionTitle')
        const questionSubTitle = document.getElementById('questionSubTitle')
        const assessmentCategories = document.getElementById('assessmentCategories')
        const choices = document.getElementById('choices')

        questionTitle.value = ''
        questionSubTitle.value = ''
        assessmentCategories.value = ''
        choices.value = ''
    }

    saveProgress() {
        /// Give pause to allow state to be set asynchronously
        setTimeout(() => {
            window.localStorage.setItem('currentAssessment', JSON.stringify(this.state));
        }, 2000);
    }


    render() {
        //meta title
        document.title = "Admin Quiz Builder";

        function QuizTimer({ expiryTimestamp }) {
            const {
                seconds,
                minutes,
                hours,
                days,
                isRunning,
                start,
                pause,
                resume,
                restart,
            } = useTimer({expiryTimestamp, onExpire: () => console.warn('onExpire called')});

            return (
                <div style={{fontSize: '20px'}}>
                    {
                        seconds < 10 && <div style={{color: 'red'}}> <span>00</span>:<span>0{seconds}</span> </div>
                    }
                    {
                        seconds >= 10 && <div> <span>00</span>:<span>{seconds}</span> </div>
                    }
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <div className="container-fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <div class='row mb--30'>
                                                <div class='col-6'>
                                                    <h4 style={{fontSize: 26}} class="card-title">Assessment Builder</h4>
                                                </div>
                                                <div class='col-6' style={{textAlign: 'right'}}>
                                                    {
                                                        this.state.selectedAssessmentId == null && <button onClick={() => {
                                                            this.saveProgress()
                                                            alert('Progres Saved')
                                                        }} type="button" className="btn btn-secondary waves-effect waves-light mr--25">
                                                            Save Progress
                                                        </button>
                                                    }

                                                    <button onClick={() => {
                                                        if(this.state.selectedAssessmentId == null) {
                                                            this.createAssessment()
                                                        } else {
                                                            this.updateAssessment()
                                                        }

                                                    }} type="button"
                                                            className="btn btn-success waves-effect waves-light">
                                                        {
                                                            this.state.selectedAssessmentId == null
                                                                ? 'Create Assessment' : 'Update Assessment'
                                                        }
                                                    </button>
                                                </div>
                                            </div>

                                            <p class="card-title-desc"> Manage assessments in the system, and select assessments that you'd like to
                                                update or remove properties from
                                            </p>

                                            <div class='row'>
                                                <div class='col-6'>
                                                    <div class="card" style={{ height: '300px'}}>
                                                        <div class="card-body">

                                                            <h4 class="card-title">Assessment Details</h4>

                                                            <form>
                                                                <div class="mb-3 row">
                                                                    <div class="col-md-10">
                                                                        <input className="form-control" type="text"
                                                                               placeholder="Enter Assessment name here (Backend Java)"
                                                                               value={this.state.currentAssessment.name}
                                                                               onChange={e => this.dataBindingOnBaseForm(e.target.id, 'name')}
                                                                               id="baseFormCategory"/>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3 row">
                                                                    <label
                                                                        className="col-md-4 col-form-label">Type</label>
                                                                    <div class="col-md-8">
                                                                        <select
                                                                            id="baseFormType"
                                                                            value={this.state.currentAssessment.type}
                                                                            onChange={e => this.dataBindingOnBaseForm(e.target.id, 'type')}
                                                                            className="form-select">
                                                                            <option value='MULTICHOICE'>Multiple Choice</option>
                                                                            <option value='OPENENDED'>Extended Response select</option>
                                                                            <option value='INTERVALSCALE'>Interval Scale</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3 row">
                                                                    <label
                                                                        className="col-md-4 col-form-label">Availability</label>
                                                                    <div class="col-md-8">
                                                                        <select
                                                                            id="baseFormStatus"
                                                                            value={this.state.currentAssessment.availability}
                                                                            onChange={e => this.dataBindingOnBaseForm(e.target.id, 'availability')}
                                                                            className="form-select">
                                                                            <option value='COMING_SOON'>Coming Soon</option>
                                                                            <option value='AVAILABLE'>Available</option>
                                                                            <option value='DEACTIVATED'>Deactivated</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="mb-3 row">
                                                                    <label
                                                                        className="col-md-4 col-form-label">Assessment Categories</label>
                                                                    <div class="col-md-8">
                                                                        <div className="d-flex flex-wrap gap-2">
                                                                            {
                                                                                this.state.currentAssessment.assessmentCategories.map((category) => {
                                                                                    return <button type="button"
                                                                                                   onClick={() => {
                                                                                                       let assessmentCategories = this.state.currentAssessment.assessmentCategories.filter((categoryData) => {
                                                                                                           let shouldKeep = category !== categoryData
                                                                                                           return shouldKeep
                                                                                                       })
                                                                                                       this.setState({
                                                                                                           ...this.state,
                                                                                                           currentAssessment: {
                                                                                                               ...this.state.currentAssessment,
                                                                                                               assessmentCategories
                                                                                                           }
                                                                                                       })
                                                                                                   }
                                                                                                   }
                                                                                                   className="btn btn-outline-dark waves-effect waves-light">
                                                                                        {category}
                                                                                    </button>
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>


                                                        </div>
                                                    </div>
                                                </div>
                                                <div class='col-6'>
                                                    <div class="card"style={{ height: '300px', overflowY: 'scroll' }}>
                                                        <div >
                                                            <div class="card-body">
                                                                <h4 class="card-title mb--30">Question Viewer</h4>
                                                                {
                                                                    this.state.currentAssessment.questions.map((question, index) => {
                                                                        if(question.title == null || question.title === '') {
                                                                            return null
                                                                        }
                                                                        return <div class="card" onClick={() => {
                                                                        this.gotoQuestion(index)
                                                                        }
                                                                        }>
                                                                            <Link>
                                                                                <div class="card-body">
                                                                                    <h4 class="card-title">
                                                                                        <div className='row'>
                                                                                            <div className='col-10'>
                                                                                                Question {index + 1}:
                                                                                            </div>
                                                                                            <div className='col'>
                                                                                                <i onClick={() => {
                                                                                                    this.removeQuestion(question)
                                                                                                }
                                                                                                } className='bx bx-window-close'/>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='row'>
                                                                                            <div className='col-12'>
                                                                                                <p style={{fontSize: 12}}>
                                                                                                    {
                                                                                                        parse(question.title || '')
                                                                                                    }
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                    </h4>
                                                                                </div>
                                                                            </Link>

                                                                        </div>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>


                                            <div class='row'>
                                                <div class='col-6'>
                                                    <div class="card">
                                                        <div class="card-body">

                                                            <h4 class="card-title">Question {this.state.currentQuestionIndex + 1}</h4>
                                                            <div class="mb-3 row">
                                                                <label
                                                                    className="col-md-3 col-form-label">Title</label>
                                                                <div class="col-md-8">
                                                                    <input className="form-control" type="text"
                                                                           onChange={e => this.dataBindingOnForm(e.target.id, 'title')}
                                                                           onKeyDown={this.addQuestionChoice}
                                                                           value={this.state.currentAssessment.questions[this.state.currentQuestionIndex]?.title}
                                                                           placeholder="Question Title"
                                                                           id="questionTitle"/>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 row">
                                                                <label
                                                                    className="col-md-3 col-form-label">Subtitle</label>
                                                                <div class="col-md-8">
                                                                    <input className="form-control" type="text"
                                                                           onChange={e => this.dataBindingOnForm(e.target.id, 'subtitle')}
                                                                           value={this.state.currentAssessment.questions[this.state.currentQuestionIndex]?.subtitle}
                                                                           placeholder="Question SubTitle (Optional)"
                                                                           id="questionSubTitle"/>
                                                                </div>
                                                            </div>
                                                            <div class="mb-3 row">
                                                                <label htmlFor="example-text-input"
                                                                       className="col-md-3 col-form-label">Category</label>
                                                                <div class="col-md-8">
                                                                    <input className="form-control"
                                                                           list="datalistOptions"
                                                                           id="assessmentCategories"
                                                                           onChange={e => this.dataBindingOnForm(e.target.id, 'category')}
                                                                           value={this.state.currentAssessment.questions[this.state.currentQuestionIndex]?.category}
                                                                           placeholder="Enter a category for this question..."/>
                                                                    <datalist id="datalistOptions">
                                                                        {
                                                                            this.state.currentAssessment.assessmentCategories.map((category) => {
                                                                               return <option value={category}>
                                                                               </option>
                                                                            })
                                                                        }
                                                                    </datalist>
                                                                </div>

                                                            </div>
                                                            <div class="mb-3 row">
                                                                <label
                                                                    className="col-md-3 col-form-label">Duration (in seconds)</label>
                                                                <div class="col-md-8">
                                                                    <input className="form-control" type="text"
                                                                           onChange={e => this.dataBindingOnForm(e.target.id, 'duration')}
                                                                           value={this.state.currentAssessment.questions[this.state.currentQuestionIndex]?.duration}
                                                                           placeholder="Seconds"
                                                                           id="duration"/>
                                                                </div>
                                                            </div>

                                                            <div class="mb-3 row">
                                                                <label
                                                                    className="col-md-3 col-form-label">Choices</label>
                                                                <div class="col-md-7">
                                                                    <input className="form-control" type="text"
                                                                           onChange={e => this.dataBindingQuestionChoice(e.target.id, false)}
                                                                           //value={this.state.currentAssessment.questions[this.state.currentQuestionIndex].choices[this.state.currentAssessment.currentChoiceIndex].choice}
                                                                           placeholder="New Choice"
                                                                           id="choices"/>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <button onClick={() => {
                                                                        this.addQuestionChoice()
                                                                    }}><i class="bx bx-plus-medical"></i></button>
                                                                </div>
                                                            </div>

                                                            {
                                                                this.state.formAlertMessage && <div
                                                                    class="alert alert-info alert-dismissible fade show mb-30"
                                                                    role="alert">
                                                                    <i class="mdi mdi-alert-circle-outline me-2"></i>
                                                                    {this.state.formAlertMessage}
                                                                    <button type="button" className="btn-close"
                                                                            data-bs-dismiss="alert"
                                                                            aria-label="Close"></button>
                                                                </div>

                                                            }

                                                            <div class='row'>
                                                                <div class='col-6'>
                                                                    {
                                                                        this.state.currentQuestionIndex > 0 && <button
                                                                            onClick={() => {
                                                                                this.previousQuestion()
                                                                        }
                                                                        }>
                                                                            Previous
                                                                        </button>
                                                                    }

                                                                </div>
                                                                <div style={{textAlign: 'right'}} class='col-6'>
                                                                    <button onClick={() => {
                                                                        this.processCurrentQuestion()
                                                                    }}>
                                                                        Save & Continue
                                                                    </button>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class='col-6'>
                                                    <div class="card card-header">
                                                        <div className='row'>
                                                            <div className='col'>
                                                                <p class="card-text">Question {this.state.currentQuestionIndex + 1}</p>
                                                            </div>
                                                            <div style={{textAlign: 'right'}} className='col'>
                                                                <p>{this.state.currentAssessment.questions[this.state.currentQuestionIndex]?.duration || 30} secs</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card card-body">
                                                        <h3 class="card-title">
                                                            {
                                                                parse(this.state.currentAssessment.questions[this.state.currentQuestionIndex]?.title || 'Example Title')
                                                            }
                                                        </h3>
                                                        <p class="card-text">
                                                            {
                                                                parse(this.state.currentAssessment.questions[this.state.currentQuestionIndex]?.subtitle || 'Example Subtitle')
                                                            }
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-12">

                                                                <form>
                                                                    {
                                                                        (this.state.currentAssessment.questions[this.state.currentQuestionIndex]?.choices[0].choice == null
                                                                            || this.state.currentAssessment.questions[this.state.currentQuestionIndex]?.choices[0].choice === '') && <div>
                                                                        <div class='row'>
                                                                            <div class='col-12'>
                                                                                <input
                                                                                    type="checkbox"
                                                                                    className="form-check-input"
                                                                                    id="checkbox_1"
                                                                                    onChange={() => {

                                                                                    }}
                                                                                    value={false}
                                                                                />
                                                                                <label className="form-check-label" htmlFor="checkbox_1">
                                                                                    Sample Choice 1
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                            <div class='row'>
                                                                                <div class='col-12'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input"
                                                                                        id="checkbox_1"
                                                                                        onChange={() => {

                                                                                        }}
                                                                                        value={false}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="checkbox_1">
                                                                                        Sample Choice 2
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class='row'>
                                                                                <div class='col-12'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input"
                                                                                        id="checkbox_1"
                                                                                        onChange={() => {

                                                                                        }}
                                                                                        value={false}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="checkbox_1">
                                                                                        Sample Choice 3
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class='row'>
                                                                                <div class='col-12'>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        className="form-check-input"
                                                                                        id="checkbox_1"
                                                                                        onChange={() => {

                                                                                        }}
                                                                                        value={false}
                                                                                    />
                                                                                    <label className="form-check-label" htmlFor="checkbox_1">
                                                                                        Sample Choice 4
                                                                                    </label>
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    }
                                                                    {
                                                                        this.state.currentAssessment.questions[this.state.currentQuestionIndex]?.choices.map((choice) => {
                                                                            if(choice.choice == null || choice.choice === '') {
                                                                                return null
                                                                            }
                                                                            return <div class='row'>
                                                                                <div class='card' style={{ backgroundColor: choice.isEditing ? '#74F2CE' : (choice.isCorrect ? '#0BAB64' : 'white') }}>
                                                                                    <div class='card-body'>
                                                                                        <div class='row'>
                                                                                            <div class='col-9'>
                                                                                                {
                                                                                                    parse(choice.choice)
                                                                                                }
                                                                                            </div>
                                                                                            {
                                                                                                !choice.isEditing && <div class='col-3' >
                                                                                                    <div class='row'>
                                                                                                        <div class='col-1'>
                                                                                                            <i onClick={() => {
                                                                                                                this.processQuestionChoice(choice, 'toggleCorrect')
                                                                                                            }
                                                                                                            } class='bx bx-check-circle'/>
                                                                                                        </div>
                                                                                                        <div class='col'>
                                                                                                            <div class="dropdown">
                                                                                                                <i data-bs-toggle="dropdown" class='bx bxs-edit'/>
                                                                                                                <div
                                                                                                                    class="dropdown-menu"
                                                                                                                    aria-labelledby="dropdownMenuButton">
                                                                                                                    <a className="dropdown-item"
                                                                                                                       onClick={() => {
                                                                                                                           this.processQuestionChoice(choice, 'remove')
                                                                                                                       }
                                                                                                                       }>Remove Choice</a>
                                                                                                                    <a className="dropdown-item"
                                                                                                                       onClick={() => {
                                                                                                                           this.processQuestionChoice(choice, 'toggleCorrect')
                                                                                                                       }
                                                                                                                       }>Toggle Correct Answer</a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                </div>
                                                                                            }

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </form>

                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12">
                                                                <button disabled={!this.state.isAssessmentEnabled} type="button" className="btn btn-primary waves-effect waves-light mt--30"
                                                                >Submit Answer </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

QuizBuilder.propTypes = {
    t: PropTypes.any
};

export default withRouter(QuizBuilder);