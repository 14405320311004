import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import PropTypes from "prop-types";
import {Alert, Card, CardBody, Col, Container, Label, Row} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import profile from "../../assets/images/profile-img.png";
import lightlogo from "../../assets/images/logo-light.svg";
import logo from "../../assets/images/logo.svg";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as Yup from "yup";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {facebook, google} from "../../config";
import {GoogleLogin} from "react-google-login";
import {connect} from "react-redux";
import {adminLoginUser, adminApiError} from "../../store/auth/admin/actions";

class AdminLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }

    render() {
        //meta title
        document.title = "Admin Login";
        window.localStorage.setItem('theme', 'dark');


        return (
            <React.Fragment>
                <div className="home-btn d-none d-sm-block">
                    <Link legacyBehavior to="/" className="text-dark">
                        <i className="bx bx-home h2" />
                    </Link>
                </div>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="overflow-hidden">
                                    <div className="bg-primary bg-soft">
                                        <Row>
                                            <Col className="col-7">
                                                <div className="text-primary p-4">
                                                    <h5 className="text-primary">Welcome Back !</h5>
                                                    <p>Sign in to the management console</p>
                                                </div>
                                            </Col>
                                            <Col className="col-5 align-self-end">
                                                <img src={profile} alt="" className="img-fluid" />
                                            </Col>
                                        </Row>
                                    </div>
                                    <CardBody className="pt-0">
                                        <div className="auth-logo">
                                            <Link legacyBehavior to="/" className="auth-logo-light">
                                                <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                                src={lightlogo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                            />
                          </span>
                                                </div>
                                            </Link>
                                            <Link legacyBehavior to="/" className="auth-logo-dark">
                                                <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                                src={logo}
                                alt=""
                                className="rounded-circle"
                                height="34"
                            />
                          </span>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="p-2">
                                            { (this.props.error) &&
                                                <Alert>
                                                    {'Authentication Error: ' + this.props.error}
                                                </Alert>
                                            }
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    email:
                                                        (this.state && this.state.email) ||
                                                        "admin@themesbrand.com",
                                                    password:
                                                        (this.state && this.state.password) || "123456",
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    email: Yup.string().required(
                                                        "Please Enter Your Email"
                                                    ),
                                                    password: Yup.string().required(
                                                        "Please Enter Valid Password"
                                                    ),
                                                })}
                                                onSubmit={values => {
                                                    this.props.adminLoginUser(values, this.props.history);
                                                }}
                                            >
                                                {({ errors, status, touched }) => (

                                                    <Form className="form-horizontal">
                                                        <div className="mb-3">
                                                            <Label for="email" className="form-label">
                                                                Email
                                                            </Label>
                                                            <Field
                                                                name="email"
                                                                type="text"
                                                                className={
                                                                    "form-control" +
                                                                    (errors.email && touched.email
                                                                        ? " is-invalid"
                                                                        : "")
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="email"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Label for="password" className="form-label">
                                                                Password
                                                            </Label>
                                                            <div className="input-group auth-pass-inputgroup">
                                                                <Field
                                                                    name="password"
                                                                    type="password"
                                                                    autoComplete="true"
                                                                    className={
                                                                        "form-control" +
                                                                        (errors.password && touched.password
                                                                            ? " is-invalid"
                                                                            : "")
                                                                    }
                                                                />
                                                                <button
                                                                    className="btn btn-light "
                                                                    type="button"
                                                                    id="password-addon"
                                                                >
                                                                    <i className="mdi mdi-eye-outline"></i>
                                                                </button>
                                                            </div>
                                                            <ErrorMessage
                                                                name="password"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>

                                                        <div className="form-check">
                                                            <input
                                                                type="checkbox"
                                                                className="form-check-input"
                                                                id="customControlInline"
                                                            />
                                                            <label
                                                                className="form-check-label"
                                                                htmlFor="customControlInline"
                                                            >
                                                                Remember me
                                                            </label>
                                                        </div>

                                                        <div className="mt-3 d-grid">
                                                            <button
                                                                className="btn btn-primary btn-block"
                                                                type="submit"
                                                            >
                                                                Log In
                                                            </button>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <h5 className="font-size-14 mb-3">
                                                                Sign in with
                                                            </h5>

                                                            <ul className="list-inline">
                                                                <li className="list-inline-item">
                                                                    <FacebookLogin
                                                                        appId={facebook.APP_ID}
                                                                        autoLoad={false}
                                                                        callback={this.facebookResponse}
                                                                        render={renderProps => (
                                                                            <Link legacyBehavior
                                                                                  to={""}
                                                                                  className="social-list-item bg-primary text-white border-primary"
                                                                            >
                                                                                <i className="mdi mdi-facebook" />
                                                                            </Link>
                                                                        )}
                                                                    />
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    {google.CLIENT_ID === "" ? (
                                                                        ""
                                                                    ) : (
                                                                        <GoogleLogin
                                                                            clientId={google.CLIENT_ID}
                                                                            render={renderProps => (
                                                                                <Link legacyBehavior
                                                                                      to={""}
                                                                                      className="social-list-item bg-danger text-white border-danger"
                                                                                >
                                                                                    <i className="mdi mdi-google" />
                                                                                </Link>
                                                                            )}
                                                                            onSuccess={this.googleResponse}
                                                                            onFailure={() => {}}
                                                                        />
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </div>

                                                        <div className="mt-4 text-center">
                                                            <Link legacyBehavior
                                                                  to="/forgot-password"
                                                                  className="text-muted"
                                                            >
                                                                <i className="mdi mdi-lock me-1" /> Forgot your
                                                                password?
                                                            </Link>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-5 text-center">
                                    <p>
                                        Don&apos;t have an account?..
                                        <Link legacyBehavior to="register" className="fw-medium text-primary">
                                            Signup Now
                                        </Link>
                                    </p>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

AdminLogin.propTypes = {
    t: PropTypes.any,
    adminApiError: PropTypes.func,
    error: PropTypes.any,
    history: PropTypes.object,
    adminLoginUser: PropTypes.func,
};

const mapStateToProps = state => {
    const { error } = state.AdminLogin;
    return { error };
};

export default withRouter(
    connect(mapStateToProps, { adminLoginUser, adminApiError })(AdminLogin)
);
