export const techSkills = [
    {
        skill: ".net",
        count: "6",
        clean_skill: ".NET",
        skill_group: "Programming Language"
    },
    {
        skill: "asp.net",
        count: "4",
        clean_skill: ".NET",
        skill_group: "Programming Language"
    },
    {
        skill: "access",
        count: "2",
        clean_skill: "Access",
        skill_group: "Database Technology"
    },
    {
        skill: "adobe",
        count: "1",
        clean_skill: "Adobe",
        skill_group: "Software"
    },
    {
        skill: "agile",
        count: "22",
        clean_skill: "Agile",
        skill_group: "Environment"
    },
    {
        skill: "agile environment",
        count: "2",
        clean_skill: "Agile",
        skill_group: "Environment"
    },
    {
        skill: "agile work environment",
        count: "1",
        clean_skill: "Agile",
        skill_group: "Environment"
    },
    {
        skill: "agile work environment - data mining",
        count: "1",
        clean_skill: "Agile",
        skill_group: "Environment"
    },
    {
        skill: "airline contracting",
        count: "1",
        clean_skill: "Airlines",
        skill_group: "Domain Expertise"
    },
    {
        skill: "airline contracting - direct analytics",
        count: "1",
        clean_skill: "Airlines",
        skill_group: "Domain Expertise"
    },
    {
        skill: "u.s. airline",
        count: "2",
        clean_skill: "Airlines",
        skill_group: "Domain Expertise"
    },
    {
        skill: "u.s. travel agency",
        count: "2",
        clean_skill: "Airlines",
        skill_group: "Domain Expertise"
    },
    {
        skill: "algorithm",
        count: "10",
        clean_skill: "Algorithm",
        skill_group: ""
    },
    {
        skill: "algorithmic development",
        count: "2",
        clean_skill: "Algorithm",
        skill_group: ""
    },
    {
        skill: "algorithms",
        count: "69",
        clean_skill: "Algorithm",
        skill_group: ""
    },
    {
        skill: "algorithms - machine learning",
        count: "1",
        clean_skill: "Algorithm",
        skill_group: ""
    },
    {
        skill: "and supervised algorithms.",
        count: "1",
        clean_skill: "Algorithm",
        skill_group: ""
    },
    {
        skill: "amazon web services",
        count: "1",
        clean_skill: "Amazon Web Services",
        skill_group: "Cloud Computing"
    },
    {
        skill: "aws",
        count: "17",
        clean_skill: "Amazon Web Services",
        skill_group: "Cloud Computing"
    },
    {
        skill: "aws cloud services: ec2",
        count: "1",
        clean_skill: "Amazon Web Services",
        skill_group: "Cloud Computing"
    },
    {
        skill: "aws- azure",
        count: "2",
        clean_skill: "Amazon Web Services",
        skill_group: "Cloud Computing"
    },
    {
        skill: "ec2",
        count: "1",
        clean_skill: "Amazon Web Services",
        skill_group: "Cloud Computing"
    },
    {
        skill: "etc.) amazon web services (aws)",
        count: "1",
        clean_skill: "Amazon Web Services",
        skill_group: "Cloud Computing"
    },
    {
        skill: "etl; data; spark; hive; extract; transform; load; sql; aws; design; data sets",
        count: "1",
        clean_skill: "Amazon Web Services",
        skill_group: "Cloud Computing"
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud.",
        count: "1",
        clean_skill: "Amazon Web Services",
        skill_group: "Cloud Computing"
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud. should have hands on experience configuring and onboarding users including developer",
        count: "1",
        clean_skill: "Amazon Web Services",
        skill_group: "Cloud Computing"
    },
    {
        skill: "python - aws",
        count: "1",
        clean_skill: "Amazon Web Services",
        skill_group: "Cloud Computing"
    },
    {
        skill: "anaconda",
        count: "1",
        clean_skill: "Anaconda",
        skill_group: "Platform"
    },
    {
        skill: "anaconda - python",
        count: "1",
        clean_skill: "Anaconda",
        skill_group: "Platform"
    },
    {
        skill: "principal data scientist skills: analytics - statistics - machine learning - ml - data models - big data - pricing revenue - retail - cloud - price optimization - r - python",
        count: "1",
        clean_skill: "Analytics",
        skill_group: ""
    },
    {
        skill: "android",
        count: "2",
        clean_skill: "Android",
        skill_group: "Platform"
    },
    {
        skill: "ansible",
        count: "1",
        clean_skill: "Ansible",
        skill_group: ""
    },
    {
        skill: "apache",
        count: "6",
        clean_skill: "Apache",
        skill_group: "Web Server"
    },
    {
        skill: "api",
        count: "8",
        clean_skill: "API",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "and application development",
        count: "1",
        clean_skill: "Application Development",
        skill_group: ""
    },
    {
        skill: "applied mathematics",
        count: "1",
        clean_skill: "Applied Mathematics",
        skill_group: "Domain Expertise"
    },
    {
        skill: "architect",
        count: "1",
        clean_skill: "Architecture",
        skill_group: "Domain Expertise"
    },
    {
        skill: "architecture",
        count: "20",
        clean_skill: "Architecture",
        skill_group: "Domain Expertise"
    },
    {
        skill: "(machine learning ) and (artificial intelligence ) and (rafa )",
        count: "1",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "ai",
        count: "13",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "ai - data scientist",
        count: "2",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "ai - r",
        count: "1",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "ai & cognitive technology",
        count: "1",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "ai and ml models",
        count: "1",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "ai.ml",
        count: "1",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "ai/ml",
        count: "1",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "artificial intelligence",
        count: "24",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "artificial-intelligence",
        count: "1",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "data science/ai/machine learning",
        count: "1",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "datarobot for quick implementation of ai/ml",
        count: "1",
        clean_skill: "Artificial Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "asp",
        count: "4",
        clean_skill: "ASP",
        skill_group: "Programming Language"
    },
    {
        skill: "asp.net",
        count: "4",
        clean_skill: "ASP",
        skill_group: "Programming Language"
    },
    {
        skill: "asset mgmt/electronic trading",
        count: "1",
        clean_skill: "Asset Management",
        skill_group: "Domain Expertise"
    },
    {
        skill: "astronomy",
        count: "1",
        clean_skill: "Astronomy",
        skill_group: "Domain Expertise"
    },
    {
        skill: "atlassian",
        count: "1",
        clean_skill: "Atlassian",
        skill_group: "Collaboration tools"
    },
    {
        skill: "automated",
        count: "22",
        clean_skill: "Automation",
        skill_group: ""
    },
    {
        skill: "automation",
        count: "2",
        clean_skill: "Automation",
        skill_group: ""
    },
    {
        skill: "automation - machine learning",
        count: "1",
        clean_skill: "Automation",
        skill_group: ""
    },
    {
        skill: "avionics",
        count: "2",
        clean_skill: "Avionics",
        skill_group: "Domain Expertise"
    },
    {
        skill: "bs/ms in computer science",
        count: "1",
        clean_skill: "Bachelors",
        skill_group: "Credential"
    },
    {
        skill: "backend coding",
        count: "1",
        clean_skill: "Backend Coding",
        skill_group: ""
    },
    {
        skill: "bash",
        count: "1",
        clean_skill: "Bash",
        skill_group: "Scripting"
    },
    {
        skill: "bash/shell",
        count: "1",
        clean_skill: "Bash",
        skill_group: "Scripting"
    },
    {
        skill: "unix",
        count: "9",
        clean_skill: "Bash",
        skill_group: "Scripting"
    },
    {
        skill: "bayesian hierarchical modeling",
        count: "4",
        clean_skill: "Bayesian",
        skill_group: "Statistical"
    },
    {
        skill: "bayesian inference",
        count: "2",
        clean_skill: "Bayesian",
        skill_group: "Statistical"
    },
    {
        skill: "bayesian-statistics",
        count: "1",
        clean_skill: "Bayesian",
        skill_group: "Statistical"
    },
    {
        skill: "and behavioral modeling and simulation for bfs/retail",
        count: "1",
        clean_skill: "Behavioral Modeling",
        skill_group: ""
    },
    {
        skill: "benchmark",
        count: "1",
        clean_skill: "Benchmark",
        skill_group: ""
    },
    {
        skill: "big data",
        count: "17",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "big data ecosystem",
        count: "1",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "big data management tools i.e. informatica bdm/idl/eic/idq",
        count: "1",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "big data management/concepts",
        count: "2",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "big data science",
        count: "1",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "big data storage",
        count: "1",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "big data/hadoop",
        count: "1",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "bigdata",
        count: "3",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "distributed computing - big data",
        count: "1",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "large data set organization & analytics",
        count: "2",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "large data sets",
        count: "8",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "principal data scientist skills: analytics - statistics - machine learning - ml - data models - big data - pricing revenue - retail - cloud - price optimization - r - python",
        count: "1",
        clean_skill: "Big Data",
        skill_group: "General Skill"
    },
    {
        skill: "bigquery",
        count: "2",
        clean_skill: "BigQuery",
        skill_group: "Database Technology"
    },
    {
        skill: "bigtable",
        count: "1",
        clean_skill: "BigTable",
        skill_group: "Database Technology"
    },
    {
        skill: "bioinformatics",
        count: "1",
        clean_skill: "Bioinformatics",
        skill_group: "Domain Expertise"
    },
    {
        skill: "biology",
        count: "1",
        clean_skill: "Biology",
        skill_group: "Domain Expertise"
    },
    {
        skill: "biomedical",
        count: "1",
        clean_skill: "Biomedical",
        skill_group: "Domain Expertise"
    },
    {
        skill: "biomedical - data science",
        count: "1",
        clean_skill: "Biomedical",
        skill_group: "Domain Expertise"
    },
    {
        skill: "biostatistics",
        count: "2",
        clean_skill: "Biostatistics",
        skill_group: "Domain Expertise"
    },
    {
        skill: "biostatistics - r",
        count: "1",
        clean_skill: "Biostatistics",
        skill_group: "Domain Expertise"
    },
    {
        skill: "bitbucket",
        count: "1",
        clean_skill: "BitBucket",
        skill_group: "Programming Tool"
    },
    {
        skill: "blueprint",
        count: "1",
        clean_skill: "Blueprint",
        skill_group: ""
    },
    {
        skill: "business concepts",
        count: "1",
        clean_skill: "Business",
        skill_group: "Domain Expertise"
    },
    {
        skill: "business objects",
        count: "7",
        clean_skill: "Business",
        skill_group: "Domain Expertise"
    },
    {
        skill: "business process analysis",
        count: "2",
        clean_skill: "Business",
        skill_group: "Domain Expertise"
    },
    {
        skill: "knowledge of a variety of machine learning and statistical modeling techniques in a business setting",
        count: "1",
        clean_skill: "Business",
        skill_group: "Domain Expertise"
    },
    {
        skill: "business analyst",
        count: "1",
        clean_skill: "Business Analyst",
        skill_group: "Position"
    },
    {
        skill: "business analyst and data and sql",
        count: "1",
        clean_skill: "Business Analyst",
        skill_group: "Position"
    },
    {
        skill: "business analyst data scientist r python jira github tableau powerbi statistics",
        count: "1",
        clean_skill: "Business Analyst",
        skill_group: "Position"
    },
    {
        skill: "bi",
        count: "2",
        clean_skill: "Business Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "bi dashboards",
        count: "1",
        clean_skill: "Business Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "bi engineer/data scientist",
        count: "1",
        clean_skill: "Business Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "business intelligence",
        count: "7",
        clean_skill: "Business Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "business intelligence engineer iii",
        count: "1",
        clean_skill: "Business Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "with thorough knowledge of business intelligence and analytics",
        count: "1",
        clean_skill: "Business Intelligence",
        skill_group: "General Skill"
    },
    {
        skill: "business services general",
        count: "1",
        clean_skill: "Business",
        skill_group: "Domain Expertise"
    },
    {
        skill: "c",
        count: "2",
        clean_skill: "C",
        skill_group: "Programming Language"
    },
    {
        skill: "c/c++",
        count: "1",
        clean_skill: "C",
        skill_group: "Programming Language"
    },
    {
        skill: "c/c++ - data scientist",
        count: "1",
        clean_skill: "C",
        skill_group: "Programming Language"
    },
    {
        skill: "c#",
        count: "7",
        clean_skill: "C#",
        skill_group: "Programming Language"
    },
    {
        skill: "data scientist machine learning statistics r python c# java scala",
        count: "1",
        clean_skill: "C#",
        skill_group: "Programming Language"
    },
    {
        skill: "python/java/c#",
        count: "1",
        clean_skill: "C#",
        skill_group: "Programming Language"
    },
    {
        skill: "c/c++",
        count: "1",
        clean_skill: "C++",
        skill_group: "Programming Language"
    },
    {
        skill: "c/c++ - data scientist",
        count: "1",
        clean_skill: "C++",
        skill_group: "Programming Language"
    },
    {
        skill: "c++",
        count: "19",
        clean_skill: "C++",
        skill_group: "Programming Language"
    },
    {
        skill: "c++ - operations research",
        count: "2",
        clean_skill: "C++",
        skill_group: "Programming Language"
    },
    {
        skill: "java or c++",
        count: "1",
        clean_skill: "C++",
        skill_group: "Programming Language"
    },
    {
        skill: "scala or c++)",
        count: "1",
        clean_skill: "C++",
        skill_group: "Programming Language"
    },
    {
        skill: "caffe",
        count: "1",
        clean_skill: "Caffe",
        skill_group: "Programming Language"
    },
    {
        skill: "data analyst and capacity and visualization and sql and azure",
        count: "1",
        clean_skill: "Capacity",
        skill_group: ""
    },
    {
        skill: "case",
        count: "6",
        clean_skill: "Case",
        skill_group: ""
    },
    {
        skill: "cassandra",
        count: "4",
        clean_skill: "Cassandra",
        skill_group: ""
    },
    {
        skill: "nosql (dynamodb/cassandra/mongodb)",
        count: "1",
        clean_skill: "Cassandra",
        skill_group: ""
    },
    {
        skill: "nosql databases - mongodb-couchbase - cassandra",
        count: "2",
        clean_skill: "Cassandra",
        skill_group: ""
    },
    {
        skill: "celery",
        count: "1",
        clean_skill: "Celery",
        skill_group: "Distributed System"
    },
    {
        skill: "celery - java",
        count: "1",
        clean_skill: "Celery",
        skill_group: "Distributed System"
    },
    {
        skill: "cfa",
        count: "1",
        clean_skill: "CFA",
        skill_group: "Credential"
    },
    {
        skill: "cfa a plus",
        count: "1",
        clean_skill: "CFA",
        skill_group: "Credential"
    },
    {
        skill: "cgi",
        count: "1",
        clean_skill: "CGI",
        skill_group: "Programming Language"
    },
    {
        skill: "chaid",
        count: "1",
        clean_skill: "CHAID",
        skill_group: ""
    },
    {
        skill: "change management",
        count: "1",
        clean_skill: "Change Management",
        skill_group: "Soft Skill"
    },
    {
        skill: "chief data scientist",
        count: "1",
        clean_skill: "Chief Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "cisco",
        count: "1",
        clean_skill: "Cisco",
        skill_group: ""
    },
    {
        skill: "civil",
        count: "2",
        clean_skill: "Civil",
        skill_group: ""
    },
    {
        skill: "claims",
        count: "3",
        clean_skill: "Claims",
        skill_group: ""
    },
    {
        skill: "claims data",
        count: "1",
        clean_skill: "Claims",
        skill_group: ""
    },
    {
        skill: "cloud",
        count: "2",
        clean_skill: "Cloud",
        skill_group: ""
    },
    {
        skill: "cloud enablement/deployment",
        count: "1",
        clean_skill: "Cloud",
        skill_group: ""
    },
    {
        skill: "cloud infrastructure and threat intelligenceexperience with data visualization and programming languages",
        count: "1",
        clean_skill: "Cloud",
        skill_group: ""
    },
    {
        skill: "cloud/analytics",
        count: "1",
        clean_skill: "Cloud",
        skill_group: ""
    },
    {
        skill: "data scientist machine learning statistical modeling database data python r cloud",
        count: "1",
        clean_skill: "Cloud",
        skill_group: ""
    },
    {
        skill: "etc.) gcp or other cloud platforms",
        count: "1",
        clean_skill: "Cloud",
        skill_group: ""
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud.",
        count: "1",
        clean_skill: "Cloud",
        skill_group: ""
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud. should have hands on experience configuring and onboarding users including developer",
        count: "1",
        clean_skill: "Cloud",
        skill_group: ""
    },
    {
        skill: "cloudbase",
        count: "1",
        clean_skill: "CloudBase",
        skill_group: ""
    },
    {
        skill: "cloundera",
        count: "1",
        clean_skill: "Cloudera",
        skill_group: ""
    },
    {
        skill: "clustering",
        count: "1",
        clean_skill: "Clustering",
        skill_group: "Method"
    },
    {
        skill: "clustering algorithms",
        count: "2",
        clean_skill: "Clustering",
        skill_group: "Method"
    },
    {
        skill: "cnn",
        count: "1",
        clean_skill: "CNN",
        skill_group: ""
    },
    {
        skill: "ai & cognitive technology",
        count: "1",
        clean_skill: "Cognitive Technology",
        skill_group: ""
    },
    {
        skill: "cognos",
        count: "4",
        clean_skill: "Cognos",
        skill_group: ""
    },
    {
        skill: "ad hoc reporting",
        count: "1",
        clean_skill: "Communication",
        skill_group: "Soft Skill"
    },
    {
        skill: "computer",
        count: "1",
        clean_skill: "Computer",
        skill_group: ""
    },
    {
        skill: "computer engineering",
        count: "1",
        clean_skill: "Computer Engineering",
        skill_group: ""
    },
    {
        skill: "computer science",
        count: "1",
        clean_skill: "Computer Science",
        skill_group: "Domain Expertise"
    },
    {
        skill: "computer vision",
        count: "1",
        clean_skill: "Computer Vision",
        skill_group: "General Skill"
    },
    {
        skill: "computer vision or developing software libraries that abstract/encapsulate custom hardware devices.",
        count: "1",
        clean_skill: "Computer Vision",
        skill_group: "General Skill"
    },
    {
        skill: "open source ml & dl frameworks - computer vision",
        count: "1",
        clean_skill: "Computer Vision",
        skill_group: "General Skill"
    },
    {
        skill: "consulting",
        count: "13",
        clean_skill: "Consulting",
        skill_group: "Soft Skill"
    },
    {
        skill: "ci/cd/ci-cd/continuous integration/continuous deployment/jenkins",
        count: "1",
        clean_skill: "Continuous Deployment",
        skill_group: ""
    },
    {
        skill: "ci/cd/ci-cd/continuous integration/continuous deployment/jenkins",
        count: "1",
        clean_skill: "Continuous Integration",
        skill_group: ""
    },
    {
        skill: "couchbase programming",
        count: "1",
        clean_skill: "Couchbase",
        skill_group: "Database Technology"
    },
    {
        skill: "nosql databases - mongodb-couchbase - cassandra",
        count: "2",
        clean_skill: "Couchbase",
        skill_group: "Database Technology"
    },
    {
        skill: "cplex",
        count: "1",
        clean_skill: "CPLEX",
        skill_group: ""
    },
    {
        skill: "critical thinking",
        count: "3",
        clean_skill: "Critical Thinking",
        skill_group: "Soft Skill"
    },
    {
        skill: "crm",
        count: "6",
        clean_skill: "CRM",
        skill_group: ""
    },
    {
        skill: "crm - python",
        count: "1",
        clean_skill: "CRM",
        skill_group: ""
    },
    {
        skill: "css",
        count: "5",
        clean_skill: "CSS",
        skill_group: "Programming Language"
    },
    {
        skill: "customer service",
        count: "1",
        clean_skill: "Customer Service",
        skill_group: ""
    },
    {
        skill: "bi dashboards",
        count: "1",
        clean_skill: "Dashboards",
        skill_group: ""
    },
    {
        skill: "business analyst and data and sql",
        count: "1",
        clean_skill: "Data",
        skill_group: ""
    },
    {
        skill: "data",
        count: "5",
        clean_skill: "Data",
        skill_group: ""
    },
    {
        skill: "data scientist machine learning statistical modeling database data python r cloud",
        count: "1",
        clean_skill: "Data",
        skill_group: ""
    },
    {
        skill: "etl; data; spark; hive; extract; transform; load; sql; aws; design; data sets",
        count: "1",
        clean_skill: "Data",
        skill_group: ""
    },
    {
        skill: "(data scientist ) and (data analysis or running data pipelines ) and(machine learning or matlab ) and(python )",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "analysis",
        count: "76",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "analysis service (ssas)",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "analyst",
        count: "17",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "analytical",
        count: "3",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "analytical skills",
        count: "4",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "analytical techniques",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "analytics",
        count: "12",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "applied research",
        count: "3",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "cloud/analytics",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "data analysis",
        count: "40",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "data analysis - sql server",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "data analytics",
        count: "8",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "data mining and statistical analysis",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "data science analytics networks machine learning tensorflow",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "digital analytics",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "direct analytics",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "trend analysis",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "with thorough knowledge of business intelligence and analytics",
        count: "1",
        clean_skill: "Data Analysis",
        skill_group: "General Skill"
    },
    {
        skill: "data analyst",
        count: "3",
        clean_skill: "Data Analyst",
        skill_group: "Position"
    },
    {
        skill: "data analyst and capacity and visualization and sql and azure",
        count: "1",
        clean_skill: "Data Analyst",
        skill_group: "Position"
    },
    {
        skill: "data scientists and data analysts with u.s. citizenship required and able to obtain a top secret or secret clearance",
        count: "1",
        clean_skill: "Data Analyst",
        skill_group: "Position"
    },
    {
        skill: "data architect - r",
        count: "1",
        clean_skill: "Data Architect",
        skill_group: "Position"
    },
    {
        skill: "data architecture",
        count: "2",
        clean_skill: "Data Architecture",
        skill_group: "General Skill"
    },
    {
        skill: "data collection",
        count: "10",
        clean_skill: "Data Collection",
        skill_group: ""
    },
    {
        skill: "data design",
        count: "1",
        clean_skill: "Data Design",
        skill_group: "General Skill"
    },
    {
        skill: "data engineer",
        count: "3",
        clean_skill: "Data Engineer",
        skill_group: "Position"
    },
    {
        skill: "data scientist data pipeline modeling data engineer r sas python spark machine learning",
        count: "1",
        clean_skill: "Data Engineer",
        skill_group: "Position"
    },
    {
        skill: "software engineer - data engineer - sql",
        count: "1",
        clean_skill: "Data Engineer",
        skill_group: "Position"
    },
    {
        skill: "creating large datasets",
        count: "1",
        clean_skill: "Data Engineering",
        skill_group: "General Skill"
    },
    {
        skill: "data engineering",
        count: "3",
        clean_skill: "Data Engineering",
        skill_group: "General Skill"
    },
    {
        skill: "data management systems",
        count: "1",
        clean_skill: "Data Management",
        skill_group: "General Skill"
    },
    {
        skill: "data manipulation/wrangling",
        count: "1",
        clean_skill: "Data Manipulation",
        skill_group: "General Skill"
    },
    {
        skill: "etc.) to manipulate data and draw insights from large data sets.",
        count: "1",
        clean_skill: "Data Manipulation",
        skill_group: "General Skill"
    },
    {
        skill: "transformation",
        count: "1",
        clean_skill: "Data Manipulation",
        skill_group: "General Skill"
    },
    {
        skill: "wrangle",
        count: "1",
        clean_skill: "Data Manipulation",
        skill_group: "General Skill"
    },
    {
        skill: "agile work environment - data mining",
        count: "1",
        clean_skill: "Data Mining",
        skill_group: "General Skill"
    },
    {
        skill: "data mining",
        count: "54",
        clean_skill: "Data Mining",
        skill_group: "General Skill"
    },
    {
        skill: "data mining and statistical analysis",
        count: "1",
        clean_skill: "Data Mining",
        skill_group: "General Skill"
    },
    {
        skill: "r required; python & spark | data mining | data mining expertise: sql required; machine learning | hadoop framework",
        count: "1",
        clean_skill: "Data Mining",
        skill_group: "General Skill"
    },
    {
        skill: "principal data scientist skills: analytics - statistics - machine learning - ml - data models - big data - pricing revenue - retail - cloud - price optimization - r - python",
        count: "1",
        clean_skill: "Data Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "conceptual and logical data modeling",
        count: "1",
        clean_skill: "Data Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "data modeling",
        count: "16",
        clean_skill: "Data Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "data modeling and hypothesis generation",
        count: "1",
        clean_skill: "Data Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "data modeling for analytics",
        count: "2",
        clean_skill: "Data Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "data modelling",
        count: "1",
        clean_skill: "Data Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "(data scientist ) and (data analysis or running data pipelines ) and(machine learning or matlab ) and(python )",
        count: "1",
        clean_skill: "Data Pipelines",
        skill_group: ""
    },
    {
        skill: "data pipelines",
        count: "1",
        clean_skill: "Data Pipelines",
        skill_group: ""
    },
    {
        skill: "data pipelining",
        count: "2",
        clean_skill: "Data Pipelines",
        skill_group: ""
    },
    {
        skill: "data scientist data pipeline modeling data engineer r sas python spark machine learning",
        count: "1",
        clean_skill: "Data Pipelines",
        skill_group: ""
    },
    {
        skill: "biomedical - data science",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "data science",
        count: "49",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "data science - python",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "data science analytics networks machine learning tensorflow",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "data science and implementations in python",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "data science python",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "data science; statistics",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "data science/ai/machine learning",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "data sciences",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "data-science",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "experience with nlp technologies/data science in a clinical background",
        count: "2",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "life science - data science",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "machine learning/data science/data scientist",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "must have skills: data science python sas",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "prior teaching experience (not essential) - data science",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "python - data science",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "python software developer background; ml; data science; system programming; webservices",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "teaching technical concepts - data science",
        count: "1",
        clean_skill: "Data Science",
        skill_group: ""
    },
    {
        skill: "(data scientist ) and (data analysis or running data pipelines ) and(machine learning or matlab ) and(python )",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "ai - data scientist",
        count: "2",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "azure - data science",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "business analyst data scientist r python jira github tableau powerbi statistics",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "data scientist",
        count: "58",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "data scientist data pipeline modeling data engineer r sas python spark machine learning",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "data scientist level 1 experience with data sets",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "data scientist machine learning",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "data scientist machine learning statistical modeling database data python r cloud",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "data scientist machine learning statistics r python c# java scala",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "data scientists and data analysts with u.s. citizenship required and able to obtain a top secret or secret clearance",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "machine learning - data scientist",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "machine learning/data science/data scientist",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "marketing data scientist/analyst",
        count: "1",
        clean_skill: "Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "etl; data; spark; hive; extract; transform; load; sql; aws; design; data sets",
        count: "1",
        clean_skill: "Data Sets",
        skill_group: ""
    },
    {
        skill: "data streaming",
        count: "1",
        clean_skill: "Data Streaming",
        skill_group: ""
    },
    {
        skill: "azure sql data warehouse",
        count: "1",
        clean_skill: "Data Warehouse",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "data warehouse",
        count: "11",
        clean_skill: "Data Warehouse",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "data warehouse reporting",
        count: "1",
        clean_skill: "Data Warehouse",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "data warehousing",
        count: "1",
        clean_skill: "Data Warehouse",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "datawarehouse",
        count: "1",
        clean_skill: "Data Warehouse",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "data scientist machine learning statistical modeling database data python r cloud",
        count: "1",
        clean_skill: "Database",
        skill_group: ""
    },
    {
        skill: "database",
        count: "38",
        clean_skill: "Database",
        skill_group: ""
    },
    {
        skill: "database concepts and sql",
        count: "1",
        clean_skill: "Database",
        skill_group: ""
    },
    {
        skill: "dba",
        count: "1",
        clean_skill: "Database Administrator",
        skill_group: "Position"
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "Databot",
        skill_group: ""
    },
    {
        skill: "datarobot",
        count: "1",
        clean_skill: "DataRobot",
        skill_group: ""
    },
    {
        skill: "datarobot for quick implementation of ai/ml",
        count: "1",
        clean_skill: "DataRobot",
        skill_group: ""
    },
    {
        skill: "dax",
        count: "1",
        clean_skill: "DAX",
        skill_group: ""
    },
    {
        skill: "db2",
        count: "2",
        clean_skill: "DB2",
        skill_group: ""
    },
    {
        skill: "ibm db2 and sql server",
        count: "1",
        clean_skill: "DB2",
        skill_group: ""
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "Decision Tree",
        skill_group: ""
    },
    {
        skill: "1. python 2. nlp 3. deep learning",
        count: "1",
        clean_skill: "Deep Learning",
        skill_group: ""
    },
    {
        skill: "deep learning",
        count: "10",
        clean_skill: "Deep Learning",
        skill_group: ""
    },
    {
        skill: "deep learning and machine learning and research and statistical models and (python",
        count: "1",
        clean_skill: "Deep Learning",
        skill_group: ""
    },
    {
        skill: "deep learning networks",
        count: "2",
        clean_skill: "Deep Learning",
        skill_group: ""
    },
    {
        skill: "etl; data; spark; hive; extract; transform; load; sql; aws; design; data sets",
        count: "1",
        clean_skill: "Design",
        skill_group: ""
    },
    {
        skill: "developer",
        count: "7",
        clean_skill: "Developer",
        skill_group: "Position"
    },
    {
        skill: "development",
        count: "60",
        clean_skill: "Development",
        skill_group: ""
    },
    {
        skill: "development(java)",
        count: "1",
        clean_skill: "Development",
        skill_group: ""
    },
    {
        skill: "etl development",
        count: "1",
        clean_skill: "Development",
        skill_group: ""
    },
    {
        skill: "devops",
        count: "1",
        clean_skill: "DevOps",
        skill_group: ""
    },
    {
        skill: "director",
        count: "9",
        clean_skill: "Director",
        skill_group: "Position"
    },
    {
        skill: "dce",
        count: "2",
        clean_skill: "Distributed Computing Environment",
        skill_group: ""
    },
    {
        skill: "distributed computing",
        count: "1",
        clean_skill: "Distributed Computing Environment",
        skill_group: ""
    },
    {
        skill: "distributed computing - big data",
        count: "1",
        clean_skill: "Distributed Computing Environment",
        skill_group: ""
    },
    {
        skill: "distribution",
        count: "2",
        clean_skill: "Distribution",
        skill_group: ""
    },
    {
        skill: "django",
        count: "2",
        clean_skill: "Django",
        skill_group: ""
    },
    {
        skill: "docker",
        count: "1",
        clean_skill: "Docker",
        skill_group: ""
    },
    {
        skill: "business requirements",
        count: "12",
        clean_skill: "Documentation",
        skill_group: ""
    },
    {
        skill: "documentation",
        count: "2",
        clean_skill: "Documentation",
        skill_group: ""
    },
    {
        skill: "requirements gathering",
        count: "2",
        clean_skill: "Documentation",
        skill_group: ""
    },
    {
        skill: "domino",
        count: "1",
        clean_skill: "Domino",
        skill_group: ""
    },
    {
        skill: "dynamic pricing",
        count: "1",
        clean_skill: "Dynamic Pricing",
        skill_group: "Domain Expertise"
    },
    {
        skill: "machine learning - dynamic pricing",
        count: "1",
        clean_skill: "Dynamic Pricing",
        skill_group: ""
    },
    {
        skill: "dynamodb",
        count: "1",
        clean_skill: "DynamoDB",
        skill_group: ""
    },
    {
        skill: "nosql (dynamodb/cassandra/mongodb)",
        count: "1",
        clean_skill: "DynamoDB",
        skill_group: ""
    },
    {
        skill: "ecommerce",
        count: "1",
        clean_skill: "eCommerce",
        skill_group: ""
    },
    {
        skill: "econometrics",
        count: "4",
        clean_skill: "Econometrics",
        skill_group: ""
    },
    {
        skill: "elastic search",
        count: "1",
        clean_skill: "Elasticsearch",
        skill_group: ""
    },
    {
        skill: "elasticsearch",
        count: "3",
        clean_skill: "Elasticsearch",
        skill_group: ""
    },
    {
        skill: "emr",
        count: "2",
        clean_skill: "Electronic Medical Record",
        skill_group: ""
    },
    {
        skill: "asset mgmt/electronic trading",
        count: "1",
        clean_skill: "Electronic Trading",
        skill_group: "Domain Expertise"
    },
    {
        skill: "embedded",
        count: "4",
        clean_skill: "Embedded",
        skill_group: ""
    },
    {
        skill: "business intelligence engineer iii",
        count: "1",
        clean_skill: "Engineer",
        skill_group: "Position"
    },
    {
        skill: "engineer",
        count: "1",
        clean_skill: "Engineer",
        skill_group: "Position"
    },
    {
        skill: "engineering",
        count: "1",
        clean_skill: "Engineering",
        skill_group: ""
    },
    {
        skill: "enterprise architect",
        count: "1",
        clean_skill: "Enterprise Architect",
        skill_group: "Position"
    },
    {
        skill: "erp",
        count: "1",
        clean_skill: "Enterprise Resource Planing",
        skill_group: "Soft Skill"
    },
    {
        skill: "environmental",
        count: "2",
        clean_skill: "Environmental",
        skill_group: ""
    },
    {
        skill: "erwin",
        count: "1",
        clean_skill: "Erwin",
        skill_group: ""
    },
    {
        skill: "erwin r7.0",
        count: "1",
        clean_skill: "Erwin",
        skill_group: ""
    },
    {
        skill: "excel",
        count: "16",
        clean_skill: "Excel",
        skill_group: ""
    },
    {
        skill: "microsoft excel",
        count: "1",
        clean_skill: "Excel",
        skill_group: ""
    },
    {
        skill: "chef",
        count: "1",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "comprehend",
        count: "1",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "full relocation package",
        count: "1",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "ibm",
        count: "4",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "n/a",
        count: "1",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "or other scripting languages",
        count: "1",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "rational",
        count: "1",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "release",
        count: "2",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "rwd",
        count: "2",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "see job description",
        count: "1",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "sme in statistics",
        count: "1",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "",
        count: "42",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "including distributions",
        count: "1",
        clean_skill: "Exclude",
        skill_group: "Exclude"
    },
    {
        skill: "2 years industry exp",
        count: "2",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "3+ years experience doing data science with r and python in a professional",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "5+ years of experience in a similar role and experience in web attribution analytics.",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "7 years of progressively increasing responsibilities (marketing",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "9+ years of demonstrated experience leading teams",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "at least 2 to 4 years professional experience in nlp",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "cloud infrastructure and threat intelligenceexperience with data visualization and programming languages",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "data scientist level 1 experience with data sets",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "experience using statistical computer languages (r",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "experience with machine learning",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "experience with nlp technologies/data science in a clinical background",
        count: "2",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "financial data experience",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "financial experience",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "fintech exp",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "masters degree with 5 years of experience in creating machine learning pipelines",
        count: "1",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "model development experience",
        count: "2",
        clean_skill: "Experience",
        skill_group: ""
    },
    {
        skill: "experimental design",
        count: "2",
        clean_skill: "Experimental Design",
        skill_group: ""
    },
    {
        skill: "experimental design - sql",
        count: "2",
        clean_skill: "Experimental Design",
        skill_group: ""
    },
    {
        skill: "reporting&etl",
        count: "1",
        clean_skill: "Extract Transform Load",
        skill_group: "General Skill"
    },
    {
        skill: "etl",
        count: "9",
        clean_skill: "Extract Transform Load",
        skill_group: "General Skill"
    },
    {
        skill: "etl and big data platforms",
        count: "1",
        clean_skill: "Extract Transform Load",
        skill_group: "General Skill"
    },
    {
        skill: "etl development",
        count: "1",
        clean_skill: "Extract Transform Load",
        skill_group: "General Skill"
    },
    {
        skill: "etl tools knowledge is required.",
        count: "1",
        clean_skill: "Extract Transform Load",
        skill_group: "General Skill"
    },
    {
        skill: "etl; data; spark; hive; extract; transform; load; sql; aws; design; data sets",
        count: "1",
        clean_skill: "Extract Transform Load",
        skill_group: "General Skill"
    },
    {
        skill: "finance",
        count: "1",
        clean_skill: "Finance",
        skill_group: "Domain Expertise"
    },
    {
        skill: "financial",
        count: "1",
        clean_skill: "Finance",
        skill_group: "Domain Expertise"
    },
    {
        skill: "financial data experience",
        count: "1",
        clean_skill: "Finance",
        skill_group: "Domain Expertise"
    },
    {
        skill: "financial experience",
        count: "1",
        clean_skill: "Finance",
        skill_group: "Domain Expertise"
    },
    {
        skill: "financial industry",
        count: "1",
        clean_skill: "Finance",
        skill_group: "Domain Expertise"
    },
    {
        skill: "fintech",
        count: "1",
        clean_skill: "FinTech",
        skill_group: ""
    },
    {
        skill: "fintech exp",
        count: "1",
        clean_skill: "FinTech",
        skill_group: ""
    },
    {
        skill: "flash",
        count: "1",
        clean_skill: "Flash",
        skill_group: ""
    },
    {
        skill: "forecast",
        count: "1",
        clean_skill: "Forecast",
        skill_group: ""
    },
    {
        skill: "foundation",
        count: "6",
        clean_skill: "Foundation",
        skill_group: ""
    },
    {
        skill: "machine learning and supervised machine learning to cybersecurity or fraud detectionworking knowledge of security detection tools",
        count: "1",
        clean_skill: "Fraud Detection",
        skill_group: ""
    },
    {
        skill: "full funnel analytics",
        count: "1",
        clean_skill: "Full Funnel Analytics",
        skill_group: ""
    },
    {
        skill: "full funnel analytics - marketing analytics",
        count: "1",
        clean_skill: "Full Funnel Analytics",
        skill_group: ""
    },
    {
        skill: "full stack",
        count: "1",
        clean_skill: "Full Stack",
        skill_group: ""
    },
    {
        skill: "glm",
        count: "2",
        clean_skill: "Generalized Linear Model",
        skill_group: "Method"
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "Generalized Linear Model",
        skill_group: "Method"
    },
    {
        skill: "genetic",
        count: "14",
        clean_skill: "Genetics",
        skill_group: "Domain Expertise"
    },
    {
        skill: "genetics",
        count: "1",
        clean_skill: "Genetics",
        skill_group: "Domain Expertise"
    },
    {
        skill: "gis",
        count: "3",
        clean_skill: "GIS",
        skill_group: ""
    },
    {
        skill: "git",
        count: "4",
        clean_skill: "Git",
        skill_group: "Programming Tool"
    },
    {
        skill: "team setting (including git & unit testing)",
        count: "1",
        clean_skill: "Git",
        skill_group: "Programming Tool"
    },
    {
        skill: "business analyst data scientist r python jira github tableau powerbi statistics",
        count: "1",
        clean_skill: "GitHub",
        skill_group: "Programming Tool"
    },
    {
        skill: "go",
        count: "3",
        clean_skill: "Go",
        skill_group: "Programming Language"
    },
    {
        skill: "golang",
        count: "2",
        clean_skill: "Go",
        skill_group: "Programming Language"
    },
    {
        skill: "golang - spark",
        count: "1",
        clean_skill: "Go",
        skill_group: "Programming Language"
    },
    {
        skill: "google",
        count: "2",
        clean_skill: "Google",
        skill_group: ""
    },
    {
        skill: "google analytics",
        count: "2",
        clean_skill: "Google Analytics",
        skill_group: ""
    },
    {
        skill: "etc.) gcp or other cloud platforms",
        count: "1",
        clean_skill: "Google Cloud Platform",
        skill_group: ""
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud.",
        count: "1",
        clean_skill: "Google Cloud Platform",
        skill_group: ""
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud. should have hands on experience configuring and onboarding users including developer",
        count: "1",
        clean_skill: "Google Cloud Platform",
        skill_group: ""
    },
    {
        skill: "gcp",
        count: "3",
        clean_skill: "Google Cloud Platform",
        skill_group: ""
    },
    {
        skill: "public cloud",
        count: "1",
        clean_skill: "Google Cloud Platform",
        skill_group: ""
    },
    {
        skill: "gpu",
        count: "2",
        clean_skill: "GPU",
        skill_group: ""
    },
    {
        skill: "graphics",
        count: "1",
        clean_skill: "Graphics",
        skill_group: ""
    },
    {
        skill: "h2o or azure ml",
        count: "1",
        clean_skill: "H2O",
        skill_group: ""
    },
    {
        skill: "apache hadoop",
        count: "1",
        clean_skill: "Hadoop",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "apache spark or kubernetes or hortonworks or hadoop",
        count: "1",
        clean_skill: "Hadoop",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "big data/hadoop",
        count: "1",
        clean_skill: "Hadoop",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hadoop",
        count: "76",
        clean_skill: "Hadoop",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hadoop - data",
        count: "1",
        clean_skill: "Hadoop",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hadoop - hive - pig",
        count: "2",
        clean_skill: "Hadoop",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hadoop hbase",
        count: "1",
        clean_skill: "Hadoop",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "pyspark hadoop",
        count: "1",
        clean_skill: "Hadoop",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "r required; python & spark | data mining | data mining expertise: sql required; machine learning | hadoop framework",
        count: "1",
        clean_skill: "Hadoop",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hardware",
        count: "4",
        clean_skill: "Hardware",
        skill_group: ""
    },
    {
        skill: "hadoop hbase",
        count: "1",
        clean_skill: "HBase",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hbase",
        count: "4",
        clean_skill: "HBase",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hdfs",
        count: "2",
        clean_skill: "HDFS",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hdfs/ hive/ impala",
        count: "1",
        clean_skill: "HDFS",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "healthcare",
        count: "1",
        clean_skill: "Healthcare",
        skill_group: "Domain Expertise"
    },
    {
        skill: "healthcare analytics",
        count: "1",
        clean_skill: "Healthcare",
        skill_group: "Domain Expertise"
    },
    {
        skill: "bayesian hierarchical modeling",
        count: "4",
        clean_skill: "Hierarchical Modeling",
        skill_group: "Mathematical "
    },
    {
        skill: "etl; data; spark; hive; extract; transform; load; sql; aws; design; data sets",
        count: "1",
        clean_skill: "Hive",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hadoop - hive - pig",
        count: "2",
        clean_skill: "Hive",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hdfs/ hive/ impala",
        count: "1",
        clean_skill: "Hive",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hive",
        count: "17",
        clean_skill: "Hive",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hive - python",
        count: "1",
        clean_skill: "Hive",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "apache spark or kubernetes or hortonworks or hadoop",
        count: "1",
        clean_skill: "Hortonworks",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "hiin analyst",
        count: "1",
        clean_skill: "Hospital Improvement Innovation Network Analyst",
        skill_group: "Position"
    },
    {
        skill: "html",
        count: "6",
        clean_skill: "HTML",
        skill_group: ""
    },
    {
        skill: "http",
        count: "8",
        clean_skill: "HTTP",
        skill_group: ""
    },
    {
        skill: "hypothesis testing",
        count: "4",
        clean_skill: "Hypothesis Testing",
        skill_group: ""
    },
    {
        skill: "image analytics",
        count: "1",
        clean_skill: "Image Analytics",
        skill_group: ""
    },
    {
        skill: "image analytics - python",
        count: "1",
        clean_skill: "Image Analytics",
        skill_group: ""
    },
    {
        skill: "image processing",
        count: "3",
        clean_skill: "Image Analytics",
        skill_group: ""
    },
    {
        skill: "hdfs/ hive/ impala",
        count: "1",
        clean_skill: "Impala",
        skill_group: ""
    },
    {
        skill: "ieor",
        count: "1",
        clean_skill: "Industrial Engineering and Operations Research",
        skill_group: ""
    },
    {
        skill: "reinforcement learningm probabistic inference",
        count: "1",
        clean_skill: "Inference",
        skill_group: ""
    },
    {
        skill: "big data management tools i.e. informatica bdm/idl/eic/idq",
        count: "1",
        clean_skill: "Informatica",
        skill_group: ""
    },
    {
        skill: "informatica",
        count: "2",
        clean_skill: "Informatica",
        skill_group: ""
    },
    {
        skill: "information retrieval",
        count: "3",
        clean_skill: "Information Retrieval",
        skill_group: ""
    },
    {
        skill: "insurance",
        count: "1",
        clean_skill: "Insurance",
        skill_group: "Domain Expertise"
    },
    {
        skill: "intern",
        count: "5",
        clean_skill: "Intern",
        skill_group: "Position"
    },
    {
        skill: "it",
        count: "3",
        clean_skill: "IT",
        skill_group: ""
    },
    {
        skill: "iterative",
        count: "2",
        clean_skill: "Iterative Development",
        skill_group: ""
    },
    {
        skill: "iterative development",
        count: "1",
        clean_skill: "Iterative Development",
        skill_group: ""
    },
    {
        skill: "celery - java",
        count: "1",
        clean_skill: "Java",
        skill_group: "Programming Language"
    },
    {
        skill: "data scientist machine learning statistics r python c# java scala",
        count: "1",
        clean_skill: "Java",
        skill_group: "Programming Language"
    },
    {
        skill: "development(java)",
        count: "1",
        clean_skill: "Java",
        skill_group: "Programming Language"
    },
    {
        skill: "java",
        count: "76",
        clean_skill: "Java",
        skill_group: "Programming Language"
    },
    {
        skill: "java or c++",
        count: "1",
        clean_skill: "Java",
        skill_group: "Programming Language"
    },
    {
        skill: "one modern programming language: java",
        count: "1",
        clean_skill: "Java",
        skill_group: "Programming Language"
    },
    {
        skill: "python or java",
        count: "2",
        clean_skill: "Java",
        skill_group: "Programming Language"
    },
    {
        skill: "python/java/c#",
        count: "1",
        clean_skill: "Java",
        skill_group: "Programming Language"
    },
    {
        skill: "scala or javaexperience driving research and early stage projects to production implementationcreative approach to problem solving and using leading edge tools to build innovative solutionsteam player with strong desire to learn",
        count: "1",
        clean_skill: "Java",
        skill_group: "Programming Language"
    },
    {
        skill: "jdbc",
        count: "1",
        clean_skill: "Java Database Connectivity",
        skill_group: ""
    },
    {
        skill: "javascript",
        count: "14",
        clean_skill: "JavaScript",
        skill_group: "Programming Language"
    },
    {
        skill: "jda software",
        count: "1",
        clean_skill: "JDA Software",
        skill_group: ""
    },
    {
        skill: "machine learning jda software",
        count: "1",
        clean_skill: "JDA Software",
        skill_group: ""
    },
    {
        skill: "ci/cd/ci-cd/continuous integration/continuous deployment/jenkins",
        count: "1",
        clean_skill: "Jenkins",
        skill_group: ""
    },
    {
        skill: "jenkins",
        count: "1",
        clean_skill: "Jenkins",
        skill_group: ""
    },
    {
        skill: "business analyst data scientist r python jira github tableau powerbi statistics",
        count: "1",
        clean_skill: "Jira",
        skill_group: "Programming Tool"
    },
    {
        skill: "jquery",
        count: "4",
        clean_skill: "jQuery",
        skill_group: "Programming Language"
    },
    {
        skill: "jquery/javascript programming (including scripting / coding)",
        count: "1",
        clean_skill: "jQuery",
        skill_group: "Programming Language"
    },
    {
        skill: "jr data scientist",
        count: "1",
        clean_skill: "Jr. Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "julia",
        count: "8",
        clean_skill: "Julia",
        skill_group: "Programming Language"
    },
    {
        skill: "kafka",
        count: "1",
        clean_skill: "Kafka",
        skill_group: ""
    },
    {
        skill: "postgres) kafka",
        count: "1",
        clean_skill: "Kafka",
        skill_group: ""
    },
    {
        skill: "presto - kafka",
        count: "1",
        clean_skill: "Kafka",
        skill_group: ""
    },
    {
        skill: "kaggle",
        count: "1",
        clean_skill: "Kagle",
        skill_group: ""
    },
    {
        skill: "kdb",
        count: "1",
        clean_skill: "KDB",
        skill_group: ""
    },
    {
        skill: "keras",
        count: "4",
        clean_skill: "Keras",
        skill_group: ""
    },
    {
        skill: "etc. o excellent understanding of fundamental machine learning techniques and algorithms",
        count: "1",
        clean_skill: "Knowledge",
        skill_group: ""
    },
    {
        skill: "kubernates",
        count: "1",
        clean_skill: "Kubernates",
        skill_group: ""
    },
    {
        skill: "apache spark or kubernetes or hortonworks or hadoop",
        count: "1",
        clean_skill: "Kubernetes",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "semi-supervised (label propagation)",
        count: "1",
        clean_skill: "Labeling",
        skill_group: ""
    },
    {
        skill: "labeling",
        count: "1",
        clean_skill: "Labeling",
        skill_group: ""
    },
    {
        skill: "laboratory",
        count: "1",
        clean_skill: "Laboratory",
        skill_group: ""
    },
    {
        skill: "lambda functions",
        count: "1",
        clean_skill: "Lambda Functions",
        skill_group: ""
    },
    {
        skill: "lambda tier",
        count: "1",
        clean_skill: "Lambda Functions",
        skill_group: ""
    },
    {
        skill: "lead data scientist - high growth startup - nyc - 160k+",
        count: "1",
        clean_skill: "Lead Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "lead generation",
        count: "2",
        clean_skill: "Lead Generation",
        skill_group: ""
    },
    {
        skill: "9+ years of demonstrated experience leading teams",
        count: "1",
        clean_skill: "Leadership",
        skill_group: "Soft Skill"
    },
    {
        skill: "administration",
        count: "1",
        clean_skill: "Leadership",
        skill_group: "Soft Skill"
    },
    {
        skill: "leadership",
        count: "2",
        clean_skill: "Leadership",
        skill_group: "Soft Skill"
    },
    {
        skill: "libraries",
        count: "7",
        clean_skill: "Libraries",
        skill_group: "Domain Expertise"
    },
    {
        skill: "life science",
        count: "1",
        clean_skill: "Life Science",
        skill_group: "Domain Expertise"
    },
    {
        skill: "life science - data science",
        count: "1",
        clean_skill: "Life Science",
        skill_group: "Domain Expertise"
    },
    {
        skill: "life-sciences",
        count: "1",
        clean_skill: "Life Science",
        skill_group: "Domain Expertise"
    },
    {
        skill: "lifecycle",
        count: "7",
        clean_skill: "Lifecycle",
        skill_group: ""
    },
    {
        skill: "linear",
        count: "1",
        clean_skill: "Linear",
        skill_group: ""
    },
    {
        skill: "linux",
        count: "18",
        clean_skill: "Linux",
        skill_group: ""
    },
    {
        skill: "linux os",
        count: "2",
        clean_skill: "Linux",
        skill_group: ""
    },
    {
        skill: "logging systems",
        count: "1",
        clean_skill: "Logging Systems",
        skill_group: ""
    },
    {
        skill: "logistic regression",
        count: "1",
        clean_skill: "Logistic Regression",
        skill_group: ""
    },
    {
        skill: "looker",
        count: "1",
        clean_skill: "Looker",
        skill_group: "Business Intelligence"
    },
    {
        skill: "(data scientist ) and (data analysis or running data pipelines ) and(machine learning or matlab ) and(python )",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "(machine learning ) and (artificial intelligence ) and (rafa )",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "ai and ml models",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "ai.ml",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "ai/ml",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "algorithms - machine learning",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "automation - machine learning",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "azure machine learning",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "data science analytics networks machine learning tensorflow",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "data science/ai/machine learning",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "data scientist data pipeline modeling data engineer r sas python spark machine learning",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "data scientist machine learning",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "data scientist machine learning statistical modeling database data python r cloud",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "data scientist machine learning statistics r python c# java scala",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "datarobot for quick implementation of ai/ml",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "deep learning and machine learning and research and statistical models and (python",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "experience with machine learning",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "h2o or azure ml",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "knowledge of a variety of machine learning and statistical modeling techniques in a business setting",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "mach",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine leaning",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning",
        count: "90",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning - data scientist",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning - dynamic pricing",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning - python",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning (aml)python",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning (ml)",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning algorithms",
        count: "6",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning and statistical modeling techniques",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning and supervised machine learning to cybersecurity or fraud detectionworking knowledge of security detection tools",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning and tensorflow and statistical model",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning jda software",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning models",
        count: "2",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning python sql / nosql databases",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning tools like spark mlib",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning/data science/data scientist",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "machine-learning",
        count: "2",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "masters degree with 5 years of experience in creating machine learning pipelines",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "model building",
        count: "4",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "model development experience",
        count: "2",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "modeling",
        count: "58",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "modeling - python",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "modeling-and-simulation",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "neural",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "neural network.",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "neural networks",
        count: "2",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "neuro networks",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "open source ml & dl frameworks",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "open source ml & dl frameworks - computer vision",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "or python and experience with languages/ frameworks used in ml & analytics: spark",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "principal data scientist skills: analytics - statistics - machine learning - ml - data models - big data - pricing revenue - retail - cloud - price optimization - r - python",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "python - machine learning",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "python software developer background; ml; data science; system programming; webservices",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "r required; python & spark | data mining | data mining expertise: sql required; machine learning | hadoop framework",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "random forest",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "random forests",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "such as k-nn",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "unsupervised (clustering)",
        count: "1",
        clean_skill: "Machine Learning",
        skill_group: "General Skill"
    },
    {
        skill: "mahout",
        count: "1",
        clean_skill: "Mahout",
        skill_group: ""
    },
    {
        skill: "management",
        count: "51",
        clean_skill: "Management",
        skill_group: ""
    },
    {
        skill: "manager",
        count: "6",
        clean_skill: "Manager",
        skill_group: "Position"
    },
    {
        skill: "mapreduce",
        count: "4",
        clean_skill: "MapReduce",
        skill_group: ""
    },
    {
        skill: "digital marketing",
        count: "1",
        clean_skill: "Marketing",
        skill_group: "Domain Expertise"
    },
    {
        skill: "full funnel analytics - marketing analytics",
        count: "1",
        clean_skill: "Marketing",
        skill_group: "Domain Expertise"
    },
    {
        skill: "marketing",
        count: "4",
        clean_skill: "Marketing",
        skill_group: "Domain Expertise"
    },
    {
        skill: "marketing analysis",
        count: "1",
        clean_skill: "Marketing",
        skill_group: "Domain Expertise"
    },
    {
        skill: "marketing analytics",
        count: "1",
        clean_skill: "Marketing",
        skill_group: "Domain Expertise"
    },
    {
        skill: "marketing data scientist/analyst",
        count: "1",
        clean_skill: "Marketing",
        skill_group: "Domain Expertise"
    },
    {
        skill: "marketing technologies",
        count: "1",
        clean_skill: "Marketing",
        skill_group: "Domain Expertise"
    },
    {
        skill: "marketing/advertising analytics",
        count: "2",
        clean_skill: "Marketing",
        skill_group: "Domain Expertise"
    },
    {
        skill: "master data management analyst",
        count: "1",
        clean_skill: "Master Data Management Analyst",
        skill_group: "Position"
    },
    {
        skill: "mdm",
        count: "1",
        clean_skill: "Master Data Management Analyst",
        skill_group: "Position"
    },
    {
        skill: "mdm analyst",
        count: "1",
        clean_skill: "Master Data Management Analyst",
        skill_group: "Position"
    },
    {
        skill: "ms in statistics or analytics",
        count: "1",
        clean_skill: "Master's Degree",
        skill_group: "Credential"
    },
    {
        skill: "ms/phd",
        count: "1",
        clean_skill: "Master's Degree",
        skill_group: "Credential"
    },
    {
        skill: "bs/ms in computer science",
        count: "1",
        clean_skill: "Master's Degree",
        skill_group: "Credential"
    },
    {
        skill: "masters degree with 5 years of experience in creating machine learning pipelines",
        count: "1",
        clean_skill: "Master's Degree",
        skill_group: "Credential"
    },
    {
        skill: "masters or phd",
        count: "2",
        clean_skill: "Master's Degree",
        skill_group: "Credential"
    },
    {
        skill: "materials",
        count: "1",
        clean_skill: "Materials",
        skill_group: ""
    },
    {
        skill: "mathematica",
        count: "1",
        clean_skill: "Mathematica",
        skill_group: ""
    },
    {
        skill: "quantitative and mathematical proficiency",
        count: "1",
        clean_skill: "Mathematical Proficiency",
        skill_group: ""
    },
    {
        skill: "mathematical",
        count: "16",
        clean_skill: "Mathematics",
        skill_group: "General Skill"
    },
    {
        skill: "mathematical models",
        count: "1",
        clean_skill: "Mathematics",
        skill_group: "General Skill"
    },
    {
        skill: "mathematics",
        count: "2",
        clean_skill: "Mathematics",
        skill_group: "General Skill"
    },
    {
        skill: "(data scientist ) and (data analysis or running data pipelines ) and(machine learning or matlab ) and(python )",
        count: "1",
        clean_skill: "MATLAB",
        skill_group: "Programming Language"
    },
    {
        skill: "matlab",
        count: "10",
        clean_skill: "MATLAB",
        skill_group: "Programming Language"
    },
    {
        skill: "scikit-learn data visualization (matplotlib",
        count: "1",
        clean_skill: "Matplotlib",
        skill_group: ""
    },
    {
        skill: "experience with nlp technologies/data science in a clinical background",
        count: "2",
        clean_skill: "Medical Clinic",
        skill_group: "Domain Expertise"
    },
    {
        skill: "metrics",
        count: "17",
        clean_skill: "Metrics",
        skill_group: ""
    },
    {
        skill: "micro",
        count: "2",
        clean_skill: "Micro",
        skill_group: ""
    },
    {
        skill: "microservices",
        count: "1",
        clean_skill: "Microservices",
        skill_group: ""
    },
    {
        skill: "microsoft",
        count: "1",
        clean_skill: "Microsoft",
        skill_group: ""
    },
    {
        skill: "aws- azure",
        count: "2",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "azure",
        count: "5",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "azure - data science",
        count: "1",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "azure cloud engineer",
        count: "1",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "azure machine learning",
        count: "1",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "azure sql data warehouse",
        count: "1",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "data analyst and capacity and visualization and sql and azure",
        count: "1",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud.",
        count: "1",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud. should have hands on experience configuring and onboarding users including developer",
        count: "1",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "h2o or azure ml",
        count: "1",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "microsoft azure",
        count: "1",
        clean_skill: "Microsoft Azure",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "microstrategy",
        count: "1",
        clean_skill: "Microstrategy",
        skill_group: ""
    },
    {
        skill: "middleware",
        count: "1",
        clean_skill: "Middleware",
        skill_group: ""
    },
    {
        skill: "mixed effects",
        count: "2",
        clean_skill: "Mixed Effects",
        skill_group: ""
    },
    {
        skill: "mllib",
        count: "1",
        clean_skill: "MLlib",
        skill_group: ""
    },
    {
        skill: "modeanalytics",
        count: "1",
        clean_skill: "Mode Analytics",
        skill_group: ""
    },
    {
        skill: "modeler",
        count: "2",
        clean_skill: "Modeler",
        skill_group: "Position"
    },
    {
        skill: "data scientist data pipeline modeling data engineer r sas python spark machine learning",
        count: "1",
        clean_skill: "Modeling",
        skill_group: ""
    },
    {
        skill: "data scientist machine learning statistical modeling database data python r cloud",
        count: "1",
        clean_skill: "Modeling",
        skill_group: ""
    },
    {
        skill: "mongo",
        count: "1",
        clean_skill: "MongoDB",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "mongodb",
        count: "8",
        clean_skill: "MongoDB",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "nosql (dynamodb/cassandra/mongodb)",
        count: "1",
        clean_skill: "MongoDB",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "nosql databases - mongodb-couchbase - cassandra",
        count: "2",
        clean_skill: "MongoDB",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "mysql",
        count: "19",
        clean_skill: "MySQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "naive bayes classifier",
        count: "1",
        clean_skill: "Naive Bayes Classifier",
        skill_group: ""
    },
    {
        skill: "1. python 2. nlp 3. deep learning",
        count: "1",
        clean_skill: "Natural Language Processing",
        skill_group: ""
    },
    {
        skill: "at least 2 to 4 years professional experience in nlp",
        count: "1",
        clean_skill: "Natural Language Processing",
        skill_group: ""
    },
    {
        skill: "experience with nlp technologies/data science in a clinical background",
        count: "2",
        clean_skill: "Natural Language Processing",
        skill_group: ""
    },
    {
        skill: "natural language",
        count: "1",
        clean_skill: "Natural Language Processing",
        skill_group: ""
    },
    {
        skill: "natural language processing",
        count: "6",
        clean_skill: "Natural Language Processing",
        skill_group: ""
    },
    {
        skill: "nlp",
        count: "24",
        clean_skill: "Natural Language Processing",
        skill_group: ""
    },
    {
        skill: "nlp - data science",
        count: "4",
        clean_skill: "Natural Language Processing",
        skill_group: ""
    },
    {
        skill: "nlp and text analytics",
        count: "1",
        clean_skill: "Natural Language Processing",
        skill_group: ""
    },
    {
        skill: "nlp(natural language processing) git r matlab",
        count: "1",
        clean_skill: "Natural Language Processing",
        skill_group: ""
    },
    {
        skill: "nltk",
        count: "1",
        clean_skill: "Natural Language Processing",
        skill_group: ""
    },
    {
        skill: "natural programmer",
        count: "1",
        clean_skill: "Natural Programmer",
        skill_group: ""
    },
    {
        skill: "netezza",
        count: "1",
        clean_skill: "Netezza",
        skill_group: ""
    },
    {
        skill: "network planning",
        count: "2",
        clean_skill: "Network Planning",
        skill_group: ""
    },
    {
        skill: "networking",
        count: "1",
        clean_skill: "Networking",
        skill_group: ""
    },
    {
        skill: "networking architecture",
        count: "1",
        clean_skill: "Networking",
        skill_group: ""
    },
    {
        skill: "data science analytics networks machine learning tensorflow",
        count: "1",
        clean_skill: "Networks",
        skill_group: ""
    },
    {
        skill: "networks",
        count: "17",
        clean_skill: "Networks",
        skill_group: ""
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "Neural Networks",
        skill_group: ""
    },
    {
        skill: "node",
        count: "1",
        clean_skill: "Node.js",
        skill_group: "Programming Language"
    },
    {
        skill: "node.js",
        count: "3",
        clean_skill: "Node.js",
        skill_group: "Programming Language"
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "NoSQL",
        skill_group: "Programming Language"
    },
    {
        skill: "machine learning python sql / nosql databases",
        count: "1",
        clean_skill: "NoSQL",
        skill_group: "Programming Language"
    },
    {
        skill: "nosql",
        count: "16",
        clean_skill: "NoSQL",
        skill_group: "Programming Language"
    },
    {
        skill: "nosql (dynamodb/cassandra/mongodb)",
        count: "1",
        clean_skill: "NoSQL",
        skill_group: "Programming Language"
    },
    {
        skill: "nosql databases - mongodb-couchbase - cassandra",
        count: "2",
        clean_skill: "NoSQL",
        skill_group: "Programming Language"
    },
    {
        skill: "numerical optimization",
        count: "1",
        clean_skill: "Numerical Optimization",
        skill_group: ""
    },
    {
        skill: "numpy",
        count: "10",
        clean_skill: "NumPy",
        skill_group: ""
    },
    {
        skill: "python (including numpy",
        count: "1",
        clean_skill: "Numpy",
        skill_group: ""
    },
    {
        skill: "oop",
        count: "2",
        clean_skill: "Object Oriented Programming",
        skill_group: ""
    },
    {
        skill: "ms office",
        count: "4",
        clean_skill: "Office",
        skill_group: ""
    },
    {
        skill: "olap",
        count: "4",
        clean_skill: "OLAP",
        skill_group: ""
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud. should have hands on experience configuring and onboarding users including developer",
        count: "1",
        clean_skill: "Onboarding",
        skill_group: "Soft Skill"
    },
    {
        skill: "operating",
        count: "1",
        clean_skill: "Operating",
        skill_group: ""
    },
    {
        skill: "c++ - operations research",
        count: "2",
        clean_skill: "Operations",
        skill_group: "Domain Expertise"
    },
    {
        skill: "operations",
        count: "1",
        clean_skill: "Operations",
        skill_group: "Domain Expertise"
    },
    {
        skill: "operations - r",
        count: "1",
        clean_skill: "Operations",
        skill_group: "Domain Expertise"
    },
    {
        skill: "operations analysis",
        count: "1",
        clean_skill: "Operations",
        skill_group: "Domain Expertise"
    },
    {
        skill: "operations research",
        count: "3",
        clean_skill: "Operations",
        skill_group: "Domain Expertise"
    },
    {
        skill: "optimization",
        count: "1",
        clean_skill: "Optimization",
        skill_group: ""
    },
    {
        skill: "oracle",
        count: "17",
        clean_skill: "Oracle",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "oracle marketing suite",
        count: "1",
        clean_skill: "Oracle",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "oracle marketing suite - marketing technologies",
        count: "1",
        clean_skill: "Oracle",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "organization transformation",
        count: "1",
        clean_skill: "Organization Transformation",
        skill_group: "Soft Skill"
    },
    {
        skill: "(toolkits like pandas",
        count: "1",
        clean_skill: "Pandas",
        skill_group: ""
    },
    {
        skill: "pandas",
        count: "12",
        clean_skill: "Pandas",
        skill_group: ""
    },
    {
        skill: "scipy & pandas)",
        count: "1",
        clean_skill: "Pandas",
        skill_group: ""
    },
    {
        skill: "performance",
        count: "1",
        clean_skill: "Performance",
        skill_group: ""
    },
    {
        skill: "perl",
        count: "11",
        clean_skill: "Perl",
        skill_group: "Programming Language"
    },
    {
        skill: "masters or phd",
        count: "2",
        clean_skill: "PhD",
        skill_group: "Credential"
    },
    {
        skill: "ms/phd",
        count: "1",
        clean_skill: "PhD",
        skill_group: "Credentials"
    },
    {
        skill: "phd",
        count: "7",
        clean_skill: "PhD",
        skill_group: "Credentials"
    },
    {
        skill: "phd required",
        count: "1",
        clean_skill: "PhD",
        skill_group: "Credentials"
    },
    {
        skill: "senior data scientist with phd",
        count: "1",
        clean_skill: "PhD",
        skill_group: "Credential"
    },
    {
        skill: "php",
        count: "2",
        clean_skill: "PHP",
        skill_group: "Programming Language"
    },
    {
        skill: "computational physics",
        count: "1",
        clean_skill: "Physics",
        skill_group: "Domain Expertise"
    },
    {
        skill: "computational physics - data science",
        count: "1",
        clean_skill: "Physics",
        skill_group: "Database Technology"
    },
    {
        skill: "physics",
        count: "15",
        clean_skill: "Physics",
        skill_group: "Domain Expertise"
    },
    {
        skill: "hadoop - hive - pig",
        count: "2",
        clean_skill: "Pig",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "pig",
        count: "4",
        clean_skill: "Pig",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "pig - python",
        count: "1",
        clean_skill: "Pig",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud.",
        count: "1",
        clean_skill: "Pivotal Cloud Foundry",
        skill_group: ""
    },
    {
        skill: "expertise/experience working on cloud platforms - pivotal cloud foundry and public cloud aws/azure/google cloud. should have hands on experience configuring and onboarding users including developer",
        count: "1",
        clean_skill: "Pivotal Cloud Foundry",
        skill_group: ""
    },
    {
        skill: "infrastructure/platform design",
        count: "1",
        clean_skill: "Platform",
        skill_group: ""
    },
    {
        skill: "postgres",
        count: "1",
        clean_skill: "PostgreSQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "postgres) kafka",
        count: "1",
        clean_skill: "PostgreSQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "postgresql",
        count: "11",
        clean_skill: "PostgreSQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "business analyst data scientist r python jira github tableau powerbi statistics",
        count: "1",
        clean_skill: "Power BI",
        skill_group: ""
    },
    {
        skill: "microsoft powerbi",
        count: "1",
        clean_skill: "PowerBI",
        skill_group: ""
    },
    {
        skill: "power bi",
        count: "1",
        clean_skill: "PowerBI",
        skill_group: ""
    },
    {
        skill: "powerpoint",
        count: "1",
        clean_skill: "PowerPoint",
        skill_group: ""
    },
    {
        skill: "pre-sales engineering",
        count: "2",
        clean_skill: "Pre-Sales Engineering",
        skill_group: ""
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "Predictive Analytics/Models",
        skill_group: "General Skill"
    },
    {
        skill: "predictive analytics",
        count: "8",
        clean_skill: "Predictive Analytics/Models",
        skill_group: "General Skill"
    },
    {
        skill: "predictive model",
        count: "3",
        clean_skill: "Predictive Analytics/Models",
        skill_group: "General Skill"
    },
    {
        skill: "predictive modeling",
        count: "6",
        clean_skill: "Predictive Analytics/Models",
        skill_group: "General Skill"
    },
    {
        skill: "predictive modelling",
        count: "1",
        clean_skill: "Predictive Analytics/Models",
        skill_group: "General Skill"
    },
    {
        skill: "predictive-analytics",
        count: "1",
        clean_skill: "Predictive Analytics/Models",
        skill_group: "General Skill"
    },
    {
        skill: "simulation development and/or predictive analytics",
        count: "1",
        clean_skill: "Predictive Analytics/Models",
        skill_group: "General Skill"
    },
    {
        skill: "building predictive models",
        count: "1",
        clean_skill: "Predictive Analytics/Models",
        skill_group: "General Skill"
    },
    {
        skill: "building predictive models - r",
        count: "1",
        clean_skill: "Predictive Analytics/Models",
        skill_group: "General Skill"
    },
    {
        skill: "presentation skills",
        count: "2",
        clean_skill: "Presentation Skills",
        skill_group: "Soft Skill"
    },
    {
        skill: "presto",
        count: "1",
        clean_skill: "Presto",
        skill_group: ""
    },
    {
        skill: "presto - kafka",
        count: "1",
        clean_skill: "Presto",
        skill_group: ""
    },
    {
        skill: "principal data scientist skills: analytics - statistics - machine learning - ml - data models - big data - pricing revenue - retail - cloud - price optimization - r - python",
        count: "1",
        clean_skill: "Price Optimization",
        skill_group: ""
    },
    {
        skill: "pricing",
        count: "2",
        clean_skill: "Pricing",
        skill_group: ""
    },
    {
        skill: "quantitative business problem solving",
        count: "1",
        clean_skill: "Problem Solving",
        skill_group: "General Skill"
    },
    {
        skill: "process improvement",
        count: "2",
        clean_skill: "Process Improvement",
        skill_group: "Soft Skill"
    },
    {
        skill: "process reengineering",
        count: "1",
        clean_skill: "Process Reengineering",
        skill_group: ""
    },
    {
        skill: "sales or product development function) within a data driven organization",
        count: "1",
        clean_skill: "Product Development",
        skill_group: ""
    },
    {
        skill: "programming",
        count: "46",
        clean_skill: "Programming",
        skill_group: ""
    },
    {
        skill: "object oriented programming",
        count: "2",
        clean_skill: "Programming Languages",
        skill_group: ""
    },
    {
        skill: "cloud infrastructure and threat intelligenceexperience with data visualization and programming languages",
        count: "1",
        clean_skill: "Programming Languages",
        skill_group: ""
    },
    {
        skill: "project",
        count: "27",
        clean_skill: "Project Management",
        skill_group: "Soft Skill"
    },
    {
        skill: "project management",
        count: "2",
        clean_skill: "Project Management",
        skill_group: "Soft Skill"
    },
    {
        skill: "protocols",
        count: "1",
        clean_skill: "Protocols",
        skill_group: ""
    },
    {
        skill: "puppet",
        count: "1",
        clean_skill: "Puppet",
        skill_group: ""
    },
    {
        skill: "pydata",
        count: "1",
        clean_skill: "Pydata",
        skill_group: ""
    },
    {
        skill: "pyspark",
        count: "6",
        clean_skill: "Pyspark",
        skill_group: ""
    },
    {
        skill: "pyspark hadoop",
        count: "1",
        clean_skill: "Pyspark",
        skill_group: ""
    },
    {
        skill: "pythin",
        count: "1",
        clean_skill: "Pythin",
        skill_group: ""
    },
    {
        skill: "(data scientist ) and (data analysis or running data pipelines ) and(machine learning or matlab ) and(python )",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "1. python 2. nlp 3. deep learning",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "3+ years experience doing data science with r and python in a professional",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "anaconda - python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "apache spark - python",
        count: "2",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "business analyst data scientist r python jira github tableau powerbi statistics",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "crm - python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "data science - python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "data science and implementations in python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "data science python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "data scientist data pipeline modeling data engineer r sas python spark machine learning",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "data scientist machine learning statistical modeling database data python r cloud",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "data scientist machine learning statistics r python c# java scala",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "deep learning and machine learning and research and statistical models and (python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "image analytics - python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "including python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "machine learning - python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "machine learning (aml)python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "machine learning python sql / nosql databases",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "modeling - python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "must have skills: data science python sas",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "or python and experience with languages/ frameworks used in ml & analytics: spark",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "pig - python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "principal data scientist skills: analytics - statistics - machine learning - ml - data models - big data - pricing revenue - retail - cloud - price optimization - r - python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python",
        count: "254",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python - aws",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python - data science",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python - machine learning",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python (including numpy",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python and (",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python or java",
        count: "2",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python or r",
        count: "2",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python r scala sql",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python software developer background; ml; data science; system programming; webservices",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python tools",
        count: "2",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python.",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python/java/c#",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python/r",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "python/r/sas",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "pyton",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "r and/or python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "r required; python & spark | data mining | data mining expertise: sql required; machine learning | hadoop framework",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "r/python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "r/python programming",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "rdbms - python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "sas or pytho",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "sas or python",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "wps or spss or python prefer: sql server",
        count: "1",
        clean_skill: "Python",
        skill_group: "Programming Language"
    },
    {
        skill: "pytorch",
        count: "1",
        clean_skill: "PyTorch",
        skill_group: "Programming Language"
    },
    {
        skill: "qlikview",
        count: "1",
        clean_skill: "Qlikview",
        skill_group: "Visualization"
    },
    {
        skill: "qa",
        count: "1",
        clean_skill: "Quality Assurance",
        skill_group: "Soft Skill"
    },
    {
        skill: "quality",
        count: "1",
        clean_skill: "Quality Assurance",
        skill_group: "Soft Skill"
    },
    {
        skill: "quality assurance",
        count: "1",
        clean_skill: "Quality Assurance",
        skill_group: "Soft Skill"
    },
    {
        skill: "quantitative analyst",
        count: "1",
        clean_skill: "Quantitative Analysis/Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "quantitative modeling",
        count: "2",
        clean_skill: "Quantitative Analysis/Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "quantitative researcher-equities",
        count: "1",
        clean_skill: "Quantitative Analysis/Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "3+ years experience doing data science with r and python in a professional",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "ai - r",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "biostatistics - r",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "building predictive models - r",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "business analyst data scientist r python jira github tableau powerbi statistics",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "data architect - r",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "data scientist data pipeline modeling data engineer r sas python spark machine learning",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "data scientist machine learning statistical modeling database data python r cloud",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "data scientist machine learning statistics r python c# java scala",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "experience using statistical computer languages (r",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "operations - r",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "principal data scientist skills: analytics - statistics - machine learning - ml - data models - big data - pricing revenue - retail - cloud - price optimization - r - python",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "programming in r",
        count: "2",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "python or r",
        count: "2",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "python r scala sql",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "python/r",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "python/r/sas",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "r",
        count: "93",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "r and/or python",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "r etc.",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "r programming",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "r required; python & spark | data mining | data mining expertise: sql required; machine learning | hadoop framework",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "r/python",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "r/python programming",
        count: "1",
        clean_skill: "R",
        skill_group: "Programming Language"
    },
    {
        skill: "r studio",
        count: "1",
        clean_skill: "R Studio",
        skill_group: "Programming Tool"
    },
    {
        skill: "rabbitmq",
        count: "1",
        clean_skill: "RabbitMQ",
        skill_group: ""
    },
    {
        skill: "(machine learning ) and (artificial intelligence ) and (rafa )",
        count: "1",
        clean_skill: "Rafa",
        skill_group: ""
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "Random Forest",
        skill_group: ""
    },
    {
        skill: "rapidminer",
        count: "1",
        clean_skill: "RapidMiner",
        skill_group: "Programming Tool"
    },
    {
        skill: "or recommender systems",
        count: "1",
        clean_skill: "Recommender Systems",
        skill_group: ""
    },
    {
        skill: "recommendation",
        count: "1",
        clean_skill: "Recommender Systems",
        skill_group: ""
    },
    {
        skill: "recommender systems",
        count: "2",
        clean_skill: "Recommender Systems",
        skill_group: ""
    },
    {
        skill: "recruiter",
        count: "4",
        clean_skill: "Recruiter",
        skill_group: "Position"
    },
    {
        skill: "rnn",
        count: "1",
        clean_skill: "Recurrent Neural Network",
        skill_group: ""
    },
    {
        skill: "redshift",
        count: "7",
        clean_skill: "Redshift",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "redshift - data scientist",
        count: "1",
        clean_skill: "Redshift",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "redshift is a plus.",
        count: "1",
        clean_skill: "Redshift",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "redshift; s3",
        count: "1",
        clean_skill: "Redshift",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "reengineering",
        count: "2",
        clean_skill: "Reengineering",
        skill_group: ""
    },
    {
        skill: "regression",
        count: "2",
        clean_skill: "Regression",
        skill_group: "General Skill"
    },
    {
        skill: "regression/logistic regression",
        count: "2",
        clean_skill: "Regression",
        skill_group: "General Skill"
    },
    {
        skill: "regression/logistic regression - sas",
        count: "2",
        clean_skill: "Regression",
        skill_group: "General Skill"
    },
    {
        skill: "regressions",
        count: "4",
        clean_skill: "Regression",
        skill_group: "General Skill"
    },
    {
        skill: "rdbms",
        count: "1",
        clean_skill: "Relational Database Management Systems",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "rdbms - python",
        count: "1",
        clean_skill: "Relational Database Management Systems",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "rdms",
        count: "1",
        clean_skill: "Relational Database Management Systems",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "rds",
        count: "2",
        clean_skill: "Relational Database Management Systems",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "data visualizations & reporting",
        count: "2",
        clean_skill: "Reporting",
        skill_group: ""
    },
    {
        skill: "data warehouse reporting",
        count: "1",
        clean_skill: "Reporting",
        skill_group: ""
    },
    {
        skill: "report",
        count: "1",
        clean_skill: "Reporting",
        skill_group: ""
    },
    {
        skill: "reporting",
        count: "1",
        clean_skill: "Reporting",
        skill_group: ""
    },
    {
        skill: "reporting service (ssrs)",
        count: "1",
        clean_skill: "Reporting",
        skill_group: ""
    },
    {
        skill: "reporting&etl",
        count: "1",
        clean_skill: "Reporting",
        skill_group: ""
    },
    {
        skill: "deep learning and machine learning and research and statistical models and (python",
        count: "1",
        clean_skill: "Research",
        skill_group: "General Skill"
    },
    {
        skill: "research",
        count: "54",
        clean_skill: "Research",
        skill_group: "General Skill"
    },
    {
        skill: "rest",
        count: "4",
        clean_skill: "REST",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "restful apis",
        count: "2",
        clean_skill: "REST",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "and behavioral modeling and simulation for bfs/retail",
        count: "1",
        clean_skill: "Retail",
        skill_group: "Domain Expertise"
    },
    {
        skill: "risk management",
        count: "4",
        clean_skill: "Risk Management",
        skill_group: "Domain Knowledge"
    },
    {
        skill: "robotics",
        count: "1",
        clean_skill: "Robotics",
        skill_group: "Domain Knowledge"
    },
    {
        skill: "ruby",
        count: "5",
        clean_skill: "Ruby",
        skill_group: "Programming Language"
    },
    {
        skill: "ruby on rails",
        count: "1",
        clean_skill: "Ruby",
        skill_group: "Programming Language"
    },
    {
        skill: "ruby on rails / ror / ruby / rails",
        count: "1",
        clean_skill: "Ruby",
        skill_group: "Programming Language"
    },
    {
        skill: "soap - ruby on rails",
        count: "1",
        clean_skill: "Ruby",
        skill_group: "Programming Language"
    },
    {
        skill: "soap - ruby on rails / ror / ruby / rails",
        count: "1",
        clean_skill: "Ruby",
        skill_group: "Programming Language"
    },
    {
        skill: "soap - ruby on rails / ror / ruby / rails",
        count: "1",
        clean_skill: "Ruby",
        skill_group: "Programming Language"
    },
    {
        skill: "redshift; s3",
        count: "1",
        clean_skill: "S3",
        skill_group: ""
    },
    {
        skill: "s3",
        count: "2",
        clean_skill: "S3",
        skill_group: ""
    },
    {
        skill: "saas",
        count: "1",
        clean_skill: "SaaS",
        skill_group: ""
    },
    {
        skill: "sagemaker",
        count: "1",
        clean_skill: "SageMaker",
        skill_group: ""
    },
    {
        skill: "sales",
        count: "8",
        clean_skill: "Sales",
        skill_group: ""
    },
    {
        skill: "salesforce marketing suite",
        count: "2",
        clean_skill: "Salesforce",
        skill_group: ""
    },
    {
        skill: "sap",
        count: "3",
        clean_skill: "SAP",
        skill_group: ""
    },
    {
        skill: "sap isu",
        count: "1",
        clean_skill: "SAP",
        skill_group: ""
    },
    {
        skill: "data scientist data pipeline modeling data engineer r sas python spark machine learning",
        count: "1",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "ibm spss and sas tools",
        count: "1",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "must have skills: data science python sas",
        count: "1",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "python/r/sas",
        count: "1",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "regression/logistic regression - sas",
        count: "2",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "sas",
        count: "47",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "sas enterprise miner",
        count: "2",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "sas or pytho",
        count: "1",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "sas or python",
        count: "1",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "sas programming",
        count: "2",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "sas/stat",
        count: "2",
        clean_skill: "SAS",
        skill_group: "Programming Language"
    },
    {
        skill: "and scala",
        count: "1",
        clean_skill: "Scala",
        skill_group: "Programming Language"
    },
    {
        skill: "data scientist machine learning statistics r python c# java scala",
        count: "1",
        clean_skill: "Scala",
        skill_group: "Programming Language"
    },
    {
        skill: "python r scala sql",
        count: "1",
        clean_skill: "Scala",
        skill_group: "Programming Language"
    },
    {
        skill: "scala",
        count: "17",
        clean_skill: "Scala",
        skill_group: "Programming Language"
    },
    {
        skill: "scala or c++)",
        count: "1",
        clean_skill: "Scala",
        skill_group: "Programming Language"
    },
    {
        skill: "scala or javaexperience driving research and early stage projects to production implementationcreative approach to problem solving and using leading edge tools to build innovative solutionsteam player with strong desire to learn",
        count: "1",
        clean_skill: "Scala",
        skill_group: "Programming Language"
    },
    {
        skill: "scalanltk",
        count: "1",
        clean_skill: "Scala",
        skill_group: "Programming Language"
    },
    {
        skill: "scheduling",
        count: "1",
        clean_skill: "Scheduling",
        skill_group: ""
    },
    {
        skill: "scientific",
        count: "10",
        clean_skill: "Scientific",
        skill_group: ""
    },
    {
        skill: "scientist",
        count: "2",
        clean_skill: "Scientist",
        skill_group: "Position"
    },
    {
        skill: "scikit",
        count: "3",
        clean_skill: "Scikit-Learn",
        skill_group: ""
    },
    {
        skill: "scikit-learn",
        count: "2",
        clean_skill: "Scikit-Learn",
        skill_group: ""
    },
    {
        skill: "scikit-learn data visualization (matplotlib",
        count: "1",
        clean_skill: "Scikit-Learn",
        skill_group: ""
    },
    {
        skill: "scikit.learn",
        count: "2",
        clean_skill: "Scikit-Learn",
        skill_group: ""
    },
    {
        skill: "sklearn",
        count: "1",
        clean_skill: "Scikit-Learn",
        skill_group: ""
    },
    {
        skill: "scipy",
        count: "6",
        clean_skill: "Scipy",
        skill_group: ""
    },
    {
        skill: "scipy & pandas)",
        count: "1",
        clean_skill: "Scipy",
        skill_group: ""
    },
    {
        skill: "scripts",
        count: "11",
        clean_skill: "Scripts",
        skill_group: ""
    },
    {
        skill: "scrum",
        count: "6",
        clean_skill: "Scrum",
        skill_group: ""
    },
    {
        skill: "seaborn",
        count: "1",
        clean_skill: "Seaborn",
        skill_group: ""
    },
    {
        skill: "machine learning and supervised machine learning to cybersecurity or fraud detectionworking knowledge of security detection tools",
        count: "1",
        clean_skill: "Security",
        skill_group: ""
    },
    {
        skill: "network/ security",
        count: "1",
        clean_skill: "Security",
        skill_group: ""
    },
    {
        skill: "security",
        count: "27",
        clean_skill: "Security",
        skill_group: ""
    },
    {
        skill: "share knowledge and build a world class security platform",
        count: "1",
        clean_skill: "Security",
        skill_group: ""
    },
    {
        skill: "test ng/selenium",
        count: "1",
        clean_skill: "Selenium",
        skill_group: ""
    },
    {
        skill: "senior data scientist with phd",
        count: "1",
        clean_skill: "Senior Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "senior data scientist",
        count: "1",
        clean_skill: "Senior Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "sr data scientist",
        count: "1",
        clean_skill: "Senior Data Scientist",
        skill_group: "Position"
    },
    {
        skill: "senior manager",
        count: "1",
        clean_skill: "Senior Manager",
        skill_group: "Position"
    },
    {
        skill: "shell scripting",
        count: "5",
        clean_skill: "Shell Scripting",
        skill_group: ""
    },
    {
        skill: "and behavioral modeling and simulation for bfs/retail",
        count: "1",
        clean_skill: "Simulation",
        skill_group: "General Skill"
    },
    {
        skill: "simulation",
        count: "8",
        clean_skill: "Simulation",
        skill_group: "General Skill"
    },
    {
        skill: "simulation development and/or predictive analytics",
        count: "1",
        clean_skill: "Simulation",
        skill_group: "General Skill"
    },
    {
        skill: "stochastic simulation",
        count: "1",
        clean_skill: "Simulation",
        skill_group: "General Skill"
    },
    {
        skill: "small business",
        count: "1",
        clean_skill: "Small Business",
        skill_group: "Domain Expertise?"
    },
    {
        skill: "snowflake",
        count: "1",
        clean_skill: "Snowflake",
        skill_group: ""
    },
    {
        skill: "soap",
        count: "3",
        clean_skill: "SOAP",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "soap - ruby on rails",
        count: "1",
        clean_skill: "SOAP",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "programmer",
        count: "1",
        clean_skill: "Software Developer",
        skill_group: "Position"
    },
    {
        skill: "python software developer background; ml; data science; system programming; webservices",
        count: "1",
        clean_skill: "Software Developer",
        skill_group: "Position"
    },
    {
        skill: "software",
        count: "2",
        clean_skill: "Software Development",
        skill_group: ""
    },
    {
        skill: "software development",
        count: "1",
        clean_skill: "Software Development",
        skill_group: ""
    },
    {
        skill: "sdlc",
        count: "1",
        clean_skill: "Software Development Life Cycle",
        skill_group: ""
    },
    {
        skill: "software engineer",
        count: "7",
        clean_skill: "Software Engineer",
        skill_group: "Position"
    },
    {
        skill: "software engineer - data engineer - sql",
        count: "1",
        clean_skill: "Software Engineer",
        skill_group: "Position"
    },
    {
        skill: "solr",
        count: "4",
        clean_skill: "Solr",
        skill_group: ""
    },
    {
        skill: "solutions implementation",
        count: "1",
        clean_skill: "Solutions Implementation",
        skill_group: "Soft Skill"
    },
    {
        skill: "sosql",
        count: "1",
        clean_skill: "SOSQL",
        skill_group: ""
    },
    {
        skill: "spacy",
        count: "2",
        clean_skill: "spaCy",
        skill_group: ""
    },
    {
        skill: "apache spark",
        count: "5",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "apache spark - python",
        count: "2",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "apache spark or kubernetes or hortonworks or hadoop",
        count: "1",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "data scientist data pipeline modeling data engineer r sas python spark machine learning",
        count: "1",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "golang - spark",
        count: "1",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "machine learning tools like spark mlib",
        count: "1",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "or python and experience with languages/ frameworks used in ml & analytics: spark",
        count: "1",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "r required; python & spark | data mining | data mining expertise: sql required; machine learning | hadoop framework",
        count: "1",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "spark",
        count: "37",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "spark - big data",
        count: "1",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "spark - machine learning",
        count: "1",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "spark or alteryx",
        count: "1",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "sparks",
        count: "1",
        clean_skill: "Spark",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "spend analysis",
        count: "1",
        clean_skill: "Spend Analysis",
        skill_group: ""
    },
    {
        skill: "splunk",
        count: "1",
        clean_skill: "Splunk",
        skill_group: ""
    },
    {
        skill: "ibm spss and sas tools",
        count: "1",
        clean_skill: "SPSS",
        skill_group: "Programming Language"
    },
    {
        skill: "spss",
        count: "5",
        clean_skill: "SPSS",
        skill_group: "Programming Language"
    },
    {
        skill: "spss or even r",
        count: "1",
        clean_skill: "SPSS",
        skill_group: "Programming Language"
    },
    {
        skill: "ssps modeler experience",
        count: "2",
        clean_skill: "SPSS",
        skill_group: "Programming Language"
    },
    {
        skill: "wps or spss or python prefer: sql server",
        count: "1",
        clean_skill: "SPSS",
        skill_group: "Programming Language"
    },
    {
        skill: "azure sql data warehouse",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "business analyst and data and sql",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "data analyst and capacity and visualization and sql and azure",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "database concepts and sql",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "etl; data; spark; hive; extract; transform; load; sql; aws; design; data sets",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "experimental design - sql",
        count: "2",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "ibm db2 and sql server",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "machine learning ; predictive analytics; neural network ; decision tree ; random forest ; linear regression ; python or r or sas; datarobot; sql; nosql",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "machine learning python sql / nosql databases",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "ms sql",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "pl/sql",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "python r scala sql",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "r required; python & spark | data mining | data mining expertise: sql required; machine learning | hadoop framework",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "slq",
        count: "3",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "software engineer - data engineer - sql",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "sql",
        count: "124",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "sql - full stack",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "sql (oracle",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "sql and data visualization software",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "sql and relational databases",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "sql data engineer",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "sql python etl",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "sql server",
        count: "16",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "sql server; unix; x12; epic; cigo; caboodle",
        count: "1",
        clean_skill: "SQL",
        skill_group: "Data Acquistion/Management"
    },
    {
        skill: "data analysis - sql server",
        count: "1",
        clean_skill: "SQL Server",
        skill_group: "Database Technology"
    },
    {
        skill: "wps or spss or python prefer: sql server",
        count: "1",
        clean_skill: "SQL Server",
        skill_group: ""
    },
    {
        skill: "integration service (ssis)",
        count: "1",
        clean_skill: "SQL Server",
        skill_group: ""
    },
    {
        skill: "sgml",
        count: "1",
        clean_skill: "Standard Generalized Markup Language",
        skill_group: ""
    },
    {
        skill: "corenlp",
        count: "1",
        clean_skill: "Stanford CoreNLP",
        skill_group: ""
    },
    {
        skill: "stanford",
        count: "1",
        clean_skill: "Stanford CoreNLP",
        skill_group: ""
    },
    {
        skill: "stanford corenlp",
        count: "1",
        clean_skill: "Stanford CoreNLP",
        skill_group: ""
    },
    {
        skill: "stata",
        count: "2",
        clean_skill: "Stata",
        skill_group: ""
    },
    {
        skill: "knowledge of a variety of machine learning and statistical modeling techniques in a business setting",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning and statistical modeling techniques",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "machine learning and tensorflow and statistical model",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "business analyst data scientist r python jira github tableau powerbi statistics",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "data science; statistics",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "data scientist machine learning statistical modeling database data python r cloud",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "data scientist machine learning statistics r python c# java scala",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "deep learning and machine learning and research and statistical models and (python",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "o advanced applied statistics skills",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "principal data scientist skills: analytics - statistics - machine learning - ml - data models - big data - pricing revenue - retail - cloud - price optimization - r - python",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical analysis",
        count: "24",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical analysis and data driven insights",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical analysis/modeling",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical computer language",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical diagnostics of models",
        count: "4",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical modelers",
        count: "2",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical modeling",
        count: "5",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical regression",
        count: "4",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical testing",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical/machine learning algorithms",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistical/machine learning algorithms - machine learning",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistics",
        count: "26",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistics or data mining disciplines5+ years experience as a data scientist (or equivalent role) applying statistical models",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistics or data modeling",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "statistics/data modeling",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "stats",
        count: "1",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "validation",
        count: "7",
        clean_skill: "Statistics/Statistical Modeling",
        skill_group: "General Skill"
    },
    {
        skill: "sprocs",
        count: "1",
        clean_skill: "Stored Procedure",
        skill_group: ""
    },
    {
        skill: "and/or business strategy developm",
        count: "1",
        clean_skill: "Strategy",
        skill_group: "Soft Skill"
    },
    {
        skill: "strategic planning",
        count: "1",
        clean_skill: "Strategy",
        skill_group: "Soft Skill"
    },
    {
        skill: "strategy",
        count: "1",
        clean_skill: "Strategy",
        skill_group: "Soft Skill"
    },
    {
        skill: "structured",
        count: "2",
        clean_skill: "Structured Data",
        skill_group: ""
    },
    {
        skill: "structured and unstructured data",
        count: "1",
        clean_skill: "Structured Data",
        skill_group: ""
    },
    {
        skill: "supervision",
        count: "4",
        clean_skill: "Supervision",
        skill_group: ""
    },
    {
        skill: "supplier campaign tracking",
        count: "1",
        clean_skill: "Supplier Campaign Tracking",
        skill_group: ""
    },
    {
        skill: "supply chain",
        count: "5",
        clean_skill: "Supply Chain",
        skill_group: ""
    },
    {
        skill: "supply chain forecasting",
        count: "1",
        clean_skill: "Supply Chain",
        skill_group: ""
    },
    {
        skill: "supply chain management",
        count: "2",
        clean_skill: "Supply Chain",
        skill_group: ""
    },
    {
        skill: "svm",
        count: "1",
        clean_skill: "Support Vector Machines",
        skill_group: ""
    },
    {
        skill: "sybase",
        count: "1",
        clean_skill: "Sybase",
        skill_group: ""
    },
    {
        skill: "python software developer background; ml; data science; system programming; webservices",
        count: "1",
        clean_skill: "System Programming",
        skill_group: ""
    },
    {
        skill: "systems",
        count: "1",
        clean_skill: "Systems",
        skill_group: ""
    },
    {
        skill: "t-sql",
        count: "1",
        clean_skill: "T-SQL",
        skill_group: ""
    },
    {
        skill: "business analyst data scientist r python jira github tableau powerbi statistics",
        count: "1",
        clean_skill: "Tableau",
        skill_group: "Visualization"
    },
    {
        skill: "etc) and analysis tools ( tableau",
        count: "1",
        clean_skill: "Tableau",
        skill_group: "Visualization"
    },
    {
        skill: "sql tableau marketing analysis",
        count: "1",
        clean_skill: "Tableau",
        skill_group: "Visualization"
    },
    {
        skill: "tableau",
        count: "16",
        clean_skill: "Tableau",
        skill_group: "Visualization"
    },
    {
        skill: "tableau - python",
        count: "2",
        clean_skill: "Tableau",
        skill_group: "Visualization"
    },
    {
        skill: "tableau/ raw /chart.js/data visualization",
        count: "1",
        clean_skill: "Tableau",
        skill_group: "Visualization"
    },
    {
        skill: "tableu",
        count: "1",
        clean_skill: "Tableau",
        skill_group: "Visualization"
    },
    {
        skill: "(digital tag over 0.2 year(s) or tag management over 0.2 year(s) or tagging over 0.2 year(s) or tealium over 0.2 year(s) or piwik pro over 0.2 year(s) or pixel over 0.2 year(s) or qubit over 0.2 year(s) or opentag over 0.2 year(s) or open tag over 0.2 year(s) or ensighten over 0.2 year(s) or google tag over 0.2 year(s) or dynamic tag management over 0.2 year(s) or tag commander over 0.2 year(s) or mezzobit over 0.2 year(s) or supertag over 0.2 year(s) or super tag over 0.2 year(s) or tag defender over 0.2 year(s) or ubertags over 0.2 year(s) or ddx over 0.2 year(s) ) and(analytics over 0.2 year(s) or data scientist over 0.2 year(s) or informatics over 0.2 year(s) ) and(javascript over 0.2 year(s) or java script over 0.2 year(s) or html over 0.2 year(s) )",
        count: "1",
        clean_skill: "Tagging",
        skill_group: ""
    },
    {
        skill: "prior teaching experience (not essential)",
        count: "1",
        clean_skill: "Teaching",
        skill_group: "Soft Skill"
    },
    {
        skill: "prior teaching experience (not essential) - data science",
        count: "1",
        clean_skill: "Teaching",
        skill_group: "Soft Skill"
    },
    {
        skill: "teaching technical concepts",
        count: "1",
        clean_skill: "Teaching",
        skill_group: "Soft Skill"
    },
    {
        skill: "teaching technical concepts - data science",
        count: "1",
        clean_skill: "Teaching",
        skill_group: "Soft Skill"
    },
    {
        skill: "telecom",
        count: "1",
        clean_skill: "Telecom",
        skill_group: "Domain Expertise"
    },
    {
        skill: "telecommunications",
        count: "1",
        clean_skill: "Telecom",
        skill_group: "Domain Expertise"
    },
    {
        skill: "machine learning and tensorflow and statistical model",
        count: "1",
        clean_skill: "TensorFlow",
        skill_group: ""
    },
    {
        skill: "data science analytics networks machine learning tensorflow",
        count: "1",
        clean_skill: "TensorFlow",
        skill_group: ""
    },
    {
        skill: "tensor flow",
        count: "1",
        clean_skill: "TensorFlow",
        skill_group: ""
    },
    {
        skill: "tensorflow",
        count: "19",
        clean_skill: "TensorFlow",
        skill_group: ""
    },
    {
        skill: "teradata",
        count: "10",
        clean_skill: "Teradata",
        skill_group: ""
    },
    {
        skill: "terraform",
        count: "1",
        clean_skill: "Terraform",
        skill_group: ""
    },
    {
        skill: "tdd",
        count: "3",
        clean_skill: "Test-Driven Development",
        skill_group: ""
    },
    {
        skill: "team setting (including git & unit testing)",
        count: "1",
        clean_skill: "Testing",
        skill_group: ""
    },
    {
        skill: "test",
        count: "1",
        clean_skill: "Testing",
        skill_group: ""
    },
    {
        skill: "test ng/selenium",
        count: "1",
        clean_skill: "Testing",
        skill_group: ""
    },
    {
        skill: "test plan",
        count: "1",
        clean_skill: "Testing",
        skill_group: ""
    },
    {
        skill: "testing",
        count: "29",
        clean_skill: "Testing",
        skill_group: ""
    },
    {
        skill: "cloud infrastructure and threat intelligenceexperience with data visualization and programming languages",
        count: "1",
        clean_skill: "Threat Intelligence",
        skill_group: ""
    },
    {
        skill: "tibco",
        count: "1",
        clean_skill: "TIBCO",
        skill_group: ""
    },
    {
        skill: "typescript",
        count: "1",
        clean_skill: "TypeScript",
        skill_group: ""
    },
    {
        skill: "underwriting",
        count: "2",
        clean_skill: "Underwriting",
        skill_group: "Domain Expertise"
    },
    {
        skill: "expert level understanding of the umls",
        count: "1",
        clean_skill: "Unified Medical Language System",
        skill_group: "Domain Expertise"
    },
    {
        skill: "structured and unstructured data",
        count: "1",
        clean_skill: "Unstructured Data",
        skill_group: ""
    },
    {
        skill: "ups",
        count: "6",
        clean_skill: "UPS",
        skill_group: ""
    },
    {
        skill: "user interface",
        count: "1",
        clean_skill: "UX/UI",
        skill_group: ""
    },
    {
        skill: "ux",
        count: "1",
        clean_skill: "UX/UI",
        skill_group: ""
    },
    {
        skill: "version control",
        count: "2",
        clean_skill: "Version Control",
        skill_group: ""
    },
    {
        skill: "vertica",
        count: "1",
        clean_skill: "Vertica",
        skill_group: ""
    },
    {
        skill: "vba",
        count: "3",
        clean_skill: "Visual Basic",
        skill_group: "Programming Languauge"
    },
    {
        skill: "visual basic",
        count: "2",
        clean_skill: "Visual Basic",
        skill_group: "Programming Languauge"
    },
    {
        skill: "cloud infrastructure and threat intelligenceexperience with data visualization and programming languages",
        count: "1",
        clean_skill: "Visualization",
        skill_group: "General Skill"
    },
    {
        skill: "data visualization",
        count: "2",
        clean_skill: "Visualization",
        skill_group: "General Skill"
    },
    {
        skill: "data visualizations & reporting",
        count: "2",
        clean_skill: "Visualization",
        skill_group: "General Skill"
    },
    {
        skill: "data analyst and capacity and visualization and sql and azure",
        count: "1",
        clean_skill: "Visualization",
        skill_group: ""
    },
    {
        skill: "visualization",
        count: "5",
        clean_skill: "Visualization",
        skill_group: ""
    },
    {
        skill: "virtual environments",
        count: "2",
        clean_skill: "VM",
        skill_group: ""
    },
    {
        skill: "watson",
        count: "1",
        clean_skill: "Watson",
        skill_group: ""
    },
    {
        skill: "watson data studio",
        count: "1",
        clean_skill: "Watson",
        skill_group: ""
    },
    {
        skill: "watson discovery",
        count: "1",
        clean_skill: "Watson",
        skill_group: ""
    },
    {
        skill: "5+ years of experience in a similar role and experience in web attribution analytics.",
        count: "1",
        clean_skill: "Web Analytics",
        skill_group: ""
    },
    {
        skill: "web developer",
        count: "1",
        clean_skill: "Web Developer",
        skill_group: "Position"
    },
    {
        skill: "python software developer background; ml; data science; system programming; webservices",
        count: "1",
        clean_skill: "Web Services",
        skill_group: ""
    },
    {
        skill: "windows",
        count: "2",
        clean_skill: "Windows",
        skill_group: ""
    },
    {
        skill: "wireless",
        count: "3",
        clean_skill: "Wireless",
        skill_group: ""
    },
    {
        skill: "wps or spss or python prefer: sql server",
        count: "1",
        clean_skill: "WPS",
        skill_group: ""
    },
    {
        skill: "xgboost",
        count: "2",
        clean_skill: "XGBoost",
        skill_group: ""
    },
    {
        skill: "xgboot",
        count: "1",
        clean_skill: "XGBoost",
        skill_group: ""
    },
    {
        skill: "xml",
        count: "2",
        clean_skill: "XML",
        skill_group: ""
    },
    {
        skill: "xslt",
        count: "1",
        clean_skill: "XSLT",
        skill_group: ""
    },
    {
        skill: "yield management",
        count: "2",
        clean_skill: "Yield Management",
        skill_group: ""
    },
    {
        skill: "apache zeppelin",
        count: "1",
        clean_skill: "Zeppelin",
        skill_group: ""
    },
    {
        skill: "apache zeppelin",
        count: "1",
        clean_skill: "iOS",
        skill_group: ""
    },
    {
        skill: "apache zeppelin",
        count: "1",
        clean_skill: "Android",
        skill_group: ""
    },
    {
        skill: "apache zeppelin",
        count: "1",
        clean_skill: "React Js",
        skill_group: ""
    },
    {
        skill: "apache zeppelin",
        count: "1",
        clean_skill: "Vue JS",
        skill_group: ""
    }
]