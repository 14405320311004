import React, {Component} from "react";
import {Container} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {getProfile, getUserById, getUserList, updateUserById} from "../../../helpers/backend_helper";
import { format } from 'date-fns'
import ReactJson from "react-json-view";

class AdminUserList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUserList: [],
            totalPages: 1,
            currentPage: 1,
            userSearchQuery: null,
            selectedUser: null
        };
    }

    componentDidMount() {
        if(!localStorage.getItem('superUser')) {
            console.error('Not Authorized')
            return
        }

        this.getUserList()

    }

    getUserList(pageNumber: number = 1, limit: number = 5, searchQuery: string = null) {
        getUserList({
            params: {
                page: pageNumber,
                limit: limit,
                searchString: searchQuery
            },
            headers: {
                Authorization: JSON.parse(localStorage.getItem('superUser'))
            }
        }).then(
            (result) => {

                const userList = result.users
                const totalPages = result.totalPages
                const currentPage = result.currentPage

                this.setState({
                    currentUserList: userList,
                    totalPages: totalPages,
                    currentPage: Number(currentPage)
                })

                setTimeout(() => {

                }, 4000);
            },
            (error) => {
                console.log(error.text);
            }
        );
    }

    queryUserById(userId: string) {
        getUserById({
            params: {
                userId: userId
            },
            headers: {
                Authorization: JSON.parse(localStorage.getItem('superUser'))
            }
        }).then(
            (result) => {

                const user = result

                this.setState({
                    selectedUser: user
                })

                setTimeout(() => {

                }, 4000);
            },
            (error) => {
                console.log(error.text);
            }
        );
    }

    saveUserUpdates(userId) {
        updateUserById({
            user: this.state.selectedUser,
            userId: userId
        }, {
            headers: {
                Authorization: JSON.parse(localStorage.getItem('superUser'))
            }
        }).then(result => {
            console.log('Saved User Data')
            this.getUserList()
        })
    }

    selectedUserFormElement(user) {
        return <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="myModalLabel">User Information</h5>
                    <button type="button" className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h5>Edit the JSON data below</h5>
                    <ReactJson collapsed={true} src={this.state.selectedUser} onEdit={(edit) => {
                        this.setState({
                            selectedUser: edit.updated_src
                        })
                    }} onAdd={(add) => {
                        this.setState({
                            selectedUser: edit.updated_src
                        })
                    }}
                       onDelete={(onDelete) => {
                           this.setState({
                               selectedUser: edit.updated_src
                           })
                       }}/>
                </div>
                <div class="modal-footer">
                    <button type="button"
                            className="btn btn-secondary waves-effect"
                            data-bs-dismiss="modal">Close
                    </button>
                    <button
                        data-bs-dismiss="modal"
                        onClick={() => {
                            this.saveUserUpdates(user._id)
                        }
                        }
                        type="button"
                            className="btn btn-primary waves-effect waves-light">Save changes
                    </button>
                </div>
            </div>
        </div>
    }

    createPaginationElements() {
        const paginationArray = []
        for(let i = 1; i <= this.state.totalPages; i++){
            paginationArray.push(
                <li className="paginate_button page-item">
                    <button
                        onClick={() => {
                            this.getUserList(i)
                        }}
                        aria-controls="datatable"
                       data-dt-idx={i}
                       tabIndex="0"
                       className="page-link">{i}</button>
            </li>
            )

        }
        return paginationArray;
    }

    render() {
        //meta title
        document.title = "Admin User List";

        return (
            <React.Fragment>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">User List</h4>
                                <p class="card-title-desc"> Manage users in the system, and select users that you'd like to
                                    update or remove properties
                                </p>

                                <div class="row">
                                    <div class="col-6">
                                        <input type="text" className="form-control-sm mt--15 mb--30"
                                               placeholder="Enter some information about a user"
                                               onChange={e => this.setState({userSearchQuery: e.target.value})}
                                               value={this.state.userSearchQuery}
                                        />
                                    </div>
                                    <div class="col-6">
                                        <button
                                            onClick={() => {
                                                if(!this.state.userSearchQuery) {
                                                    return
                                                }

                                                this.getUserList(1, 10, this.state.userSearchQuery)
                                            }}
                                            className="btn btn-primary waves-effect waves-light mt--15 mb--30">
                                            Search</button>
                                    </div>

                                </div>


                                <table id="datatable"
                                       className="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                       role="grid" aria-describedby="datatable_info" style={{height: '857px'}}>
                                    <thead>
                                    <tr role="row">
                                        <th className="sorting_asc" tabIndex="0" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '128px'}} aria-sort="ascending"
                                            aria-label="UserId: activate to sort column descending">User ID
                                        </th>
                                        <th className="sorting_asc" tabIndex="1" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '128px'}} aria-sort="ascending"
                                            aria-label="Name: activate to sort column descending">Name
                                        </th>
                                        <th className="sorting" tabIndex="2" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '198px'}}
                                            aria-label="Email: activate to sort column ascending">Email
                                        </th>
                                        <th className="sorting" tabIndex="3" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '91px'}}
                                            aria-label="Location: activate to sort column ascending">Location
                                        </th>
                                        <th className="sorting" tabIndex="4" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '37px'}}
                                            aria-label="Created At: activate to sort column ascending">Create At
                                        </th>
                                        <th className="sorting" tabIndex="5" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '83px'}}
                                            aria-label="Last Updated: activate to sort column ascending">Last Updated
                                        </th>
                                    </tr>
                                    </thead>


                                    <tbody>

                                    {
                                        this.state.currentUserList.map((user, index) => {
                                            const context = this
                                            return <tr class={index % 2 === 0 ? "even" : "odd"}>
                                                <td>{user._id}</td>
                                                <td style={{textDecoration: 'underline', textDecorationColor: 'blue'}}
                                                    className="sorting_1 dtr-control">
                                                    <div class='row'>
                                                        <div class='col-12'>
                                                            <button
                                                                onClick={() => {
                                                                    this.queryUserById(user._id)
                                                                }
                                                                }
                                                                style={{textAlign: 'left'}} type="button"
                                                                    className="btn btn-link waves-effect waves-light"
                                                                    data-bs-toggle="modal" data-bs-target="#myModal">{user.firstName
                                                                + ' ' + user.lastName}
                                                            </button>

                                                            <div id="myModal" class="modal fade" tabIndex="-1"
                                                                 aria-labelledby="myModalLabel" aria-hidden="true"
                                                                 style={{display: 'none'}}>
                                                                {
                                                                    this.selectedUserFormElement(user)
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{user.email}</td>
                                                <td>{user.location.city + ', ' + user.location.state}</td>
                                                <td>{format(new Date(user.createdAt), 'yyyy-MM-dd')}</td>
                                                <td>{format(new Date(user.updatedAt), 'yyyy-MM-dd')}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>

                                <div class="row">
                                    <div class="col-sm-12 col-md-5">
                                        <div class="dataTables_info" id="datatable_info" role="status"
                                             aria-live="polite">{'Showing ' + this.state.currentPage + ' of '
                                            + this.state.totalPages}
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                                            <ul class="pagination">

                                                {
                                                    this.state.currentPage > 1 && <li className="paginate_button page-item previous"
                                                                                       id="datatable_previous">
                                                        <button onClick={() => {
                                                            let currentPage: number = this.state.currentPage
                                                            this.getUserList(--currentPage)
                                                        }} aria-controls="datatable"
                                                                data-dt-idx="0" tabIndex="0"
                                                                className="page-link">
                                                            Previous
                                                        </button>
                                                    </li>
                                                }

                                                {
                                                    this.createPaginationElements().map((paginationElement) => {
                                                        return paginationElement
                                                    })
                                                }
                                                {
                                                    this.state.currentPage <= (this.state.totalPages - 1) && <li className="paginate_button page-item next" id="datatable_next">
                                                        <button onClick={() => {
                                                            let currentPage: number = this.state.currentPage
                                                            this.getUserList(++currentPage)
                                                        }}
                                                                aria-controls="datatable" data-dt-idx="7" tabIndex="0"
                                                                className="page-link">Next</button>
                                                    </li>
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

AdminUserList.propTypes = {
    t: PropTypes.any
};

export default withTranslation()(AdminUserList);