import { takeEvery, put, call } from "redux-saga/effects";
import uuid from 'react-uuid';

// Login Redux States
import {EDIT_PROFILE, GET_PROFILE, UPLOAD_PROFILE_PICTURE, UPLOAD_RESUME} from "./actionTypes";
import {profileSuccess, profileError, getProfileSuccess, getProfileFailure} from "./actions";

//Include Both Helper File with needed methods
import {
  getProfile, editProfileInfo, uploadProfilePictureData, uploadResumeData
} from "../../../helpers/backend_helper";

function* getProfileData() {
  try {
    const response = yield call(getProfile);
    yield put(getProfileSuccess(response));
  } catch (error) {
    yield put(getProfileFailure(error));
  }
}

function* editProfile({ payload: { user } }) {
  try {
    const response = yield call(editProfileInfo, user);
    yield put(profileSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}

function* uploadProfilePicture({ payload: { file } }) {
  try {
    const formData = new FormData();
    const id = uuid()
    formData.append('file', file, uuid() + '.' + file.name.split('.')[1]);
    formData.append('type', file.type);
    const response = yield call(uploadProfilePictureData, formData);
    yield put(profileSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}

function* uploadResume({ payload: { file } }) {
  try {
    const formData = new FormData();
    const id = uuid()
    formData.append('file', file, uuid() + '.' + file.name.split('.')[1]);
    formData.append('type', file.type);
    const response = yield call(uploadResumeData, formData);
    yield put(profileSuccess(response));
  } catch (error) {
    yield put(profileError(error));
  }
}

function* ProfileSaga() {
  yield takeEvery(GET_PROFILE, getProfileData);
  yield takeEvery(EDIT_PROFILE, editProfile);
  yield takeEvery(UPLOAD_PROFILE_PICTURE, uploadProfilePicture);
  yield takeEvery(UPLOAD_RESUME, uploadResume);
}

export default ProfileSaga;
