import Image from 'next/image';
import {useEffect, useState} from 'react';

const ThemeSwitcher = () => {
    const [darkMode, setDarkMode] = useState(false);

    const toggleTheme = () => {
        setDarkMode((prev) => !prev);

        if (darkMode) {
            window.localStorage.setItem('theme', 'light');
        } else {
            window.localStorage.setItem('theme', 'dark');
        }
    };

    useEffect(() => {
        const body = document.querySelector('body'),
            theme = window.localStorage.getItem('theme');

        if (theme === 'dark') {
            setDarkMode(true);
            body.classList.add('active-dark-mode');
            body.classList.remove('active-light-mode');
        } else {
            setDarkMode(false);
            body.classList.add('active-light-mode');
            body.classList.remove('active-dark-mode');
        }
    }, [darkMode]);

    return (
        <div></div>
    );
};

export default ThemeSwitcher;
