import React, {Component, useState} from "react";
import PropTypes from "prop-types";
import { Alert, Card, CardBody, Col, Container, Row, Label } from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

// action
import {
  apiError, loginUser,
  registerUser,
  registerUserFailed, socialLogin,
} from "../../store/actions";

// Redux
import { connect } from "react-redux";
import {Link, withRouter} from "react-router-dom";

// import images
import profileImg from "../../assets/images/profile-img.png";
import logoImg from "../../assets/images/logo.svg";

import {
  useHistory
} from "react-router-dom";

class Register extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.apiError("");
    this.props.registerUserFailed("");
  }

  render() {

    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link legacyBehavior to="/" className="text-dark">
            <i className="bx bx-home h2" />
          </Link>
        </div>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5}>
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col className="col-7">
                        <div className="text-primary p-4">
                          <h5 className="text-primary">Set up your profile</h5>
                          <p>We need some basic info to get you started.</p>
                        </div>
                      </Col>
                      <Col className="col-5 align-self-end">
                        <img src={profileImg} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <div>
                      <Link legacyBehavior to="/">
                        <div className="avatar-md profile-user-wid mb-4">
                          <span className="avatar-title rounded-circle bg-light">
                            <img
                              src={logoImg}
                              alt=""
                              className="rounded-circle"
                              height="34"
                            />
                          </span>
                        </div>
                      </Link>
                    </div>
                    <div className="p-2">
                      { (this.props.registrationError) &&
                          <Alert>
                            {'Registration Error: ' + this.props.registrationError}
                          </Alert>
                      }
                      <Formik
                        enableReinitialize={true}
                        initialValues={{
                          firstName: (this.state && this.state.firstName) || "",
                          lastName: (this.state && this.state.lastName) || "",
                          location: {
                            city: (this.state && this.state.city) || "",
                            state: (this.state && this.state.state) || "",
                            zip: (this.state && this.state.zip) || "",
                          },
                          email: (this.state && this.state.email) || "",
                          password: (this.state && this.state.password) || ""
                        }}
                        validationSchema={Yup.object().shape({
                          firstName: Yup.string().required(
                              "Please Enter Your First Name"
                          ),
                          lastName: Yup.string().required(
                              "Please Enter Your Last Name"
                          ),
                          city: Yup.string().required(
                              "Please Enter Your City"
                          ),
                          state: Yup.string().required(
                              "Please Enter Your State"
                          ),
                          zip: Yup.string().required(
                              "Please Enter Your Zip Code"
                          ),
                          email: Yup.string().required(
                            "Please Enter Your Email"
                          ),
                          password: Yup.string().required(
                            "Please Enter Valid Password"
                          )
                        })}
                        onSubmit={values => {
                          this.props.registerUser({
                            firstName: values.firstName,
                            lastName: values.lastName,
                            location: {
                              city: values.city,
                              state: values.state,
                              zip: values.zip,
                            },
                            email: values.email,
                            password: values.password
                          }, this.props.history);
                        }}
                      >
                        {({ errors, status, touched }) => (
                          <Form className="form-horizontal">
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label for="firstName" className="form-label">
                                    First Name
                                  </Label>
                                  <Field
                                      name="firstName"
                                      type="input"
                                      placeholder='John'
                                      className={
                                          "form-control" +
                                          (errors.firstName && touched.firstName
                                              ? " is-invalid"
                                              : "")
                                      }
                                  />
                                  <ErrorMessage
                                      name="firstName"
                                      component="div"
                                      className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label for="lastName" className="form-label">
                                    Last Name
                                  </Label>
                                  <Field
                                      name="lastName"
                                      type="input"
                                      placeholder='Doe'
                                      className={
                                          "form-control" +
                                          (errors.lastName && touched.lastName
                                              ? " is-invalid"
                                              : "")
                                      }
                                  />
                                  <ErrorMessage
                                      name="lastName"
                                      component="div"
                                      className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <div className="mb-3">
                                  <Label for="city" className="form-label">
                                    City
                                  </Label>
                                  <Field
                                      name="city"
                                      type="input"
                                      placeholder='Miami'
                                      className={
                                          "form-control" +
                                          (errors.city && touched.city
                                              ? " is-invalid"
                                              : "")
                                      }
                                  />
                                  <ErrorMessage
                                      name="city"
                                      component="div"
                                      className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label for="state" className="form-label">
                                    State
                                  </Label>
                                  <Field
                                      name="state"
                                      type="input"
                                      placeholder='CA'
                                      className={
                                          "form-control" +
                                          (errors.state && touched.state
                                              ? " is-invalid"
                                              : "")
                                      }
                                  />
                                  <ErrorMessage
                                      name="state"
                                      component="div"
                                      className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                              <Col>
                                <div className="mb-3">
                                  <Label for="zip" className="form-label">
                                    Zip
                                  </Label>
                                  <Field
                                      name="zip"
                                      type="input"
                                      placeholder='33101'
                                      className={
                                          "form-control" +
                                          (errors.zip && touched.zip
                                              ? " is-invalid"
                                              : "")
                                      }
                                  />
                                  <ErrorMessage
                                      name="zip"
                                      component="div"
                                      className="invalid-feedback"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="mb-3">
                              <Label for="email" className="form-label">
                                Email
                              </Label>
                              <Field
                                name="email"
                                type="email"
                                placeholder='John.Doe@email.com'
                                className={
                                  "form-control" +
                                  (errors.email && touched.email
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>
                            <div className="mb-3">
                              <Label for="password" className="form-label">
                                Password
                              </Label>
                              <Field
                                name="password"
                                autoComplete="true"
                                type="password"
                                placeholder='At least 6 characters'
                                className={
                                  "form-control" +
                                  (errors.password && touched.password
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorMessage
                                name="password"
                                component="div"
                                className="invalid-feedback"
                              />
                            </div>

                            <div className="mt-4 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Register
                              </button>
                            </div>

                            <div className="mt-4 text-center">
                              <p className="mb-0">
                                By registering you agree to the Remotesapps{" "}
                                <Link legacyBehavior to="#" className="text-primary">
                                  Terms of Use
                                </Link>
                              </p>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p>
                    Already have an account ?{" "}
                    <Link legacyBehavior to="/login" className="fw-medium text-primary">
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Register.propTypes = {
  apiError: PropTypes.any,
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.any,
  registrationError: PropTypes.any,
  user: PropTypes.object,
  history: PropTypes.object
};

const mapStateToProps = state => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default withRouter(
    connect(mapStateToProps, {
      registerUser,
      apiError,
      registerUserFailed,
    })(Register)
);
