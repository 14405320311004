import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { ADMIN_LOGIN_USER, ADMIN_LOGOUT_USER } from "./actionTypes"
import { adminLoginSuccess, adminLogoutUserSuccess, adminApiError } from "./actions"

import {
  postAdminLogin,
  postLogin
} from "../../../helpers/backend_helper"


function* adminLoginUser({ payload: { user, history } }) {
  try {
    const response = yield call(postAdminLogin, {
      email: user.email,
      password: user.password,
    })
    localStorage.setItem("superUser", JSON.stringify('Bearer ' + response.token))
    yield put(adminLoginSuccess(response))
    history.push("/adminPanel")
  } catch (error) {
    yield put(adminApiError(error.response.data.error))
  }
}

function* adminLogoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("superUser")

    history.push("/adminLogin")
  } catch (error) {
    yield put(adminApiError(error))
  }
}

function* adminAuthSaga() {
  yield takeEvery(ADMIN_LOGIN_USER, adminLoginUser)
  yield takeEvery(ADMIN_LOGOUT_USER, adminLogoutUser)
}

export default adminAuthSaga
