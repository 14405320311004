import React, {Component} from "react";
import {Container} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import { useTimer } from 'react-timer-hook';

class AssessmentScreen extends Component {
    constructor(props) {
        super(props);

        this.state = { isAssessmentEnabled: false};
    }

    componentDidMount() {

    }

    render() {
        //meta title
        document.title = "Remote's Apps | #1 Place for Matching Remote Talent with Careers";

        window.localStorage.setItem('theme', 'light');

        const time = new Date();
        time.setSeconds(time.getSeconds() + 30);

        function QuizTimer({ expiryTimestamp }) {
            const {
                seconds,
                minutes,
                hours,
                days,
                isRunning,
                start,
                pause,
                resume,
                restart,
            } = useTimer({expiryTimestamp, onExpire: () => console.warn('onExpire called')});

            return (
                <div style={{fontSize: '20px'}}>
                    {
                        seconds < 10 && <div style={{color: 'red'}}> <span>00</span>:<span>0{seconds}</span> </div>
                    }
                    {
                        seconds >= 10 && <div> <span>00</span>:<span>{seconds}</span> </div>
                    }
                </div>
            );
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <div style={{height: '50px'}} class="row">
                        </div>
                        <div class="row">
                            <div class="col-3">
                            </div>
                            <div class="col-6">
                                <div class="card card-header">
                                    <div className='row'>
                                       <div className='col'>
                                           <p class="card-text">Question 1/25</p>
                                       </div>
                                        <div style={{textAlign: 'right'}} className='col'>
                                            <QuizTimer expiryTimestamp={time} />
                                        </div>
                                    </div>
                                </div>
                                <div class="card card-body">
                                    <h3 class="card-title">What does REST stand for</h3>
                                    <p class="card-text">With supporting text below as a natural lead-in to additional
                                        content.</p>
                                    <div className="row">
                                        <div className="col-12">

                                            <form>
                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="checkbox_1"
                                                    onChange={() => {

                                                    }}
                                                    value={this.state.isAssessmentEnabled}
                                                />
                                                <label className="form-check-label" htmlFor="checkbox_1">
                                                    I pledge I will work on this quiz myself without any help.
                                                </label>

                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="checkbox_1"
                                                    onChange={() => {
                                                        this.setState({
                                                            isAssessmentEnabled: !this.state.isAssessmentEnabled
                                                        })
                                                    }}
                                                    value={this.state.isAssessmentEnabled}
                                                />
                                                <label className="form-check-label" htmlFor="checkbox_1">
                                                    I pledge I will work on this quiz myself without any help.
                                                </label>

                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="checkbox_1"
                                                    onChange={() => {
                                                        this.setState({
                                                            isAssessmentEnabled: !this.state.isAssessmentEnabled
                                                        })
                                                    }}
                                                    value={this.state.isAssessmentEnabled}
                                                />
                                                <label className="form-check-label" htmlFor="checkbox_1">
                                                    I pledge I will work on this quiz myself without any help.
                                                </label>

                                                <input
                                                    type="checkbox"
                                                    className="form-check-input"
                                                    id="checkbox_1"
                                                    onChange={() => {
                                                        this.setState({
                                                            isAssessmentEnabled: !this.state.isAssessmentEnabled
                                                        })
                                                    }}
                                                    value={this.state.isAssessmentEnabled}
                                                />
                                                <label className="form-check-label" htmlFor="checkbox_1">
                                                    I pledge I will work on this quiz myself without any help.
                                                </label>
                                            </form>

                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <button disabled={!this.state.isAssessmentEnabled} type="button" className="btn btn-primary waves-effect waves-light mt--30"
                                                    >Submit Answer </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

AssessmentScreen.propTypes = {
    t: PropTypes.any
};

export default withTranslation()(AssessmentScreen);