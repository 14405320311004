  import axios from "axios"
  import accessToken from "./jwt-token-access/accessToken"

  //apply base url for axios
  const API_URL = "http://localhost:8525/api"

  const axiosApi = axios.create({
    baseURL: API_URL,
  })

  axiosApi.defaults.headers.common["Authorization"] = JSON.parse(localStorage.getItem("authUser"));

  axiosApi.interceptors.response.use(
    response => response,
    error => Promise.reject(error)
  )

  export async function get(url, config = {}) {
    if(config.headers.Authorization) {
      console.log(config.headers)
      axiosApi.defaults.headers.common["Authorization"] = config.headers.Authorization;
    }
    return await axiosApi.get(url, { ...config }).then(response => response.data)
  }

  export async function post(url, data, config = {}) {
    if(config.headers.Authorization) {
      axiosApi.defaults.headers.common["Authorization"] = config.headers.Authorization;
    }
    return axiosApi
      .post(url, { ...data }, { ...config })
      .then(response => response.data)
  }

  export async function uploadFile(url, data, config = {}) {
    if(config.headers.Authorization) {
      axiosApi.defaults.headers.common["Authorization"] = config.headers.Authorization;
    }
    const fileUploadInstance = axios.create({
      baseURL: API_URL,
      headers: {
        'Authorization' : config.headers.Authorization,
        'Application-Type': 'multipart/form-data'
      }
    })
    fileUploadInstance.interceptors.response.use(
        response => response,
        error => Promise.reject(error)
    )
    return fileUploadInstance
        .post(url, data, { ...config })
        .then(response => response.data)
  }

  export async function put(url, data, config = {}) {
    if(config.headers.Authorization) {
      axiosApi.defaults.headers.common["Authorization"] = config.headers.Authorization;
    }
    return axiosApi
      .put(url, { ...data }, { ...config })
      .then(response => response.data)
  }

  export async function del(url, config = {}) {
    if(config.headers.Authorization) {
      axiosApi.defaults.headers.common["Authorization"] = config.headers.Authorization;
    }
    return await axiosApi
      .delete(url, { ...config })
      .then(response => response.data)
  }
