import React, {Component} from "react";
import {Container} from "reactstrap";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import matter from 'gray-matter';
import AboutOne from '../../components/FrontPage/abouts/AboutOne';
import BlogOne from '../../components/FrontPage/blogs/BlogOne';
import BrandOne from '../../components/FrontPage/brands/BrandOne';
import CallToActionOne from '../../components/FrontPage/call-to-actions/CallToActionOne';
import CounterOne from '../../components/FrontPage/counters/CounterOne';
import Layout from '../../components/FrontPage/layouts/Layout';
import PortfolioOne from '../../components/FrontPage/portfolio/PortfolioOne';
import PricingOne from '../../components/FrontPage/pricings/PricingOne';
import ServiceOne from '../../components/FrontPage/services/ServiceOne';
import BannerOne from '../../components/FrontPage/banners/BannerOne';
import SliderOne from '../../components/FrontPage/sliders/SliderOne';
import TeamOne from '../../components/FrontPage/teams/TeamOne';
import TestimonialOne from '../../components/FrontPage/testimonials/TestimonialOne';

class ForBusinessPage extends Component {
    constructor(props) {
        super(props);
        this.state = {posts: null};

    }

    render() {
        //meta title
        document.title = "Remote's Apps | #1 Place for Matching Remote Talent with Careers";
        window.localStorage.setItem('theme', 'light');
        return (
            <React.Fragment>
                <Layout>
                    <Container fluid>
                        <BannerOne/>

                        <ServiceOne/>

                        <SliderOne/>

                        <AboutOne/>

                        <PortfolioOne/>

                        <CounterOne/>

                        <TestimonialOne/>

                        <TeamOne/>

                        <PricingOne/>

                        <BrandOne/>

                        {
                            this.state.posts != null && <BlogOne posts={this.state.posts.slice(0, 2)}/>
                        }


                        <CallToActionOne/>
                    </Container>
                </Layout>

            </React.Fragment>
        );
    }
}

export async function getStaticProps() {
    const files = fs.readdirSync(path.join('exp'));

    const posts = files.map((filename) => {
        const slug = filename.replace(".md", "");

        const mardownWithMeta = fs.readFileSync(
            path.join("data/posts", filename),
            "utf-8"
        );

        const {data: postdata} = matter(mardownWithMeta);

        return {
            slug,
            date: postdata.publishedAt,
            postdata,
        };
    });

    return {
        props: {
            posts: posts
        },
    };
}

ForBusinessPage.propTypes = {
    t: PropTypes.any,
    posts: PropTypes.object
};

export default withTranslation()(ForBusinessPage);