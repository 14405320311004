import {
  ADMIN_LOGIN_USER,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT_USER,
  ADMIN_LOGOUT_USER_SUCCESS,
  ADMIN_API_ERROR
} from "./actionTypes"

export const adminLoginUser = (user, history) => {
  return {
    type: ADMIN_LOGIN_USER,
    payload: { user, history },
  }
}

export const adminLoginSuccess = user => {
  return {
    type: ADMIN_LOGIN_SUCCESS,
    payload: user,
  }
}

export const adminLogoutUser = history => {
  return {
    type: ADMIN_LOGOUT_USER,
    payload: { history },
  }
}

export const adminLogoutUserSuccess = () => {
  return {
    type: ADMIN_LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const adminApiError = error => {
  return {
    type: ADMIN_API_ERROR,
    payload: error,
  }
}

export const socialLogin = (data, history, type) => {
  return {
    type: SOCIAL_LOGIN,
    payload: { data, history, type },
  }
}
