import React, { Component } from "react";
import PropTypes from "prop-types";
import {Col, Container, Row} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {profileSkills} from "../../../../constants/profile";

class AssessmentFrontDoor extends Component {
    constructor(props) {
        super(props);

        this.state = { isAssessmentEnabled: false};
    }

    componentDidMount() {

    }

    render() {
        //meta title
        document.title = "Remote's Apps | #1 Place for Matching Remote Talent with Careers";

        window.localStorage.setItem('theme', 'light');

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <div class='row'>
                            <div className="col">
                                <h1 style={{textAlign: 'center'}} className="mb-sm-0 font-size-24"><bold>Backend Assessment</bold></h1>
                                <h6 style={{textAlign: 'center'}} className="mb-sm-0 font-size-14 mt--20"><i class='bx bx-time-five mr--5'/>Time to complete: 25 - 35min</h6>
                            </div>
                        </div>
                        <div style={{height: '20px'}} class='row'>
                        </div>
                        <div class='row mt--25'>
                            <hr/>
                        </div>
                        <div class='row'>
                            <div className="col-2" ></div>
                            <div className="col">
                                <p>
                                    This assessment covers topics connected to backend development.<br/>
                                    You will be getting questions and code snippets to solve from topics such as:

                                    <ul>
                                        <li>
                                            Coding principles and logic
                                        </li>
                                        <li>
                                            Commonly used algorithms
                                        </li>
                                        <li>
                                            Low-level system questions
                                        </li>
                                        <li>
                                            Backend architecture and design
                                        </li>
                                        <li>
                                            Data structures and databases
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div class='row'>
                            <div class='col'>

                            </div>
                            <div class='col-lg-4'>
                                <div style={{backgroundColor: '#FFFACD'}} class="card card-body">
                                    <p class="card-text">You can take each assessment once in 10 days</p>
                                </div>
                            </div>
                            <div class='col'>

                            </div>
                        </div>
                        <div class='row'>
                            <div className="col-2" ></div>
                            <div className="col">
                                <div>
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="checkbox_1"
                                        onChange={() => {
                                            this.setState({
                                                isAssessmentEnabled: !this.state.isAssessmentEnabled
                                            })
                                        }}
                                        value={this.state.isAssessmentEnabled}
                                    />
                                    <label className="form-check-label" htmlFor="checkbox_1">
                                        I pledge I will work on this quiz myself without any help.
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class='row'>
                            <div className="col-2" ></div>
                            <div className="col">
                                <div>
                                    <button disabled={!this.state.isAssessmentEnabled} type="button" className="btn btn-primary waves-effect waves-light mt--30"
                                            data-bs-toggle="modal" data-bs-target="#myModal">Start Assessment
                                    </button>

                                    <div id="myModal" style={{width: '400px'}} class="modal fade mt--25" tabIndex="-1" aria-labelledby="myModalLabel"
                                         aria-hidden="true" style={{display: 'none'}}>
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="myModalLabel">Important
                                                        reminders before you start</h5>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal"
                                                            aria-label="Close"></button>
                                                </div>
                                                <div class="modal-body">
                                                    <ul>
                                                        <li>
                                                            <p>Make sure you have uninterrupted time to take the assessment</p>
                                                        </li>
                                                        <li>
                                                            <p>You can take it once in 10 days</p>
                                                        </li>
                                                        <li>
                                                            <p>Assessment results will be added to your profile</p>
                                                        </li>
                                                    </ul>

                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" className="btn btn-secondary waves-effect"
                                                            data-bs-dismiss="modal">I'll take it another time
                                                    </button>
                                                    <button type="button"
                                                            className="btn btn-primary waves-effect waves-light"> Got it. Let's start
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

AssessmentFrontDoor.propTypes = {
    t: PropTypes.any
};

export default withTranslation()(AssessmentFrontDoor);