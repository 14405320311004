import React, { Component } from "react";
import PropTypes from "prop-types";
import {Col, Container, Row} from "reactstrap";
import {Link, withRouter} from "react-router-dom";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";
import {connect} from "react-redux";
import {getProfile} from "../../helpers/backend_helper";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {profilePictureSrc: null};
  }

  componentDidMount() {
    getProfile().then(
        (result) => {
          this.setState({
            //user: result.user,
            profilePictureSrc: result.user.talentProperties.profilePictureURL
          })

          setTimeout(() => {

          }, 4000);
        },
        (error) => {
          console.log(error.text);
        }
    );

  }

  render() {
    //meta title
    document.title = "Remote's Apps | #1 Place for Matching Remote Talent with Careers";

    function openProfilePictureInput() {
      const inputElement = document.getElementById("uploadProfileInput")
      inputElement.click()
    }

    function handleProfilePictureChange(event) {
      const fileObj = event.target.files && event.target.files[0];
      if (!fileObj) {
        return;
      }

      this.setState({
        profilePictureSrc: URL.createObjectURL(fileObj),
        profilePictureFile: fileObj
      })
    }

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
          <div class='row'>
            <div class='col-md-8'>
              <div class='row'>
                <div class='col'>
                  <div class="card card-body">
                    <h3 class="card-title">Special title treatment</h3>
                    <p class="card-text">With supporting text below as a natural lead-in to additional
                      content.</p>
                    <a href="javascript: void(0);" className="btn btn-primary waves-effect waves-light">Go somewhere</a>
                  </div>
                </div>
              </div>

              <div class='row'>
                <div class='col'>
                  <div class="card card-body">
                    <h3 class="card-title">Special title treatment</h3>
                    <p class="card-text">With supporting text below as a natural lead-in to additional
                      content.</p>
                    <a href="javascript: void(0);" className="btn btn-primary waves-effect waves-light">Go somewhere</a>
                  </div>
                </div>
              </div>

              <div class='row'>
                <div class='col'>
                  <div class="card card-body">
                    <h3 class="card-title">Special title treatment</h3>
                    <p class="card-text">With supporting text below as a natural lead-in to additional
                      content.</p>
                    <a href="javascript: void(0);" className="btn btn-primary waves-effect waves-light">Go somewhere</a>
                  </div>
                </div>
              </div>

              <div class='row'>
                <div class='col'>
                  <div class="card card-body">
                    <h3 class="card-title">Special title treatment</h3>
                    <p class="card-text">With supporting text below as a natural lead-in to additional
                      content.</p>
                    <a href="javascript: void(0);" className="btn btn-primary waves-effect waves-light">Go somewhere</a>
                  </div>
                </div>
              </div>

            </div>
            <div class='col-md-4'>
              <div class='row'>
                <div class='col'>
                  <div class="card card-body">
                    <h3 class="card-title">Special title treatment</h3>
                    <p class="card-text">With supporting text below as a natural lead-in to additional
                      content.</p>
                    <a href="javascript: void(0);" className="btn btn-primary waves-effect waves-light">Go somewhere</a>
                  </div>
                </div>
              </div>

              <div class='row'>
                <div class='col'>
                  <div class="card card-body">
                    <h3 class="card-title">Special title treatment</h3>
                    <p class="card-text">With supporting text below as a natural lead-in to additional
                      content.</p>
                    <a href="javascript: void(0);" className="btn btn-primary waves-effect waves-light">Go somewhere</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          </Container>
        </div>
      </React.Fragment>
    );
  }
}

Dashboard.propTypes = {
  t: PropTypes.any
};

export default withTranslation()(Dashboard);
