import React, {Component, useEffect, useState} from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import ReactPlayer from 'react-player'
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

class CoursePlayer extends Component {
    constructor(props) {
        super(props);

        this.state = { isMobile: false, screenHeight: 1000 };
    }

    componentDidMount() {
        this.setState({
            isMobile: (window.innerWidth < 720) ? true : false,
            screenHeight: window.innerHeight
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content" style={{backgroundColor: '#1e2429'}}>
                    <div class='row' style={{minHeight: this.state.screenHeight}}>
                        <div class='col-md-9' style={{display: 'inline-block'}}>
                            <div className='player-wrapper'>
                                <ReactPlayer
                                    className='react-player'
                                    url='https://www.youtube.com/watch?v=ysz5S6PUM-U'
                                    width='100%'
                                    height={this.state.isMobile ? '100%' : '900px'}
                                />
                            </div>
                        </div>
                        <div class='col-md-3' style={{backgroundColor: '#1e2429', display: 'inline-block'}}>
                            <div class='row'>
                                <div class='col'>
                                    <p style={{fontSize: '25px', color: 'white'}}>
                                        iOS App Development: The Big Picture
                                    </p>
                                    <p style={{fontSize: '15px', color: 'white'}}>
                                        By Karoly Nyisztor
                                    </p>
                                </div>
                            </div>
                            <div class='row mt--50'>
                                <div class='col'>
                                    <p>
                                        Table of Contents
                                    </p>
                                </div>
                            </div>

                            <div class='row mt--25'>
                                <div class='col'>
                                    <Accordion allowMultipleExpanded allowZeroExpanded>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Course Overview
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div class='row'>
                                                    <div class='col-1'>
                                                        <i style={{color: 'white'}} class="bx bx-play-circle"></i>
                                                    </div>

                                                    <div class='col'>
                                                        <p style={{color: 'white', fontSize: 12}}>Course Overview</p>
                                                    </div>

                                                    <div class='col'>
                                                        <p style={{color: 'white', fontSize: 12}}>1m 24s</p>
                                                    </div>
                                                </div>
                                                <div class='row'>
                                                    <div class='col-1'>
                                                        <i style={{color: 'white'}} class="bx bx-play-circle"></i>
                                                    </div>

                                                    <div class='col'>
                                                        <p style={{color: 'white', fontSize: 12}}>Introduction</p>
                                                    </div>

                                                    <div class='col'>
                                                        <p style={{color: 'white', fontSize: 12}}>1m 24s</p>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                    Getting Started with iOS Development
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <div class='row'>
                                                    <div class='col-1'>
                                                        <i style={{color: 'white'}} class="bx bx-play-circle"></i>
                                                    </div>

                                                    <div class='col'>
                                                        <p style={{color: 'white', fontSize: 12}}>Course Overview</p>
                                                    </div>

                                                    <div class='col'>
                                                        <p style={{color: 'white', fontSize: 12}}>1m 24s</p>
                                                    </div>
                                                </div>
                                                <div class='row'>
                                                    <div class='col-1'>
                                                        <i style={{color: 'white'}} class="bx bx-play-circle"></i>
                                                    </div>

                                                    <div class='col'>
                                                        <p style={{color: 'white', fontSize: 12}}>Introduction</p>
                                                    </div>

                                                    <div class='col'>
                                                        <p style={{color: 'white', fontSize: 12}}>1m 24s</p>
                                                    </div>
                                                </div>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class='row'>
                        <div class='col-md-2  col-lg-3'>

                        </div>
                        <div class='col-md'>
                            iOS App Development: The Big Picture
                        </div>
                    </div>

                </div>
            </React.Fragment>
        )
    }
}

CoursePlayer.propTypes = {
    t: PropTypes.any
};

export default withTranslation()(CoursePlayer);