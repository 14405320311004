export const profileSkills = [
    {
        skill: 'Front End',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Back End',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Fullstack',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Mobile',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Devops',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Data Engineer',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Data Science',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Machine Learning',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Blockchain',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Data Analyst',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Quality Assurance',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Project Manager',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Product Manager',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'UI/UX Designer',
        yoe: 0,
        isSelected: false
    },
    {
        skill: 'Engineering Manager',
        yoe: 0,
        isSelected: false
    }
]