import {
  PROFILE_ERROR,
  PROFILE_SUCCESS,
  EDIT_PROFILE,
  RESET_PROFILE_FLAG,
  GET_PROFILE,
  GET_PROFILE_SUCCESS, GET_PROFILE_FAILURE, UPLOAD_PROFILE_PICTURE, UPLOAD_RESUME
} from "./actionTypes"

const initialState = {
  error: "",
  success: "",
  getProfileSuccess: null
}

const profile = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_PROFILE:
      state = { ...state }
      break
    case UPLOAD_PROFILE_PICTURE:
      state = { ...state }
      break
    case UPLOAD_RESUME:
      state = { ...state }
      break
    case PROFILE_SUCCESS:
      state = { ...state, success: action.payload }
      break
    case PROFILE_ERROR:
      state = { ...state, error: action.payload }
      break
    case RESET_PROFILE_FLAG :
      state = { ...state, success: null }
      break
    case GET_PROFILE :
      state = { ...state }
      break
    case GET_PROFILE_SUCCESS :
      state = { ...state, getProfileSuccess: action.payload }
      break
    case GET_PROFILE_FAILURE :
      state = { ...state, getProfileFailure: action.payload }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default profile
