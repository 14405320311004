import React, { Component } from "react";
import PropTypes from "prop-types";
import {Col, Container, Row} from "reactstrap";
import {Link, withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

class Assessments extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        //meta title
        document.title = "Remote's Apps | #1 Place for Matching Remote Talent with Careers";

        window.localStorage.setItem('theme', 'light');

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <div class='row'>
                            <div className="col">
                                <h4 className="mb-sm-0 font-size-18">Skill Assessments</h4>

                            </div>
                        </div>
                        <div class='row'>
                            <div className="col">
                                <section class='mt--20'>
                                    <div class="row">
                                        <div class="col-xl-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div style={{textAlign: 'right'}}>
                                                       <button>
                                                           25 mins
                                                       </button>
                                                    </div>
                                                    <div style={{textAlign: 'center'}}>
                                                        <i className='bx bx-clipboard'/>
                                                    </div>
                                                    <h4 style={{textAlign: 'center'}} class="card-title">Backend Assessment</h4>
                                                    <p style={{textAlign: 'center'}} class="card-title-desc font-size-15">This assessment covers important coding principles,
                                                        commonly used algorithms, low-level system questions,
                                                        system design and backend architecture.</p>
                                                    <div style={{textAlign: 'center'}}>
                                                        <Link to='assessments/123'  type="button"
                                                                 className="btn btn-primary waves-effect waves-light btn-sm text-light">
                                                            Take Assessment
                                                        </Link>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div style={{textAlign: 'right'}}>
                                                        <button>
                                                            25 mins
                                                        </button>
                                                    </div>
                                                    <div style={{textAlign: 'center'}}>
                                                        <i className='bx bx-clipboard'/>
                                                    </div>
                                                    <h4 style={{textAlign: 'center'}} class="card-title">Backend Assessment</h4>
                                                    <p style={{textAlign: 'center'}} class="card-title-desc font-size-15">This assessment covers important coding principles,
                                                        commonly used algorithms, low-level system questions,
                                                        system design and backend architecture.</p>
                                                    <div style={{textAlign: 'center'}}>
                                                        <button  type="button"
                                                                 className="btn btn-primary waves-effect waves-light btn-sm text-light">
                                                            Take Assessment
                                                        </button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div style={{textAlign: 'right'}}>
                                                        <button>
                                                            25 mins
                                                        </button>
                                                    </div>
                                                    <div style={{textAlign: 'center'}}>
                                                        <i className='bx bx-clipboard'/>
                                                    </div>
                                                    <h4 style={{textAlign: 'center'}} class="card-title">Backend Assessment</h4>
                                                    <p style={{textAlign: 'center'}} class="card-title-desc font-size-15">This assessment covers important coding principles,
                                                        commonly used algorithms, low-level system questions,
                                                        system design and backend architecture.</p>
                                                    <div style={{textAlign: 'center'}}>
                                                        <button  type="button"
                                                                 className="btn btn-primary waves-effect waves-light btn-sm text-light">
                                                            Take Assessment
                                                        </button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div style={{textAlign: 'right'}}>
                                                        <button>
                                                            25 mins
                                                        </button>
                                                    </div>
                                                    <div style={{textAlign: 'center'}}>
                                                        <i className='bx bx-clipboard'/>
                                                    </div>
                                                    <h4 style={{textAlign: 'center'}} class="card-title">Backend Assessment</h4>
                                                    <p style={{textAlign: 'center'}} class="card-title-desc font-size-15">This assessment covers important coding principles,
                                                        commonly used algorithms, low-level system questions,
                                                        system design and backend architecture.</p>
                                                    <div style={{textAlign: 'center'}}>
                                                        <button  type="button"
                                                                 className="btn btn-primary waves-effect waves-light btn-sm text-light">
                                                            Take Assessment
                                                        </button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-4">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div style={{textAlign: 'right'}}>
                                                        <button>
                                                            25 mins
                                                        </button>
                                                    </div>
                                                    <div style={{textAlign: 'center'}}>
                                                        <i className='bx bx-clipboard'/>
                                                    </div>
                                                    <h4 style={{textAlign: 'center'}} class="card-title">Backend Assessment</h4>
                                                    <p style={{textAlign: 'center'}} class="card-title-desc font-size-15">This assessment covers important coding principles,
                                                        commonly used algorithms, low-level system questions,
                                                        system design and backend architecture.</p>
                                                    <div style={{textAlign: 'center'}}>
                                                        <button  type="button"
                                                                 className="btn btn-primary waves-effect waves-light btn-sm text-light">
                                                            Take Assessment
                                                        </button>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </div>
                        </div>

                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

Assessments.propTypes = {
    t: PropTypes.any
};

export default withTranslation()(Assessments);