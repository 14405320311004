import {
  ADMIN_LOGIN_USER,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGOUT_USER,
  ADMIN_LOGOUT_USER_SUCCESS,
  ADMIN_API_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
}

const adminLogin = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_LOGIN_USER:
      state = {
        ...state,
        loading: true,
      }
      break
    case ADMIN_LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      }
      break
    case ADMIN_LOGOUT_USER:
      state = { ...state }
      break
    case ADMIN_LOGOUT_USER_SUCCESS:
      state = { ...state }
      break
    case ADMIN_API_ERROR:
      state = { ...state, error: action.payload, loading: false }
      break
    default:
      state = { ...state }
      break
  }
  return state
}

export default adminLogin
