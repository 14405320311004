import React, {Component} from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {getQuizList} from "../../../helpers/backend_helper";
import {format} from "date-fns";
import { Link, withRouter } from "react-router-dom";

class QuizList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assessments: [],
            totalPages: 1,
            currentPage: 1,
            selectedQuiz: null
        };
    }

    componentDidMount() {
        if(!localStorage.getItem('superUser')) {
            console.error('Not Authorized')
            return
        }

        this.getQuizList()

    }

    createPaginationElements() {
        const paginationArray = []
        for(let i = 1; i <= this.state.totalPages; i++){
            paginationArray.push(
                <li className="paginate_button page-item">
                    <button
                        onClick={() => {
                            this.getQuizList(i)
                        }}
                        aria-controls="datatable"
                        data-dt-idx={i}
                        tabIndex="0"
                        className="page-link">{i}</button>
                </li>
            )

        }
        return paginationArray;
    }

    getQuizList(pageNumber: number = 1, limit: number = 5, searchQuery: string = null) {
        getQuizList({
            params: {
                page: pageNumber,
                limit: limit,
                searchString: searchQuery
            },
            headers: {
                Authorization: JSON.parse(localStorage.getItem('superUser'))
            }
        }).then(
            (result) => {

                const assessments = result.assessments
                const totalPages = result.totalPages
                const currentPage = result.currentPage

                this.setState({
                    assessments: assessments,
                    totalPages: totalPages,
                    currentPage: Number(currentPage)
                })

                setTimeout(() => {

                }, 4000);
            },
            (error) => {
                console.log(error.text);
            }
        );
    }

    render() {
        //meta title
        document.title = "Admin Quiz List";

        return (
            <React.Fragment>
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title">Assessment List</h4>
                                <p class="card-title-desc"> Manage assessments in the system, and select assessments that you'd like to
                                    update or remove properties from
                                </p>

                                <table id="datatable"
                                       className="table table-bordered dt-responsive nowrap w-100 dataTable no-footer dtr-inline"
                                       role="grid" aria-describedby="datatable_info">
                                    <thead>
                                    <tr role="row">
                                        <th className="sorting_asc" tabIndex="0" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '128px'}} aria-sort="ascending"
                                            aria-label="Assessment ID: activate to sort column descending">Assessment ID
                                        </th>
                                        <th className="sorting_asc" tabIndex="1" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '128px'}} aria-sort="ascending"
                                            aria-label="Category: activate to sort column descending">Category
                                        </th>
                                        <th className="sorting" tabIndex="2" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '198px'}}
                                            aria-label="Type: activate to sort column ascending">Type
                                        </th>
                                        <th className="sorting" tabIndex="3" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '91px'}}
                                            aria-label="Status: activate to sort column ascending">Status
                                        </th>
                                        <th className="sorting" tabIndex="4" aria-controls="datatable" rowSpan="1"
                                            colSpan="1" style={{width: '37px'}}
                                            aria-label="IsTimed: activate to sort column ascending">IsTimed
                                        </th>
                                    </tr>
                                    </thead>


                                    <tbody>

                                    {
                                        this.state.assessments.map((assessment, index) => {
                                            const context = this
                                            return <tr class={index % 2 === 0 ? "even" : "odd"}>
                                                <td>{assessment._id}</td>
                                                <td style={{textDecoration: 'underline', textDecorationColor: 'blue'}}
                                                    className="sorting_1 dtr-control">
                                                    <div class='row'>
                                                        <div class='col-12'>
                                                            <Link
                                                                to={'/quizBuilder?assessmentId=' + assessment._id}
                                                                style={{textAlign: 'left'}} type="button"
                                                                className="btn btn-link waves-effect waves-light"
                                                                >{assessment.category}
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{assessment.type}</td>
                                                <td>{assessment.status}</td>
                                                <td>true</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>

                                <div class="row">
                                    <div class="col-sm-12 col-md-5">
                                        <div class="dataTables_info" id="datatable_info" role="status"
                                             aria-live="polite">{'Showing ' + this.state.currentPage + ' of '
                                            + this.state.totalPages}
                                        </div>
                                    </div>
                                    <div class="col-sm-12">
                                        <div class="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                                            <ul class="pagination">

                                                {
                                                    this.state.currentPage > 1 && <li className="paginate_button page-item previous"
                                                                                      id="datatable_previous">
                                                        <button onClick={() => {
                                                            let currentPage: number = this.state.currentPage
                                                            this.getQuizList(--currentPage)
                                                        }} aria-controls="datatable"
                                                                data-dt-idx="0" tabIndex="0"
                                                                className="page-link">
                                                            Previous
                                                        </button>
                                                    </li>
                                                }

                                                {
                                                    this.createPaginationElements().map((paginationElement) => {
                                                        return paginationElement
                                                    })
                                                }
                                                {
                                                    this.state.currentPage <= (this.state.totalPages - 1) && <li className="paginate_button page-item next" id="datatable_next">
                                                        <button onClick={() => {
                                                            let currentPage: number = this.state.currentPage
                                                            this.getQuizList(++currentPage)
                                                        }}
                                                                aria-controls="datatable" data-dt-idx="7" tabIndex="0"
                                                                className="page-link">Next</button>
                                                    </li>
                                                }

                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

QuizList.propTypes = {
    t: PropTypes.any
};

export default withRouter(QuizList);