import { takeEvery, put, call } from "redux-saga/effects";

//Account Redux states
import {REGISTER_BUSINESS_USER, REGISTER_USER} from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

import {
  postUserRegister,
  postBusinessUserRegister
} from "../../../helpers/backend_helper";


// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user, history } }) {
  try {
    localStorage
    const response = yield call(postUserRegister, user);
    yield put(registerUserSuccessful(response.user));
    localStorage.setItem("authUser", JSON.stringify('Bearer ' + response.token))
    history.push("/dashboard")
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

function* registerBusinessUser({ payload: { user } }) {
  try {
    const response = yield call(postBusinessUserRegister, user);
    yield put(registerUserSuccessful(response));
  } catch (error) {
    yield put(registerUserFailed(error));
  }
}

function* accountSaga() {
  yield takeEvery(REGISTER_USER, registerUser);
  yield takeEvery(REGISTER_BUSINESS_USER, registerBusinessUser);
}

export default accountSaga;
