import React, {Component, useEffect, useState} from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import EditorState from "draft-js/lib/EditorState";

interface CurriculumSectionInterface {
    sectionTitle: string;
    lectures: [{
        type: string;
        title: string;
        learningObjective: string;
        videoURI: string
    }]
}

class CourseBuilder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            numTabs: 4,
            tabs: ['courseDetails', 'requirements', 'content', 'review'],
            curriculum: {
                sections: [{
                    sectionTitle: 'Introduction',
                    lectures: [{
                        type: 'lecture',
                        title: 'Introduction',
                        learningObjective: 'Add a learning description...',
                        videoURI: ''
                    }]
                }]
            },
            editorState: EditorState.createWithText('Add a description. Include what students will be able to do after completing the lecture.')
        }
    }

    componentDidMount() {

    }

    onEditorStateChange: Function = (editorState) => {
        this.setState({
            editorState
        });
    };

    previousTab() {
        this.setState({
            currentIndex: (this.state.currentIndex <= 0) ? 0 : (this.state.currentIndex - 1)
        })
    }

    nextTab() {
        this.setState({
            currentIndex: (this.state.currentIndex >= (this.state.numTabs - 1)) ? this.state.currentIndex
                : (this.state.currentIndex + 1)
        })
    }

    gotoTab(index: number) {
        this.setState({
            currentIndex: index
        })
    }

    formSwitch(context, param = 'courseDetails') {
        switch (param) {
            case 'courseDetails':
                return <form>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="course-name-input">Course Title</label>
                                <input type="text" className="form-control"
                                       id="course-name-input"
                                       placeholder="Ex. SwiftUI Masterclass 2023 - iOS App Development & Swift"/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="course-short-description-input">Short Description</label>
                                <textarea id="course-short-description-input" className="form-control"
                                          maxLength="225" rows="3"
                                          placeholder="Ex. The Complete iOS 16 and 15 App Development Course with SwiftUI From Beginner to Advanced App Developer with Xcode"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="learn-description-input">What You'll Learn</label>
                                <textarea id="learn-description-input" className="form-control"
                                          maxLength="2000" rows="10"
                                          placeholder="Describe what you expect your audience to learn from this course"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="course-long-description-input">Main Description</label>
                                <textarea id="course-main-description-input" className="form-control"
                                          maxLength="2000" rows="10"
                                          placeholder="Detailed Description of Course Material"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="course-language-input">Language</label>
                                <select value='English' className="form-select">
                                    <option>Language</option>
                                    <option>English</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
            case 'requirements':
                return <form>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="course-requirements-input">Course Requirements</label>
                                <textarea id="course-requirements-input" className="form-control"
                                          maxLength="225" rows="10"
                                          placeholder={"• Macintosh computer\n• Willing to create awesome apps by following the instructor step by step\n• macOS 13 Ventura from Apple (free)"}></textarea>
                            </div>
                        </div>
                    </div>
                </form>
            case 'content':
                return <form>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="mb-3">
                                <label htmlFor="course-name-input">Curriculum Builder</label>
                                <div class="accordion" id="accordionExample">
                                    {
                                        context.state.curriculum.sections.map((section, index) => {
                                            return <div class="accordion-item">
                                                <h2 class="accordion-header" id="headingOne">
                                                    <button className="accordion-button fw-medium collapsed" type="button"
                                                            data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                            aria-expanded="false" aria-controls="collapseOne">
                                                        {
                                                            'Section ' + (index + 1) + ': ' + section.sectionTitle
                                                        }
                                                        <i class="bx bx-pencil ml--20"></i>
                                                        <i class="bx bx-trash-alt ml--10"></i>
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" class="accordion-collapse collapse"
                                                     aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div class="accordion-body">
                                                        {
                                                            section.lectures.map((lecture, index) => {
                                                                return <div class="text-muted">
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header" id="headingOne">
                                                                            <button className="accordion-button fw-medium collapsed" type="button"
                                                                                    data-bs-toggle="collapse" data-bs-target="#collapseOne"
                                                                                    aria-expanded="false" aria-controls="collapseOne">
                                                                                {
                                                                                    'Lecture ' + (index + 1) + ': ' + lecture.title
                                                                                }
                                                                                <i class="bx bx-pencil ml--20"></i>
                                                                                <i class="bx bx-trash-alt ml--10"></i>

                                                                                <button type="button"
                                                                                        className="btn btn-outline-dark waves-effect waves-light ml--50">+ Content
                                                                                </button>
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseOne" class="accordion-collapse collapse"
                                                                             aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                            <div class="accordion-body">
                                                                                <div class="text-muted">
                                                                                    <Editor
                                                                                        editorState={context.state.editorState}
                                                                                        toolbarClassName="toolbarClassName"
                                                                                        wrapperClassName="wrapperClassName"
                                                                                        editorClassName="editorClassName"
                                                                                        onEditorStateChange={this.onEditorStateChange}
                                                                                    />
                                                                                </div>
                                                                                <div class='row'>
                                                                                    <div class="col-lg-6">
                                                                                        <div class="mb-3">
                                                                                            <button type="button"
                                                                                                    className="btn btn-outline-primary waves-effect waves-light">Cancel
                                                                                            </button>
                                                                                            <button type="button"
                                                                                                    className="btn btn-dark waves-effect waves-light ml--15">Save
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                                <div class='row'>
                                                                                    <div class="col-lg-6">
                                                                                        <div class="mb-3">
                                                                                            <button type="button"
                                                                                                    className="btn btn-light waves-effect waves-light w-sm">
                                                                                                <i class="mdi mdi-upload d-block font-size-16"></i> Upload Lecture

                                                                                            </button>
                                                                                            <input type="file" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }

                                </div>

                            </div>
                        </div>
                    </div>
                    <div class='row'>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <button>
                                    + Add
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            case 'review':
                return <form>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="course-name-input">Course Title</label>
                                <input type="text" className="form-control"
                                       id="course-name-input"
                                       placeholder="Ex. SwiftUI Masterclass 2023 - iOS App Development & Swift"/>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="course-short-description-input">Short Description</label>
                                <textarea id="course-short-description-input" className="form-control"
                                          maxLength="225" rows="3"
                                          placeholder="Ex. The Complete iOS 16 and 15 App Development Course with SwiftUI From Beginner to Advanced App Developer with Xcode"></textarea>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="learn-description-input">What You'll Learn</label>
                                <textarea id="learn-description-input" className="form-control"
                                          maxLength="2000" rows="10"
                                          placeholder="Describe what you expect your audience to learn from this course"></textarea>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="course-long-description-input">Main Description</label>
                                <textarea id="course-main-description-input" className="form-control"
                                          maxLength="2000" rows="10"
                                          placeholder="Detailed Description of Course Material"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="mb-3">
                                <label htmlFor="course-language-input">Language</label>
                                <select value='English' className="form-select">
                                    <option>Language</option>
                                    <option>English</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </form>
        }
    }

    render() {

        return (
            <React.Fragment>
                <div className="page-content">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title mb-4">Course Wizard</h4>

                                    <div id="basic-example" role="application" class="wizard clearfix">
                                        <div class="steps clearfix">
                                            <ul role="tablist">
                                                <li role="tab" className={
                                                    "first " + (this.state.currentIndex == 0 ? "current" : "disabled")
                                                } aria-disabled="false"
                                                    aria-selected="false"><a id="basic-example-t-0"
                                                                             onClick={() => {
                                                                                 this.gotoTab(0)
                                                                             }}
                                                                             aria-controls="basic-example-p-0"><span
                                                    class="number">1.</span> Course Details</a></li>
                                                <li role="tab" className={
                                                    "" + (this.state.currentIndex == 1 ? "current" : "disabled")
                                                } aria-disabled="false"
                                                    aria-selected="false"><a id="basic-example-t-1"
                                                                             onClick={() => {
                                                                                 this.gotoTab(1)
                                                                             }}
                                                                             aria-controls="basic-example-p-1"><span
                                                    class="number">2.</span> Course Requirements</a></li>
                                                <li role="tab" className={
                                                    "" + (this.state.currentIndex == 2 ? "current" : "disabled")
                                                } aria-disabled="false"
                                                    aria-selected="false"><a id="basic-example-t-2"
                                                                             onClick={() => {
                                                                                 this.gotoTab(2)
                                                                             }}
                                                                             aria-controls="basic-example-p-2"><span
                                                    class="number">3.</span> Curriculum</a></li>
                                                <li role="tab" className={
                                                    "last " + (this.state.currentIndex == 3 ? "current" : "disabled")
                                                } aria-disabled="false"
                                                    aria-selected="true"><a id="basic-example-t-3"
                                                                            onClick={() => {
                                                                                this.gotoTab(3)
                                                                            }}
                                                                            aria-controls="basic-example-p-3"><span
                                                    class="current-info audible">current step: </span><span
                                                    class="number">4.</span> Review</a></li>
                                            </ul>
                                        </div>
                                        <div class="content clearfix">
                                            {
                                                this.formSwitch(this, this.state.tabs[this.state.currentIndex])
                                            }
                                        </div>
                                        <div class="actions clearfix">
                                            <ul role="menu" aria-label="Pagination">
                                                <li className="" aria-disabled="false"><a onClick={() => {
                                                    this.previousTab()
                                                }}
                                                                                          role="menuitem">Previous</a>
                                                </li>
                                                <li><a onClick={() => {
                                                    this.nextTab()
                                                }} role="menuitem">{
                                                        this.state.currentIndex == (this.state.numTabs - 1) ? 'Finish' : 'Next'
                                                }</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

CourseBuilder.propTypes = {
    t: PropTypes.any
};

export default withTranslation()(CourseBuilder);