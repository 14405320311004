import Image from 'next/image';
import Link from 'next/link';
import {useEffect, useState} from 'react';
import Tilt from 'react-parallax-tilt';
import layerIcon from '../../../assets/frontPage/images/icons/layer.svg'

const ServiceCardOne = (
    {
        column,
        serviceClass,
        index,
        activeIndex,
        data,
        icon,
        layer,
        changeActive,
        alignment = 'center',
    }
) => {
    const [textAlignment, setTextAlignment] = useState('text-center');

    useEffect(() => {
        if (alignment === 'center') setTextAlignment('text-center');
        else if (alignment === 'right') setTextAlignment('text-end');
        else setTextAlignment('text-start');
    }, [alignment]);

    return (
        <div className={column} data-aos="aos-fade-in-up">
            <Tilt tiltMaxAngleX={7} tiltMaxAngleY={7}>
                <div
                    className={`axil-service axil-control paralax-image ${serviceClass} ${textAlignment} ${
                        activeIndex === index ? "active" : ""
                    }`}
                    onMouseEnter={() => changeActive(index)}
                >
                    <div className="inner">
                        <div className={`icon ${data.iconColorClass}`}>
                            <div className="icon-inner">
                                <div className="layer-image">
                                    <img
                                        width={50}
                                        height={49}
                                        src={layerIcon}
                                        alt="Icon Images"
                                    />
                                </div>
                                <div className="image-2">
                                    <img
                                        width={data.imageIcon.width}
                                        height={data.imageIcon.height}
                                        src={icon}
                                        alt="Shape Images"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="content">

                            <p>{data.description}</p>

                        </div>
                    </div>
                </div>
            </Tilt>
        </div>
    );
};

export default ServiceCardOne;
