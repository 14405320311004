import PropTypes from "prop-types";
import React, {Component, useState} from "react";
import {Alert, Button, Card, CardBody, Col, Container, Row, Label, ButtonGroup, CardHeader} from "reactstrap";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js`;


// Redux
import { connect } from "react-redux";
import {Link, Route, Router, Switch, withRouter} from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import {
  editProfile,
  getProfileData,
  getProfileSuccess,
  resetProfileFlag,
  getProfileFailure,
  uploadProfilePicture,
  uploadResume
} from "../../store/actions";
import Dropzone from 'react-dropzone'
import {getProfile} from "../../helpers/backend_helper";
import {profileSkills} from "../../constants/profile";
import {techSkills} from "../../constants/techSkills";

class UserProfile extends Component {

  constructor(props) {
    super(props);

    this.state = { email: "", name: "", getProfileSuccess: null, profileSkills: profileSkills,
      selectedSkills: [], techSkills: [], selectedMainTechSkills: [], selectedSecondaryTechSkills: [],
    profilePictureSrc: null, profilePictureFile: null, resumeFileSrc: null, resumeFile: null,
      numberOfResumePages: null, githubLink: null, gitlabLink: null, stackoverflowLink: null, linkedinLink: null,
      twitterLink: null, yourWebsiteLink: null, otherLink: null, user: null
    };

  }

  componentDidMount() {
    getProfile().then(
        (result) => {
          this.setState({
            user: result.user,
            getProfileSuccess: result.user,
            selectedSkills: result.user.talentProperties.techRoles,
            selectedMainTechSkills: result.user.talentProperties.mainSkills,
            selectedSecondaryTechSkills: result.user.talentProperties.secondarySkills,
            profilePictureSrc: result.user.talentProperties.profilePictureURL,
            resumeFileSrc: result.user.talentProperties.resumeURL,
            githubLink: result.user.talentProperties.techReferences?.github ?? null,
            gitlabLink: result.user.talentProperties.techReferences?.gitlab ?? null,
            stackoverflowLink: result.user.talentProperties.techReferences?.stackoverflow ?? null,
            linkedinLink: result.user.talentProperties.techReferences?.linkedin ?? null,
            twitterLink: result.user.talentProperties.techReferences?.twitter ?? null,
            yourWebsiteLink: result.user.talentProperties.techReferences?.yourWebsite ?? null,
            otherLink: result.user.talentProperties.techReferences?.other ?? null
          })

          this.downloadResumeFromRemote(result.user.talentProperties.resumeURL)

          setTimeout(() => {

          }, 4000);
        },
        (error) => {
          console.log(error.text);
        }
    );

    const skillSet = this.getTechSkills()
    this.setState({
      techSkills: [...skillSet]
    })
  }

  downloadResumeFromRemote(resumeFileSrc) {
    const request = new XMLHttpRequest();
    request.open('GET', resumeFileSrc, true);
    request.responseType = 'blob';
    request.onload = function() {

      let reader = new FileReader()
      reader.readAsDataURL(request.response)
      reader.onload = () => {
        const fileUrl = reader.result
        context.setState({
          resumeFileSrc: fileUrl,
          resumeFile: fileObj
        })
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      }
    };
    request.send();
  }

  getTechSkills()  {
    const skillList = techSkills.map(function (techSkill) {
      return techSkill.clean_skill
    })

    let skillSet = new Set(skillList)
    return skillSet
  }

  profileSectionSwitch(context, param = 'personalInfo') {

    function updateProfileSkill(skill) {

      const filteredSkills = context.state.selectedSkills.filter(function (s) {
        if(skill.skill !== s.skill) {
          return s
        }
      })

      filteredSkills.push(skill)

      context.setState({
        selectedSkills: filteredSkills
      })
    }

    function updateProfileSkillExperience(skill) {
      const inputElement = document.getElementById("techRole")
      const val = inputElement.value;

      const updatedProfileSkills  = context.state.selectedSkills.map(function (selectedSkill) {
        if (selectedSkill.skill === skill.skill) {
          return {
            skill: skill.skill,
            yoe: val
          };
        } else {
          return selectedSkill
        }
      })

      context.setState({
        selectedSkills: updatedProfileSkills
      })
      
    }

    function removeProfileSkill(skill) {

      const filteredSkills = context.state.selectedSkills.filter(function (s) {
        if(skill.skill !== s.skill) {
          return s
        }
      })

      context.setState({
        selectedSkills: filteredSkills
      })
    }

    function onMainTechSkillInput() {
      const inputElement = document.getElementById("techSkills")
      const val = inputElement.value;
      const options = document.getElementById('techSkillOptions').childNodes;
      options.forEach(function (option) {
        const currentValue = option.value
       if (currentValue === val) {
         const currentSelectedSkills = context.state.selectedMainTechSkills
         currentSelectedSkills.push(currentValue)

         if(currentSelectedSkills.length == 4) {
           inputElement.disabled = true
         } else {
           inputElement.disabled = false
         }

         context.setState({
           selectedMainTechSkills: currentSelectedSkills
         })
         inputElement.value = ''
        }
      })
    }

    function onSecondaryTechSkillInput() {
      const inputElement = document.getElementById("supportingTechSkills")
      const val = inputElement.value;
      const options = document.getElementById('supportingTechSkillOptions').childNodes;
      options.forEach(function (option) {
        const currentValue = option.value
        if (currentValue === val) {
          const currentSelectedSkills = context.state.selectedSecondaryTechSkills
          currentSelectedSkills.push(currentValue)

          if(currentSelectedSkills.length == 8) {
            inputElement.disabled = true
          } else {
            inputElement.disabled = false
          }

          context.setState({
            selectedSecondaryTechSkills: currentSelectedSkills
          })
          inputElement.value = ''
        }
      })
    }

    function removeMainTechSkill(skill) {
      const inputElement = document.getElementById("techSkills")
      const filteredSkills = context.state.selectedMainTechSkills.filter(function (s) {
        if(skill !== s) {
          return s
        }
      })

      if(filteredSkills.length == 4) {
        inputElement.disabled = true
      } else {
        inputElement.disabled = false
      }

      context.setState({
        selectedMainTechSkills: filteredSkills
      })
    }

    function removeSecondaryTechSkill(skill) {
      const inputElement = document.getElementById("supportingTechSkills")
      const filteredSkills = context.state.selectedSecondaryTechSkills.filter(function (s) {
        if(skill !== s) {
          return s
        }
      })

      if(filteredSkills.length == 8) {
        inputElement.disabled = true
      } else {
        inputElement.disabled = false
      }

      context.setState({
        selectedSecondaryTechSkills: filteredSkills
      })
    }

    function openProfilePictureInput() {
      const inputElement = document.getElementById("uploadProfileInput")
      inputElement.click()
    }

    function handleProfilePictureChange(event) {
      const fileObj = event.target.files && event.target.files[0];
      if (!fileObj) {
        return;
      }

      context.setState({
        profilePictureSrc: URL.createObjectURL(fileObj),
        profilePictureFile: fileObj
      })
    }

    function openResumeUploadPrompt() {
      const inputElement = document.getElementById("uploadResumeInput")
      inputElement.click()
    }

    function handleResumeUploadEvent(event) {
      const fileObj = event.target.files && event.target.files[0];
      if (!fileObj) {
        return;
      }

      let reader = new FileReader()
      reader.readAsDataURL(fileObj)
      reader.onload = () => {
        const fileUrl = reader.result
        context.setState({
          resumeFileSrc: fileUrl,
          resumeFile: fileObj
        })
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      }
    }

    function onDocumentLoadSuccess({ numPages }) {
      console.log('Number of pages: ' + numPages)
      context.setState({
        numberOfResumePages: numPages
      })
    }

    function updateProfileReferences(referenceLink) {

    }

    switch(param) {
      case "personalInfo":
        return <div>
          <Row>
            <Col>
              <p style={{ fontSize: "20px" }}>
                PROFILE PHOTO
              </p>
            </Col>
          </Row>
          <Row style={{ paddingTop: '20px'}}>
            <Col>
              <div className={'d-flex flex-wrap gap-2'}>
                {
                  context.state.profilePictureSrc == null && <div className="avatar-lg align-self-center">
                      <div
                          className="avatar-title rounded-circle bg-dark bg-soft text-dark" onClick={() => openProfilePictureInput()}>
                        <i className="dripicons-camera"></i>
                        <input
                            id="uploadProfileInput"
                            style={{display: 'none'}}
                            type="file"
                            onChange={handleProfilePictureChange}
                            accept='image/*'
                        />
                      </div>
                    </div>
                }

                {
                  context.state.profilePictureSrc != null &&  <div className="avatar-lg align-self-center">
                      <img
                          className="avatar-title rounded-circle bg-dark bg-soft text-dark"
                          onClick={() => openProfilePictureInput()}
                          src={context.state.profilePictureSrc}/>
                        <input
                            id="uploadProfileInput"
                            style={{display: 'none'}}
                            type="file"
                            onChange={handleProfilePictureChange}
                            accept='image/*'
                        />
                    </div>
                }


                <div className="avatar-lg align-self-center">
                  <div
                      style={{alignItems: 'center',
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        width: '200px'
                  }}>
                    <div>
                      <h4>Set your photo
                      (optional)</h4>
                      <p className="u-color--gray-50 u-body-XXS u-mt4"> JPG or PNG. Max size of 2MB </p>
                    </div>
                  </div>
                </div>

              </div>
            </Col>
          </Row>
          <Row style={{paddingTop: '40px'}}>
            <Col>
              <p style={{ fontSize: "20px" }}>
                Name and Location
              </p>
            </Col>
          </Row>
          <Row>
            <div className="p-2">
              { (this.props.error && this.props.error != '') &&
                  <Alert color={'danger'}>
                    {'Profile Failed to Update'}
                  </Alert>
              }
              { (this.props.success && this.props.success != '') &&
                  <Alert>
                    {'Profile Successfully Updated'}
                  </Alert>
              }
              {
                  (this.state.getProfileSuccess) && <Formik
                      enableReinitialize={true}
                      initialValues={{
                        firstName: this.state.getProfileSuccess.firstName,
                        lastName: (this.state.getProfileSuccess.lastName) || "",
                        city: (this.state && this.state.getProfileSuccess.location.city) || "",
                        state: (this.state && this.state.getProfileSuccess.location.state) || "",
                        zip: (this.state && this.state.getProfileSuccess.location.zip) || ""
                      }}
                      validationSchema={Yup.object().shape({
                        firstName: Yup.string().required(
                            "Please Enter Your First Name"
                        ),
                        lastName: Yup.string().required(
                            "Please Enter Your Last Name"
                        ),
                        city: Yup.string().required(
                            "Please Enter Your City"
                        ),
                        state: Yup.string().required(
                            "Please Enter Your State"
                        ),
                        zip: Yup.string().required(
                            "Please Enter Your Zip Code"
                        )
                      })}
                      onSubmit={values => {
                        this.props.editProfile({
                          firstName: values.firstName,
                          lastName: values.lastName,
                          location: {
                            city: values.city,
                            state: values.state,
                            zip: values.zip,
                          },
                          talentProperties: context.state.talentProperties
                        }, this.props.history);
                        if(context.state.profilePictureFile) {
                          this.props.uploadProfilePicture(context.state.profilePictureFile)
                        }
                      }}
                  >
                    {({ errors, status, touched }) => (
                        <Form className="form-horizontal">
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Label for="firstName" className="form-label">
                                  First Name
                                </Label>
                                <Field
                                    name="firstName"
                                    type="input"
                                    placeholder='John'
                                    className={
                                        "form-control" +
                                        (errors.firstName && touched.firstName
                                            ? " is-invalid"
                                            : "")
                                    }
                                />
                                <ErrorMessage
                                    name="firstName"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="mb-3">
                                <Label for="lastName" className="form-label">
                                  Last Name
                                </Label>
                                <Field
                                    name="lastName"
                                    type="input"
                                    placeholder='Doe'
                                    className={
                                        "form-control" +
                                        (errors.lastName && touched.lastName
                                            ? " is-invalid"
                                            : "")
                                    }
                                />
                                <ErrorMessage
                                    name="lastName"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <div className="mb-3">
                                <Label for="city" className="form-label">
                                  City
                                </Label>
                                <Field
                                    name="city"
                                    type="input"
                                    placeholder='Miami'
                                    className={
                                        "form-control" +
                                        (errors.city && touched.city
                                            ? " is-invalid"
                                            : "")
                                    }
                                />
                                <ErrorMessage
                                    name="city"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="mb-3">
                                <Label for="state" className="form-label">
                                  State
                                </Label>
                                <Field
                                    name="state"
                                    type="input"
                                    placeholder='CA'
                                    className={
                                        "form-control" +
                                        (errors.state && touched.state
                                            ? " is-invalid"
                                            : "")
                                    }
                                />
                                <ErrorMessage
                                    name="state"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </div>
                            </Col>
                            <Col>
                              <div className="mb-3">
                                <Label for="zip" className="form-label">
                                  Zip
                                </Label>
                                <Field
                                    name="zip"
                                    type="input"
                                    placeholder='33101'
                                    className={
                                        "form-control" +
                                        (errors.zip && touched.zip
                                            ? " is-invalid"
                                            : "")
                                    }
                                />
                                <ErrorMessage
                                    name="zip"
                                    component="div"
                                    className="invalid-feedback"
                                />
                              </div>
                            </Col>
                          </Row>
                          <div className="text-center mt-4">
                            <Button type="submit" color="primary">
                              Update Profile
                            </Button>
                          </div>
                        </Form>
                    )}
                  </Formik>
              }

            </div>
          </Row>
        </div>;
      case "skills":
        return <div>
        <Row>
          <Col>
            <h3 className="c-road--dot u-mb24"> Your role </h3>
            <h5 className="c-road--dot u-mb24"> Select roles that best represents your previous experiences </h5>
          </Col>
        </Row>
        <Row style={{ paddingTop: '10px'}}>
          <Col>
            <div className="d-flex flex-wrap gap-2">
              {
                profileSkills.map(function(skillData){
                  return <button type="button" onClick={(e) => {
                    updateProfileSkill(skillData)
                  }} className="btn btn-outline-dark waves-effect waves-light">
                   {skillData.skill}
                  </button>
                })
              }
            </div>
          </Col>
        </Row>
          <Row style={{ paddingTop: '10px'}}>
            <Col>
              <div className={'u-pb40'}>
                {
                  profileSkills.map(function(skillData){
                    if(skillData.isSelected) {
                      return
                      <button data-bs-toggle="button" type="button" class="btn btn-outline-secondary waves-effect">
                        <i className="bx bx-smile font-size-16 align-middle me-2"></i> {skillData.skill}
                      </button>
                    }
                  })
                }
              </div>
            </Col>
          </Row>
          {
            <Row style={{ paddingTop: '10px'}}>
              <Col>
                <div className={'u-pb40'}>
                  {
                    context.state.selectedSkills.map(function(skillData){
                      return <Card>
                        <Row>
                          <Col>
                            <div class="border-primary">
                              <div class="card-header bg-transparent border-primary">
                                <Row>
                                  <Col>
                                    <h6 class="my-0 text-primary"><i class="mdi mdi-bullseye-arrow me-3"></i>{skillData.skill}</h6>
                                  </Col>
                                  <Col style={{textAlign: 'right'}}>
                                    <i onClick={() => {
                                      removeProfileSkill(skillData)
                                    }
                                    }className="mdi mdi-close"></i>
                                  </Col>
                                </Row>


                              </div>
                              <div class="card-body">
                                <h5 class="card-title">Experience in years</h5>
                                <div class="col-md-10">
                                  <input style={{width: '100px'}} id="techRole" placeholder={skillData.yoe} onInput={() => {
                                    updateProfileSkillExperience(skillData)
                                  }
                                  }
                                         type="number"
                                         inputMode="numeric" min="1" max="20"
                                         className="c-input"/>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Card>
                    })
                  }
                </div>
              </Col>
            </Row>
          }
          <Row style={{marginTop: '20px'}}>
            <Col>
              <h3 className="c-road--dot u-mb24"> Tech experience </h3>
            </Col>
          </Row>

          <Row style={{marginTop: '20px'}}>
            <Col>
              <h4 className="c-road--dot c-road--dot--small u-mb8"> Main skills (max 4) </h4>
              <p className="u-color--gray-60 u-body-XS u-mb24"> Add languages and frameworks which represent your main
                skill of expertise </p>
              <div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="col-md-10">
                      <input onInput={onMainTechSkillInput} className="form-control" list="techSkillOptions" 
                             id="techSkills"
                             placeholder="Type to add main skill (e.g. React)"/>
                        <datalist id="techSkillOptions">
                          {
                            context.state.techSkills.map(function (techSkill) {
                              return <option value={techSkill}>
                              </option>
                            })
                          }
                        </datalist>
                    </div>
                  </div>
                </div>
                {
                  <Row style={{ paddingTop: '10px'}}>
                    <Col>
                      <div class="d-flex flex-wrap gap-2">
                        {
                          context.state.selectedMainTechSkills.map(function(techSkill){
                            return <button onClick={() => {
                              removeMainTechSkill(techSkill)
                            }
                            } data-bs-toggle="button" type="button"
                                           className="btn btn-dark btn-block">
                              {techSkill}
                            </button>
                          })
                        }
                      </div>
                    </Col>
                  </Row>
                }
              </div>
            </Col>
          </Row>
          <Row style={{marginTop: '20px'}}>
            <Col>
              <h4 className="c-road--dot c-road--dot--small u-mb8"> Supporting skills (max 8) </h4>
              <p className="u-color--gray-60 u-body-XS u-mb24"> Add languages and frameworks which represent your skills which you are familiar with and comfortable working with as a supporting skill. </p>
              <div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="col-md-10">
                      <input onInput={onSecondaryTechSkillInput} className="form-control" list="supportingTechSkillOptions"
                             id="supportingTechSkills"
                             placeholder="Type to add secondary skill (e.g. React)"/>
                      <datalist id="supportingTechSkillOptions">
                        {
                          context.state.techSkills.map(function (techSkill) {
                            return <option value={techSkill}>
                            </option>
                          })
                        }
                      </datalist>
                    </div>
                  </div>
                </div>
                {
                  <Row style={{ paddingTop: '10px'}}>
                    <Col>
                      <div class="d-flex flex-wrap gap-2">
                        {
                          context.state.selectedSecondaryTechSkills.map(function(techSkill){
                            return <button onClick={() => {
                              removeSecondaryTechSkill(techSkill)
                            }
                            } data-bs-toggle="button" type="button"
                                           className="btn btn-dark btn-block">
                              {techSkill}
                            </button>
                          })
                        }
                      </div>
                    </Col>
                  </Row>
                }
              </div>
            </Col>
          </Row>
          <Row style={{marginTop: '20px'}}>
            { (this.props.error && this.props.error != '') &&
                <Alert color={'danger'}>
                  {'Profile Failed to Update'}
                </Alert>
            }
            { (this.props.success && this.props.success != '') &&
                <Alert>
                  {'Profile Successfully Updated'}
                </Alert>
            }
          </Row>
          <Row style={{marginTop: '20px'}}>
            <div className="text-center mt-4">
              <Button type="submit" color="primary" onClick={() => {
                const techRoles = context.state.selectedSkills.map(function (selectedSkill) {
                  return {
                    skill: selectedSkill.skill,
                    yoe: selectedSkill.yoe
                  }
                })
                context.props.editProfile({
                  talentProperties: {
                    techRoles: techRoles,
                    mainSkills: context.state.selectedMainTechSkills,
                    secondarySkills: context.state.selectedSecondaryTechSkills
                  }
                }, this.props.history);
              }}>
                Update Profile
              </Button>
            </div>
          </Row>


      </div>
      case "experience":
        return <div className="card-body">

          <h4 className="card-title">Upload your resume</h4>
          <p className="card-title-desc">We'll build your profile based on your work experience, projects,
            and certificates
          </p>

          {
            context.state.resumeFileSrc != null &&
              <div>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                  <Document file={context.state.resumeFileSrc} onLoadSuccess={onDocumentLoadSuccess}
                            onLoadError={console.error}>
                    <Page pageIndex={0} renderTextLayer={false} renderAnnotationLayer={false}/>
                  </Document>


                </div>

              </div>
          }

          <div>
            <input
                id="uploadResumeInput"
                style={{display: 'none'}}
                type="file"
                onChange={handleResumeUploadEvent}
                accept="application/pdf"
            />
            <form action="#" className="dropzone dz-clickable" onClick={(event) => {
              openResumeUploadPrompt()
            }
            }>

              <div className="dz-message needsclick">
                <div className="mb-3">
                  <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                </div>

                <h4>Drop resume files here or click to upload.</h4>
              </div>
            </form>
          </div>
          <div className="text-center mt-4">
            <button onClick={(e) => {
              context.props.uploadResume(context.state.resumeFile)
            }
            } type="button" className="btn btn-primary waves-effect waves-light">Upload Resume</button>
          </div>
        </div>
      case "references":
        return <div>
          <div className="col-12">
            <div className="card">
              <div className="card-body">

                <h4 className="card-title">References</h4>
                <p className="card-title-desc">Add link to your external profiles which represent or showcase
                  your experience and skills. Adding multiple references will increase your
                  chance to get contacted by companies.</p>

                { (this.props.success && this.props.success != '') &&
                    <Alert>
                      {'Profile Successfully Updated'}
                    </Alert>
                }

                <div className="mb-3 row">
                  <div className="col-md-10">
                    <input className="form-control"
                           onChange={e => context.setState({githubLink: e.target.value})}
                           value={context.state.githubLink} type="url" placeholder="Github URL"
                           id="example-url-input"/>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-md-10">
                    <input className="form-control"
                           onChange={e => context.setState({gitlabLink: e.target.value})}
                           value={context.state.gitlabLink} type="url" placeholder="Gitlab"
                           id="example-url-input"/>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-md-10">
                    <input className="form-control"
                           onChange={e => context.setState({stackoverflowLink: e.target.value})}
                           value={context.state.stackoverflowLink} type="url" placeholder="StackOverflow"
                           id="example-url-input"/>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-md-10">
                    <input className="form-control"
                           onChange={e => context.setState({linkedinLink: e.target.value})}
                           value={context.state.linkedinLink} type="url" placeholder="LinkedIn"
                           id="example-url-input"/>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-md-10">
                    <input className="form-control"
                           onChange={e => context.setState({twitterLink: e.target.value})}
                           value={context.state.twitterLink} type="url" placeholder="Twitter"
                           id="example-url-input"/>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-md-10">
                    <input className="form-control"
                           onChange={e => context.setState({yourWebsiteLink: e.target.value})}
                           value={context.state.yourWebsiteLink}
                           type="url" placeholder="Your Website"
                           id="example-url-input"/>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-md-10">
                    <input className="form-control"
                           onChange={e => context.setState({otherLink: e.target.value})}
                           value={context.state.otherLink} type="url" placeholder="Other"
                           id="example-url-input"/>
                  </div>
                </div>

                <div className="text-center mt-4">
                  <Button type="submit" color="primary" onClick={(event) => {
                    context.props.editProfile({
                      ...context.state.user,
                      talentProperties: {
                        techReferences: {
                          github: context.state.githubLink,
                          gitlab: context.state.gitlabLink,
                          stackoverflow: context.state.stackoverflowLink,
                          linkedin: context.state.linkedinLink,
                          twitter: context.state.twitterLink,
                          yourWebsite: context.state.yourWebsiteLink,
                          other: context.state.otherLink
                        }
                      }
                    })
                  }
                  }>
                    Update Profile
                  </Button>
                </div>

              </div>
            </div>
          </div>
        </div>
      case "preferences":
        return <div></div>
    }
  }

  render() {
    const profileSection = [{
      title: "Personal Info",
      sectionPath: "/profile/personalInfo"
    },
    {
      title: "Skills and experience",
      sectionPath: "/profile/skills"
    },
    {
      title: "Work experience",
      sectionPath: "/profile/experience"
    },
    {
      title: "References",
      sectionPath: "/profile/references"
    }];



    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Remotesapps" breadcrumbItem="My profile" />

            <Row style={{display: 'flex', justifyContent: 'center'}}>
              <Col md={'2'} lg={'4'}>
                <Card>
                  <CardBody>
                    {profileSection.map(function(section){
                      return <Row>
                        <Col>
                          <Card>
                            <CardBody>
                              <Link legacyBehavior to={section.sectionPath}>
                                <Row>
                                  <Col lg={'2'}>
                                <span className="font-size-20">
                                  <i className="bx bx-checkbox-checked" />
                                </span>
                                  </Col>
                                  <Col>
                                    <div aria-current="page"
                                       className="c-vertical-tab u-pt16 u-pb16 u-pl24 u-pr32 router-link-exact-active active">
                                      <div className="u-flex u-flex--between u-flex--middle">
                                        <div className="u-flex">

                                          <span className="u-pl8">{section.title}</span></div>
                                      </div>
                                    </div>

                                  </Col>
                                </Row>
                              </Link>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>;
                    })

                    }
                  </CardBody>
                </Card>
              </Col>
              <Col md={'4'} lg={'8'}>
                <Card style={{display: 'flex'}}>
                  <CardBody>
                    {this.profileSectionSwitch(this, this.props.match.params.type)}
                  </CardBody>

                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

UserProfile.propTypes = {
  editProfile: PropTypes.func,
  uploadProfilePicture: PropTypes.func,
  uploadResume: PropTypes.func,
  getProfileData: PropTypes.func,
  getProfileSuccess: PropTypes.any,
  getProfileFailure: PropTypes.any,
  error: PropTypes.object,
  success: PropTypes.string,
  resetProfileFlag: PropTypes.func,
  routeType: PropTypes.object
};

const mapStateToProps = state => {
  const { error, success, getProfileSuccess, getProfileFailure  } = state.Profile;
  return { error, success, getProfileSuccess, getProfileFailure };
};

export default withRouter(
  connect(mapStateToProps, { editProfile, uploadProfilePicture, uploadResume, resetProfileFlag, getProfileData,
    getProfileSuccess, getProfileFailure })(UserProfile)
);
