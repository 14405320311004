export const EDIT_PROFILE = "EDIT_PROFILE"
export const UPLOAD_PROFILE_PICTURE = "UPLOAD_PROFILE_PICTURE"
export const UPLOAD_RESUME = "UPLOAD_RESUME"
export const PROFILE_SUCCESS = "PROFILE_SUCCESS"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const RESET_PROFILE_FLAG = "RESET_PROFILE_FLAG";

export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILE_SUCCESS = "GET_PROFILE_SUCCESS";
export const GET_PROFILE_FAILURE = "GET_PROFILE_FAILURE";
