import React from "react";
import {Redirect, Route} from "react-router-dom";

// User profile
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import FrontPage from "../pages/FrontPage";
import ForBusinessPage from "../pages/ForBusinessPage";
import Assessments from "../pages/Dashboard/Assessments";
import AssessmentFrontDoor from "../pages/Dashboard/Assessments/FrontDoor";
import AssessmentScreen from "../pages/Dashboard/Assessments/Assessments";
import AdminLogin from "../pages/Admin/login";
import AdminPanel from "../pages/Admin/admin";
import QuizBuilder from "../pages/Admin/Sections/QuizBuilder";
import CoursePlayer from "../pages/Courses/coursePlayer";
import CourseDashboard from "../pages/Courses/courseDashboard";
import CourseBuilder from "../pages/Courses/courseBuilder";

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
    { path: "/assessments", component: Assessments },
    { path: "/assessments/:id", component: AssessmentFrontDoor },
    {path: "/assessments/:id/questions", component: AssessmentScreen},

  //profile
  { path: "/profile", component: UserProfile },
    { path: "/profile/:type", component: UserProfile },

    //course player
    { path: "/courses/:id", component: CoursePlayer },

    //course dashboard
    { path: "/courseDashboard", component: CourseDashboard },

    //course dashboard
    { path: "/courseBuilder", component: CourseBuilder },

    // this route should be at the end of all other routes
    // eslint-disable-next-line react/display-name
    { path: "/", exact: true, component: () => <Redirect to="/home" /> },
];

const adminRoutes = [
    {path: "/adminPanel", component: AdminPanel},
    { path: "/adminPanel/:type", component: AdminPanel },
    {path: "/quizBuilder", component: QuizBuilder}
]

const publicRoutes = [
    { path: "/home", component: FrontPage },
    { path: "/companies", component: ForBusinessPage },
    { path: "/logout", component: Logout },
  { path: "/login", component: () => {
      if (!localStorage.getItem("authUser")) {
        return <Login/>
      } else {
        return <Redirect to="/dashboard" />
      }
    }
    },
    { path: "/adminLogin", component: AdminLogin },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { authProtectedRoutes, adminRoutes, publicRoutes };